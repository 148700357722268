import React, { useEffect, useState } from 'react'
import ReportView from './ReportView'
import { useSelector } from 'react-redux'
import { baseURL, successCode } from '../../configuration/url'
import axios from 'axios'

interface EyeSightPatientReportViewProps {
    EyesightReportData: any
}

const EyeSightPatientReportView: React.FC<EyeSightPatientReportViewProps> = ({ EyesightReportData }) => {
    const { orgData, shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
    const org = useSelector((state: any) => state.Login.organization)
    const [eyeSightdata, setEyesightData] = useState<any>([])
        const [locationLegend, setLocationLegend] = useState<any>([])
        const [activityLegend, setActivityLegend] = useState<any>([])

    useEffect(() => {
        if (EyesightReportData !== null && EyesightReportData.length > 0) {
            const filterData = EyesightReportData && EyesightReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime)
            setEyesightData(filterData)
        } else {
            setEyesightData([])
        }
    }, [EyesightReportData])

    useEffect(() => {
        const handleDropdowns = async () => {
            try {
                const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Location%20Legend&organizationId=${org}&page=-1`)
                if (response.data.message.code === successCode) {
                    setLocationLegend(response.data.data[0].list)
                } else {
                    setLocationLegend([])
                }
            } catch (error: any) {
                console.error("API ERROR:", error)
            }
        }
        const handleDropdowns1 = async () => {
            try {
                const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Activity%20Legend&organizationId=${org}&page=-1`)
                if (response.data.message.code === successCode) {
                    setActivityLegend(response.data.data[0].list)
                } else {
                    setActivityLegend([])
                }
            } catch (error: any) {
                console.error("API ERROR :", error)
            }
        }
        handleDropdowns()
        handleDropdowns1()
    }, [])
  return (
    <div>
        {
            eyeSightdata !== null && eyeSightdata !== undefined &&
            <ReportView reportData={eyeSightdata} selectShiftView={undefined} checkBox={undefined} selectShift={undefined} nextDate={undefined} dateID={undefined} checkPrecaution={''} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={false} />
        }
    </div>
  )
}

export default EyeSightPatientReportView
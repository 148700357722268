import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, TextField, FormControlLabel, Checkbox, Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import { getAllPatient } from '../../slices/thunk';
import printImage from '../../assets/images/printer.svg'
import { secondarybt } from "../../common/primary";
import { toast } from 'react-toastify';
import { primarytext, grey } from "../../common/primary";
// import { jsPDF } from 'jspdf';
import MultipleReportView from './MultipleReportView';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Loader from '../../components/loader/Loader';
import trilliumImage from './../../assets/images/trillium.png';
import { useLocation } from 'react-router-dom';
import ReportView from '../Reports/ReportView';

interface OneToOneReportViewProps {
  checkPrecaution: any
}
const OneToOneReportView: React.FC<OneToOneReportViewProps> = ({ checkPrecaution }) => {
  const addInputData = {
    date: '',
    patientName: ''
  }
  const org = useSelector((state: any) => state.Login.organization)
  const { patientData } = useSelector((state: any) => state.Patient);
  let [datas, setData] = useState(new Array<any>());
  let [selectdate, setSelectDate] = useState<any>(new Array<any>());
  let [dates, setDates] = useState<any>();
  let [addPatientName, setAddPatientName] = useState("");
  let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
  const [viewContentData, setViewContent] = useState<boolean>(true);
  const [newInputData, setNewInputData] = useState(addInputData)
  const [value, setValue] = useState<any>()
  const [disabled, setDisabled] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [selectShift, setSelectShift] = useState<any>('');
  const [selectShiftView, setSelectShiftView] = useState<boolean>(false);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
  let [outputSave, setOutputSave] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [accordionView, setAccordionView] = useState<boolean>(false)
  const [checkSelect, setCheckSelect] = useState<number>(1)
  const [multiReportData, setMultiReportData] = useState<any>()
  const [reportData, setReportData] = useState<any>([])
  const [singleDate, setSingleDate] = useState<any>()
  const [checkSubmit, setCheckSubmit] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [OrgName, setOrgName] = useState<any>('')

  const location = useLocation();
  const path = location.pathname === "/one-to-one-report"
  const { orgData } = useSelector((state: any) => state.Org)
  useEffect(() => {
    if (!orgData) return
    console.log(orgData, 'orgData')
    setOrgName(orgData.organizationdetails[0].name)
  }, [orgData])

  const dispatch = useDispatch<any>()
  const option = ['Shift-A', 'Shift-B', 'Shift-C']
  const [legendcontrol, setLegendControl] = useState<boolean>(false)

  const handlelegendshow = (e:React.ChangeEvent<HTMLInputElement>) => {
    setLegendControl(e.target.checked)
  }
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked);
  };

  useEffect(() => {
    getAllPatient(dispatch, org, -1, '');
  }, [org])
  useEffect(() => {
    if (patientData) {
      setAddNewPatient(patientData !== null && patientData !== undefined && patientData?.content);
      setData(
        patientData?.content?.map((k: any) => {
          return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family;
        })
      );
    } else {
      setAddNewPatient([]);
      setData([]);
    }
  }, [patientData]);
  // useEffect(() => {
  //   setAddNewPatient(patientData !== null && patientData !== undefined && patientData);
  //   setData(patientData !== null && patientData !== undefined && patientData?.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
  // }, [patientData])

  useEffect(() => {
    if (!value) return
    setNewInputData({ ...newInputData, date: moment(value).format("YYYYMMDD") });
  }, [value])

  useEffect(() => {
    const getDropDownDate = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${baseURL}/register1to1/getDateList?patientId=${newInputData.patientName}`)
        if (response) {
          setSelectDate(response.data.data)
          setLoading(false)
        } else {
          console.error("Error");
          setSelectDate([]);
          setLoading(false)
        }
      } catch (error) {
        console.error("Error:", error);
        setSelectDate([]);
        setLoading(false)
      }
    }
    getDropDownDate();
  }, [newInputData, addPatientName]);

  useEffect(() => {
    setDates(selectdate ? selectdate?.map((item: any, inx: number) => { return item.date }) : []);
    if (selectdate === null || selectdate === undefined) {
      setValue('')
    }
  }, [selectdate, addPatientName, newInputData]);

  const handlesumbit = async () => {

    if (checkSelect === 1) {
      let newErrors = {
        patientName: !newInputData.patientName,
        date: !newInputData.date
      };
      setNameError(newErrors);
      const hasErrors = Object.values(newErrors)?.some(error => error);
      if (hasErrors) {
        setCheckSubmit(true)
        toast.error("Please Fill Required Field")
        return;
      }
    } else {
      let newErrors = {
        patientName: !newInputData.patientName,
        selectedRows: !selectedRows.length
      };
      setNameError(newErrors);
      const hasErrors = Object.values(newErrors)?.some(error => error);
      if (hasErrors) {
        setCheckSubmit(true)
        toast.error("Please Fill Required Field")
        return;
      }
    }
    try {
      setLoading(true)
      setMultiReportData([])
      setReportData([])
      let bodyDate: any[];
      if (checkSelect === 1) {
        bodyDate = singleDate
      } else {
        bodyDate = selectedRows
      }
      const response = await axios.post(`${baseURL}/register1to1/getOneToOneConfigReportForMultipleDate?organization=${org}&patientId=${newInputData.patientName}&shiftName=${selectShift}`, { dates: bodyDate })
      if (response?.data.message && response?.data.message.code === successCode) {
        if (checkSelect === 1) {
          setReportData(response.data.data)
          setSelectShiftView(selectShift ? true : false)
          setCheckBox(disabled)
          setViewContent(false)
          setIshidden(false)
        } else {
          setAccordionView(true)
          setMultiReportData(response.data.data)
          setReportData(response.data.data)
          setSelectShiftView(selectShift ? true : false)
          setCheckBox(disabled)
          setIshidden(false)
          // setViewContent(!viewContentData)
          if (checkSubmit === false) {
            setViewContent(!viewContentData)
          }
        }
      } else {
        console.error("Error:", response.data.message);
        setIshidden(true)
      }
      bodyDate = []
      setLoading(false)
      setIshidden(false)
    } catch (error: any) {
      console.error("API Request Error", error)
      setLoading(false)
      setIshidden(true)
    }
  }
  const handlePrint = () => {
    let printContents = document.getElementById('1to1AllReport')!.innerHTML;
    let printWindow = window.open('', '_blank');
    printWindow!.document.body.innerHTML = printContents;
    printWindow!.print();
    printWindow!.close();
  }
  const handleCheckSelect = (n: any) => {
    setCheckSelect(n)
    if (n === 1) {
      setAccordionView(false)
      setSelectedRows([])
      setReportData([])
    } else {
      setOutputSave(false)
      setSelectedRows([])
      setReportData([])
    }
  }
  const handleDownload = async () => {
    if (reportData) {
      try {
        setLoading(true);
        let index = 0;
        let myVar = setInterval(() => {
          if (index < reportData.length) {
            const currentIndex = index;
            setTimeout(() => { timeDealys(reportData[currentIndex]); }, 0);
            index++;
          } else {
            clearInterval(myVar);
          }
        }, 0);

        async function timeDealys(reports: any) {
          // Get all the sections
          const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

          // Create a PDF instance
          const pdf = new jsPDF('p', 'px', 'a4', true);
          const margin = 20;
          const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
          const images: any[] = []; // Array to store images of each section
          let currentY = margin;

          const addSectionToPDF = (section: any) => {
            return new Promise<void>((resolve) => {
              html2canvas(section, {
                useCORS: true,
                logging: true,
                scale: 2,
                backgroundColor: null,
              }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                images.push(imgData); // Push the image data into the images array
                resolve();
              }).catch((error) => {
                console.error('Error rendering section', error);
                resolve();
              });
            });
          };

          // Add all sections to images array
          const promises = Array.from(sections).map(addSectionToPDF);
          await Promise.all(promises);

          // Add the first image to the second page (swap the first and second content)
          pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
          pdf.addPage(); // Create a new page

          // Add the second image to the first page (swapped content)
          pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

          // Save the PDF with the swapped content
          pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
          setLoading(false);
        }
      } catch (error: any) {
        console.error("Download Error: ", error);
        setLoading(false);
      }
    }
  };



  const handleRowSelected = (item: string) => {
    const index = selectedRows.findIndex(selectedItem => selectedItem === moment(item).format("YYYYMMDD"));
    if (index > -1) {
      setSelectedRows(selectedRows.filter((_, i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, moment(item).format("YYYYMMDD")]);
    }
  };


  const [nextDate, setNextDate] = useState<any>('')
  useEffect(() => {
    if (!singleDate) return;

    const validDate = moment(singleDate[0]);
    if (validDate.isValid()) {
      const nextDay = validDate.clone().add(1, 'days');
      setNextDate(moment(nextDay)?.format('MMM DD, YYYY'))
    } else {
      console.error("Invalid date:", singleDate);
    }
  }, [singleDate]);

  const [isHidden, setIshidden] = useState<boolean>(true)

  const [locationLegend, setLocationLegend] = useState<any>([])
  const [activityLegend, setActivityLegend] = useState<any>([])
  useEffect(() => {
    const handleDropdowns = async () => {
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Location%20Legend&organizationId=${org}&page=-1`)
        if (response.data.message.code === successCode) {
          setLocationLegend(response.data.data[0].list)
        } else {
          setLocationLegend([])
        }
      } catch (error: any) {
        console.error("API ERROR:", error)
      }
    }
    const handleDropdowns1 = async () => {
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Activity%20Legend&organizationId=${org}&page=-1`)
        if (response.data.message.code === successCode) {
          setActivityLegend(response.data.data[0].list)
        }
      } catch (error: any) {
        console.error("API ERROR :", error)
      }
    }
    handleDropdowns()
    handleDropdowns1()
  }, [])
  return (
    <div className='container-fluid overflow-y-auto pb-5'>
      {loading && <Loader />}
      <div hidden={checkSelect === 2 && !viewContentData ? true : false}>
        <div className='pt-2'>
          <div className="d-flex justify-content-between">
            <div className="col-md-3">
              {path && <h5 style={{ float: 'left' }}>One To One Report</h5>}
            </div>
            <div className="col-md-2 d-flex justify-content-end ">
              <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => {
                if (checkSelect === 1) {
                  if (reportData.length === 0) {
                    handlesumbit()
                    handleDownload()
                  }
                  handleDownload()
                } else if (checkSelect === 2) {
                  if (!reportData.length) {
                    handlesumbit()
                    setCheckSubmit(false)
                  } else {
                    setViewContent(!viewContentData)
                  }
                }
              }}
              disabled={isHidden}
              >
                {checkSelect === 1 ? 'Download' : 'View'}
              </button>
              {
                checkSelect === 1 &&
                <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} disabled={isHidden} onClick={handlePrint}><img src={printImage} />Print</button>
              }
            </div>
          </div>
          <div className=''>
            {/* Report View Header Start */}
            <div className=" d-flex flex-row mt-2" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px", height: '100px', width: '100%' }}>
              <div className='col-2  d-flex justify-content-center align-items-center '>
                <div className='' >
                  <div className=''>
                    <FormControlLabel
                      control={<Checkbox checked={checkSelect === 1 ? true : false} onChange={() => { handleCheckSelect(1) }} />}
                      label="Single Report"
                    />
                  </div>
                  <div className=''>
                    <FormControlLabel
                      control={
                        <Checkbox checked={checkSelect === 2 ? true : false}
                          onChange={() => {
                            if (!viewContentData) {
                              setViewContent(true)
                            }
                            handleCheckSelect(2)
                          }
                          }
                        />
                      }
                      label="Multi Report"
                    />
                  </div>
                </div>
              </div>
              <div className='col-3  d-flex justify-content-center align-items-center '>
                <Autocomplete
                  options={datas}
                  getOptionLabel={(option) => option}
                  value={addPatientName}
                  onChange={(e, v) => {
                    setAddPatientName(v);
                    if (v !== "") {
                      setSelectedRows([])
                      setMultiReportData([])
                      setReportData([])
                      let newPatientData = addNewPatient?.filter((m: any) =>
                        m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v
                      )?.map((k) => { return k.id })
                      newInputData.patientName = newPatientData[0]
                      setNewInputData({ ...newInputData, date: '' })
                      setNameError({ ...nameError, patientName: false })
                      setOutputSave(false);
                      setCheckSubmit(true);
                      if (checkSelect === 1) {
                        setViewContent(true)
                      }
                    }
                  }}
                  sx={{ width: "90%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="patient name"
                      margin="none"
                      fullWidth
                      error={!!nameError.patientName}
                      required
                    />
                  )}
                />
              </div>
              <div className='col-2  d-flex justify-content-center align-items-center  ' >
                <Autocomplete
                  options={dates}
                  getOptionLabel={(option) => option}
                  value={outputSave === true ? value : ''}
                  onChange={(e, v) => {

                    setValue(v);
                    setSingleDate([moment(v).format("YYYYMMDD")])
                    // handleRowSelected(v)
                    // setSelectedRows([...selectedRows, moment(v).format("YYYYMMDD")])
                    setNameError({ ...nameError, date: false });
                    setOutputSave(true);
                    if (checkSelect === 1) {
                      setViewContent(true)
                    }
                  }}
                  freeSolo
                  sx={{ width: "82%" }}
                  disabled={checkSelect === 2 ? true : false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select date"
                      error={!!nameError.date}
                      required
                    />
                  )}
                />
              </div>
              <div className='col-2  d-flex justify-content-center align-items-center  '>
                <Autocomplete
                  options={option}
                  getOptionLabel={(option) => option}
                  value={selectShift}
                  onChange={(e, v) => {
                    if (reportData.length > 0) {
                      setSelectShift(v)
                      // setViewContent(!viewContentData)
                      // handlesumbit()
                      // setCheckSubmit(true)
                    } else {
                      setSelectShift(v)
                    }
                  }}
                  sx={{ width: "82%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Shift"
                      placeholder="Shift"
                      margin="none"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className='col-2  d-flex flex-column px-1' >
                <FormControlLabel
                  control={<Checkbox checked={disabled} onChange={handleCheck} />}
                  label="Proximity"
                />
                <FormControlLabel
                  control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                  label={legendcontrol ? 'Legends Name' : 'Legends Key'}
                />
              </div>
              <div className='col-1  d-flex justify-content-center align-items-center     '>
                <Button label='submit' style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => {
                  handlesumbit()
                  setCheckSubmit(true)
                }}>Submit</Button>
              </div>
            </div>
            {
              checkSelect === 2 &&
              <div style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }} className=''>
                {(addPatientName && dates) && (
                  <Table hover style={{ width: '100%', textAlign: 'center' }} >
                    <thead >
                      <tr className='' style={{ top: 0, position: 'sticky' }}>
                        <th style={{ color: primarytext, backgroundColor: grey }} >Date</th>
                        <th style={{ color: primarytext, backgroundColor: grey }} >Patient Name</th>
                        <th style={{ color: primarytext, backgroundColor: grey }} >Shift</th>
                        <th style={{ color: primarytext, backgroundColor: grey }} >Proximity</th>
                        <th style={{ color: primarytext, backgroundColor: grey }} >Select</th>
                      </tr>
                    </thead>
                    <tbody >
                      {dates && dates.map((item: any, inx: number) => {
                        return (
                          <tr key={inx} className='' style={{}}>
                            <td>{item && moment(item).format("MM-DD-YYYY")}</td>
                            <td>{addPatientName}</td>
                            <td>{selectShift ? selectShift : 'Full Shift'}</td>
                            <td>{disabled ? disabled && "Proximity" : '-'}</td>
                            <td>
                              {/* <Checkbox onChange={()=>{handleRowSelected(item)}} /> */}
                              <input type='checkbox' checked={selectedRows.includes(moment(item).format("YYYYMMDD"))} onChange={() => { handleRowSelected(item) }} />
                            </td>
                          </tr>)
                      })}
                    </tbody>
                  </Table >)}
              </div>
            }
            {/* Report View Header End */}
            {/* Multi Report Summary&Details View Start */}
            <div className='mt-2'>
              {
                checkSelect === 2 &&
                <MultipleReportView
                  multiReportData={multiReportData}
                  View={accordionView}
                  selectShift={selectShift}
                  selectShiftView={selectShiftView}
                  checkBox={checkBox}
                  locationLegend={locationLegend}
                  activityLegend={activityLegend}
                  checkPrecaution={undefined}
                  legendcontrol={legendcontrol}
                />
              }
            </div>
            {/* Multi Report Summary&Details View End */}
          </div>
        </div>
      </div>
      <div hidden={viewContentData} id='1to1AllReport' style={{ paddingLeft: '100px', paddingRight: '100px' }} >
        {
          checkSelect === 2 && !viewContentData &&
          <>
            <div className='d-flex justify-content-end gap-2'>
              <div>
                <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => { handleDownload() }}>Download</button>
              </div>
              <div>
                <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px', }} onClick={() => { handlePrint() }}><img src={printImage} />Print</button>
              </div>
              <div>
                <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px' }} onClick={() => { setViewContent(!viewContentData) }}>Close</button>
              </div>
            </div>
          </>
        }
        {
          reportData !== null && reportData !== undefined && <ReportView reportData={reportData} selectShiftView={selectShiftView} checkBox={checkBox} selectShift={selectShift} nextDate={nextDate} dateID={undefined} checkPrecaution={checkPrecaution} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={legendcontrol} />
        }
      </div>
    </div>
  )
}
export default OneToOneReportView
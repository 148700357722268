import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { primarybg, secondarybt } from '../../common/primary';
import axios from 'axios';
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPatient, getAllStaff } from '../../slices/thunk';
import { toast } from 'react-toastify';
import moment from 'moment';
import { authorize } from '../../helpers/common';
import Q15PatientReportView from './Q15PatientReportView';
import OnetoOnePatientReportView from './OnetoOnePatientReportView';
import Loader from '../../components/loader/Loader';
import EyeSightPatientReportView from './EyeSightPatientReportView';

interface addpatient {
    patientid?: any
    startDate?: any
    endDate?: any
}

const PatientReport = () => {
    let tabcont = {
        q15Report: false,
        onetoone: false,
        eyesight: false,
        onehour: false
    }
    const org = useSelector((state: any) => state.Login.organization)
    const [Q15tabcontrol, setQ15Tabcontrol] = useState<boolean>(false)
    const [onetoonetabcontrol, setOnetooneTabcontrol] = useState<boolean>(false)
    const [Eyetabcontrol, setEyeTabcontrol] = useState<boolean>(false)
    const [value, setValue] = useState<any>('1');
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    };
    const dispatch = useDispatch<any>()
    const { staffData } = useSelector((state: any) => state.Staff);
    const { patientData } = useSelector((state: any) => state.Patient);
    let [getStaffData, setStaffData] = useState(new Array<any>());
    let [addNewStaff, setAddNewStaff] = useState(new Array<any>());
    const [addPatientName, setAddPatientName] = useState<any>('')
    let [data, setData] = useState(new Array<any>());
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [newInputData, setNewInputData] = useState<addpatient>();
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const [Q15ReportData, setQ15ReportData] = useState<any>('')
    const [onetooneReportData, setonetooneReportData] = useState<any>([])
    const [EyesightReportData, setEyesightReportData] = useState<any>([])
    const [loading ,setLoading] = useState<boolean>(false)
    useEffect(() => {
        getAllStaff(dispatch, org, -1, '')
        getAllPatient(dispatch, org, -1, '');
    }, [org])
    useEffect(() => {
        console.log(patientData, 'staffData')
    }, [staffData])
    useEffect(() => {
        if (staffData) {
            setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner"));
            setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k?.name[0]?.given + " " + k?.name[0]?.family }))
        } else {
            setAddNewPatient([])
            setStaffData([])
        }
    }, [staffData])

    useEffect(() => {
        if (patientData) {
            setAddNewPatient(patientData !== null && patientData !== undefined && patientData?.content);
            setData(
                patientData?.content?.map((k: any) => {
                    return k?.basicDetails[0]?.name[0]?.given + " " + k?.basicDetails[0]?.name[0]?.family;
                })
            );
        } else {
            setAddNewPatient([]);
            setData([]);
        }
    }, [patientData]);

    const handleGetPatientData = async (staffId: any) => {
        const id = staffId ? staffId : ''
        try {
            const response = await axios.get(`${baseURL}/report/patients?organization=${org}&staffId=${id}`)
            console.log(response.data, 'respss')
        } catch (error: any) {
            console.error("API ERROR:", error)
        }
    }
    const handleSubmit = async () => {
        setLoading(true)
        let newErrors = {
            patientid: !newInputData?.patientid,
            startDate: !newInputData?.startDate,
        };
        setNameError(newErrors);
        const hasErrors = Object.values(newErrors)?.some(error => error);
        if (hasErrors) {
            setLoading(false)
            toast.error("Please Fill Required Field")
            return;
        }

        // const endDateRange = newInputData?.endDate ? moment(newInputData?.endDate).format("YYYYMMDD") : ''

        const apiDomian = newInputData?.endDate
            ? `${baseURL}/report/patientReports?endDate=${moment(newInputData?.endDate).format("YYYYMMDD")}&organization=${org}&patientId=${newInputData?.patientid}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`
            : `${baseURL}/report/patientReports?organization=${org}&patientId=${newInputData?.patientid}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`

        try {

            const { header1 } = authorize()
            const response = await axios.get(apiDomian, { headers: header1 })
            if (response.data.message.code === successCode) {
                setLoading(false)
                if (response.data.q15) {
                    setQ15Tabcontrol(response.data.q15 ? true : false)
                    setValue('1')
                    setQ15ReportData(response.data.q15Data)
                } else {
                    setQ15ReportData([])
                }

                if (response.data.oneToOne) {
                    setOnetooneTabcontrol(response.data.oneToOne ? true : false)
                    setValue('2')
                    setonetooneReportData(response.data.oneToOneData)
                } else {
                    setonetooneReportData([])
                }

                if (response.data.eyeSight) {
                    setEyeTabcontrol(response.data.eyeSight ? true : false)
                    setValue('3')
                    setEyesightReportData(response.data.eyeSightData)
                } else {
                    setEyesightReportData([])
                }

                console.log(response.data, 'respData')
            } else {
                setLoading(false)
                setQ15Tabcontrol(false)
                setOnetooneTabcontrol(false)
                setEyeTabcontrol(false)
                setQ15ReportData([])
                setonetooneReportData([])
                setonetooneReportData([])
            }
        } catch (error: any) {
            setLoading(false)
            console.error("API ERROR:", error)
        }

    }
    return (
        <>
            {loading && <Loader/>}
            <h5 style={{ textAlign: 'center' }}>Patient Specific Report</h5>
            <div className='mt-3'>
                { /* Select Patient section start */}
                <header>
                    <div className='d-flex gap-3 px-1'>
                        <div className='col-3'>
                            <Autocomplete
                                options={data}
                                getOptionLabel={(option) => option}
                                value={addPatientName}
                                onChange={(e, v) => {
                                    setAddPatientName(v);
                                    let newPatientData = addNewPatient && addNewPatient?.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                                    setNewInputData({ ...newInputData, patientid: newPatientData[0] });
                                    setNameError({ ...nameError, patientid: false })
                                }}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="patient name"
                                        placeholder="patient name"
                                        margin="none"
                                        fullWidth
                                        error={!!nameError.patientid}
                                    // helperText={nameError.patientid ? 'sad' : '' }
                                    />
                                )}

                            />
                        </div>
                        <div className='col-3'>
                            <Autocomplete
                                options={getStaffData}
                                getOptionLabel={(option) => option}
                                // value={addPatientName}
                                disabled
                                // onChange={(e, v) => {
                                //     // console.log(e, 'ev', v)
                                //     // setAddPatientName
                                //     let newStaffData = addNewStaff && addNewStaff?.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k) => { return k.id });

                                //     handleGetPatientData(newStaffData[0])

                                // }}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Staff name"
                                        placeholder="Staff name"
                                        margin="none"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                        <div className='col-2'>
                            <DatePicker
                                className=""
                                label="Start Date"
                                // value={newInputData?.startDate ? newInputData?.startDate : null}
                                onChange={(event: any) => {
                                    // const d = new Date(event)
                                    // let w = d.getFullYear().toString()
                                    // if (w.length !== 4) {
                                    setNewInputData({ ...newInputData, startDate: event?.$d })
                                    setNameError({ ...nameError, startDate: false })
                                    // }
                                }}
                                sx={{
                                    border: nameError.startDate ? "1px solid red" : "",
                                    borderRadius: "5px"
                                }}
                            />
                        </div>
                        <div className='col-2'>
                            <DatePicker
                                className=""
                                label="End Date"
                                onChange={(event: any) => {
                                    setNewInputData({ ...newInputData, endDate: event?.$d })
                                }}

                            />
                        </div>
                        <div className='col-1 d-flex align-items-center justify-content-center '>
                            <button className="btn" style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px', color: 'white' }} onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </header>
                { /* Select Patient section End */}
                {
                    (onetoonetabcontrol || Eyetabcontrol || Q15tabcontrol) && (
                        <>
                            <section>
                                <Box>
                                    <TabContext value={value}>
                                        <Box>
                                            <div className='row'>
                                                <div className="col-md-8">
                                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                        {Q15tabcontrol && <Tab label="Q15-Report" value="1" />}
                                                        {onetoonetabcontrol && <Tab label="One-to-One" value="2" />}
                                                        {Eyetabcontrol && <Tab label="EyeSight" value="3" />}
                                                    </TabList>
                                                </div>
                                                <div className="col-md-4">
                                                    download
                                                </div>
                                            </div>
                                        </Box>
                                        <TabPanel value='1'>
                                            <>
                                                {Q15ReportData !== null && Q15ReportData.length > 0 && <Q15PatientReportView ReportData={Q15ReportData} />}
                                            </>
                                        </TabPanel>
                                        <TabPanel value='2'>
                                            <>
                                               {onetooneReportData !== null && onetooneReportData.length > 0 && <OnetoOnePatientReportView onetooneReportData={onetooneReportData} selectShiftView={undefined} selectShift={undefined} nextDate={undefined} checkPrecaution={undefined} checkBox={undefined} legendcontrol={undefined} /> }
                                            </>
                                        </TabPanel>
                                        <TabPanel value='3'>
                                            <div>EyeSight</div>
                                            <>
                                                {EyesightReportData !== null && EyesightReportData.legend > 0 && <EyeSightPatientReportView EyesightReportData={EyesightReportData}/> }
                                            </>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </section>
                        </>
                    )
                }

            </div>
        </>
    )
}

export default PatientReport
import React from 'react';
import './calendar.css';

interface CalendarProps {
  date: number;
  day: string;
  onClick: () => void; 
  isSelected: boolean; 
  isPreSelected : boolean;
  selectedShift: any;
}

const Calendar = (props: CalendarProps) => {
  const { date, day, onClick, isSelected, isPreSelected, selectedShift } = props;
  return (
    <React.Fragment>
      <button  className={`calMain ${isSelected ? 'selected' : isPreSelected && selectedShift === 'Shift-C' ? 'selected' : ''}`} onClick={onClick}>
        <div className="calDate">{date}</div>
        <div style={{border:"1px groove"}}/>
        <div className="calDay">{day}</div>
      </button>
    </React.Fragment>
  );
};

export default Calendar
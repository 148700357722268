import React, { useEffect, useState } from "react";
import DateBox from '../../components/calendar/dateBox'
import moment from 'moment';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import { grey, primarybg, primarytext } from '../../common/primary';
import AddEyesight from "../Eyesight/AddEyesight";
import { HttpLogin } from "../../utils/Http";
import { Tooltip } from "@mui/material";
import { Paginator } from 'primereact/paginator'
import EditOneToOneRoutineModal1 from "./editEyesight";
import {
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { authorize } from "../../helpers/common";
import { baseURL, successCode } from "../../configuration/url";
import { fetchPatientData1, getPatientDropdown, getStaffDropdown, getStaffDropdown1 } from "../../slices/thunk";
type ShiftName = 'Shift-A' | 'Shift-B' | 'Shift-C';

const Eyesight = () => {
    const { organization } = useSelector((state: any) => state.Login);
    const { oneData1, oneDataElement1 } = useSelector((state: any) => state.OneToOne)
    const [selectedDate, setSelectedDate] = useState<any>();
    const [Loading, setLoading] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<ShiftName>('Shift-A');
    const [modal, setModal] = useState(false);
    const [editModalview, setEditModalview] = useState(false);
    const [editModal1, setEditModal1] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const dispatch = useDispatch<any>();
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const handleSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    };
    const [formValues, setFormValues] = useState({
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    });

    const initFormData = [{
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        oneToOneObserverName: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: "",
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    }];
    useEffect(() => {
        const handleStartTime = () => {
            const originalLocalStorageGetItem = window.localStorage.getItem.bind(window.localStorage);
            const crtime = originalLocalStorageGetItem("LTime");

            if (crtime) {
                const formattedDate = moment(crtime, "MM/DD/YYYY HH:mm a").format("YYYY-MM-DD");
                setSelectedDate(formattedDate);
            }
        };

        handleStartTime();
    }, []);
    const [PatientDate, setPatientDate] = useState<any[]>(initFormData);
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const [shifts, setShifts] = useState([
        {
            date: "",
            id: "",
            organization: "",
            pid: "",
            shift: [
                {
                    shiftName: "",
                    shiftTime: "",
                    shiftIncharge: "",
                    record: [
                        {
                            distance: "",
                            endTime: "",
                            note: [
                                {
                                    note: "",
                                    time: ""
                                }
                            ],
                            staffId: "",
                            staffName: "",
                            startTime: ""
                        }
                    ]
                }
            ]
        }
    ]);
    const editChange = (patient: any) => {
        const { header1 } = authorize();
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModal1(true);
            setLoading(true);  
            HttpLogin.axios().get(`${baseURL}/eyesight/eyesightPatientList?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&page=${currentPage}&pageSize=5&shiftName=${selectedTab}&search=${searchQuery}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setPatientDate(response.data.data.content || []);
                        setTotalElements(response.data.data.totalElements);
                    } else {
                        setPatientDate([""]);
                    }
                    setLoading(false);  
                });

            HttpLogin.axios().get(`${baseURL}/eyesight/eyesightByPidAndDateAndOrganizationAndShift?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&patientId=${patient.patientId}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: moment(selectedDate).format('YYYYMMDD'),
                                id: responseData.id,
                                organization: organization,
                                pid: responseData.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);  // Update the shifts data
                    } else {
                        setShifts([{
                            date: "",
                            id: "",
                            organization: "",
                            pid: "",
                            shift: [{
                                shiftName: "",
                                shiftTime: "",
                                shiftIncharge: "",
                                record: [{
                                    distance: "",
                                    endTime: "",
                                    note: [{ note: "", time: "" }],
                                    staffId: "",
                                    staffName: "",
                                    startTime: "",
                                }]
                            }]
                        }]);
                    }
                    setLoading(false);  
                });
        }
    };
   
    const toggle = () => {
        setModal(!modal);
        setErrors({});
    };
    const handleDateChange = (event: any) => {       
        const newDate = event.target.value;
        if (newDate) {
          setSelectedDate(newDate);
        }
    };
    const Viewstaff = (patient: any) => {
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModalview(true);
        }
        setLoading(true);
        const { header1 } = authorize();
         try {
            HttpLogin.axios().get(`${baseURL}/eyesight/eyesightByPidAndDateAndOrganizationAndShift?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&patientId=${patient.patientId}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: moment(selectedDate).format('YYYYMMDD'),
                                id: responseData.id,
                                organization: organization,
                                pid: responseData.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setShifts([{
                            date: "",
                            id: "",
                            organization: "",
                            pid: "",
                            shift: [{
                                shiftName: "",
                                shiftTime: "",
                                shiftIncharge: "",
                                record: [{
                                    distance: "",
                                    endTime: "",
                                    note: [{ note: "", time: "" }],
                                    staffId: "",
                                    staffName: "",
                                    startTime: "",
                                }]
                            }]
                        }]);
                    }
                    setLoading(false);
                });                
        setLoading(false);
        } catch (error: any) {
            console.error('API Error:', error)
            setLoading(false);
        }
        //     }
        // }, [searchQuery, currentPage, forDate, organization, selectedTab, year, month, date]);
    }
    useEffect(() => {
        fetchPatientData1(dispatch, organization, currentPage, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), selectedTab)
    }, [editModal1, selectedTab, modal, searchQuery,selectedDate]);

    const fetchPatientDropdownByDate = async (date: any, tab: any) => {
        const { header1 } = authorize();
        setLoading(true);  
        try {

            const response = await axios.get(`${baseURL}/eyesight/eyesightPatientList?date=${date}&organization=${organization}&page=${currentPage}&pageSize=5&shiftName=${tab}`, { headers: header1 });

            if (response.data.message.code === successCode) {
                setPatientDate(response.data.data.content || []);
                setLoading(false); 
            } else {
                setPatientDate([]);
                console.error('No Record:');
            }
        } catch (error) {
            setPatientDate([]);
            console.error('No Record:');
        }
    };
    useEffect(() => {
        const currentDate = `${moment(selectedDate, "YYYY-MM-DD").format('YYYYMMDD')}`;
        fetchPatientDropdownByDate(currentDate, selectedTab);
    }, [selectedDate, selectedTab]);

    useEffect(() => {
        getPatientDropdown(dispatch, organization);
        getStaffDropdown1(dispatch, organization);
        getStaffDropdown(dispatch, organization);
    
      }, [dispatch,organization]);
    return(
        <div>
         {Loading && <Loader />}
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} checkShift={selectedTab} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", height: "79vh" }} >
                <div style={{ width: "15%", backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }} >
                    <div style={{ display: "flex", flexDirection: "column", height: "142px", justifyContent: "space-around", paddingTop: "20px", alignItems: "center" }}>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-A' ? 'active' : ''}`}
                            id="Shift-A-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-A"
                            aria-selected={selectedTab === 'Shift-A'}
                            onClick={() => setSelectedTab('Shift-A')}
                            style={{ backgroundColor: selectedTab === 'Shift-A' ? '#cddcfa' : '', color: 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-A
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-B' ? 'active' : ''}`}
                            id="Shift-B-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-B"
                            aria-selected={selectedTab === 'Shift-B'}
                            onClick={() => setSelectedTab('Shift-B')}
                            style={{ backgroundColor: selectedTab === 'Shift-B' ? '#cddcfa' : '', color: 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-B
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-C' ? 'active' : ''}`}
                            id="Shift-C-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-C"
                            aria-selected={selectedTab === 'Shift-C'}
                            onClick={() => setSelectedTab('Shift-C')}
                            style={{ backgroundColor: selectedTab === 'Shift-C' ? '#cddcfa' : '', color: 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-C
                        </button>
                    </div>
                </div>
                <div style={{ width: "83%", backgroundColor: "#f2f5f9" }} >
                    <div style={{ textAlign: "start", borderBottom: "1px groove #ccd2d8", height: "70px", lineHeight: 3.5, padding: "3px 0px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>{moment(selectedDate).format("ddd DD") + " "}{selectedTab} Eye Sight Staff Assignment </span>
                        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
                        <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                            <i style={{ fontSize: "36px", fontWeight: "lighter" }} className="material-icons" 
                            onClick={toggle}
                            >add</i>
                        </div>
                        <div className="mx-0 search-container d-flex align-items-center">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="search form-control"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <FaSearch className="search-icon mt-1" />
                        </div>
                    </div>
                </div>
                 <table className="table table-bordered" style={{ fontSize: '13px' }}>
                    <thead style={{ backgroundColor: grey }}>
                        <tr>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Date & Time</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Date & Time</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Prescribed by</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Technician</th>
                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, borderRightStyle: "none" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                         {oneData1 && oneData1.length > 0 ? (
                            oneData1.map((patient: any, index: number) => (
                                <tr key={index}>
                                    <td className="text text-center">{currentPage * 5 + index + 1}</td>
                                    <td
                                        style={{ cursor: "pointer", textAlign: "center" }}
                                        onClick={() => editChange(patient)}
                                        className="text"
                                    >
                                        {patient.patientName}
                                    </td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.startTime}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.endTime}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.shiftInchargeName}</td>
                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.staffName}</td>
                                    <td className="text" style={{ display: "flex", justifyContent: "space-around", height: "35px", alignItems: "center", borderRightStyle: "none" }}>
                                        <Tooltip title="Edit" arrow>
                                            <div className="text-primary">
                                                <i
                                                    style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                                    onClick={() => { setEditModal1(true); editChange(patient); }}
                                                    className="material-icons">create</i>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="View" arrow>
                                            <div className="text-primary">
                                                <i
                                                    style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                                    onClick={() => { setEditModalview(true); Viewstaff(patient); }}
                                                    className="material-icons">preview</i>
                                            </div>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))
                        ) : ( 
                            <tr>
                                <td colSpan={8} className="text-center">There are no records....</td>
                            </tr>
                         )} 
                    </tbody>
                </table>
                <div className="pagination-container">
                        <div className="d-flex justify-content-center">
                            <Paginator
                                first={currentPage * 5}
                                rows={5}
                                totalRecords={oneDataElement1}
                                onPageChange={(e: any) => {
                                    setCurrentPage(e.page);
                                    fetchPatientData1(dispatch, organization, e.page, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), selectedTab)
                                    fetchPatientData1(dispatch, organization, e.page, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), selectedTab)
                                }}
                                currentPageReportTemplate={`Page ${currentPage} of ${oneDataElement1 / 5}`}
                            />
                        </div>
                </div>
                
                
                <Modal isOpen={editModalview} centered size='lg'>
                        <div className="d-flex align-items-center justify-content-center vh-90">
                            <div className='row' style={{ width: "100%" }}>
                                <div className='container col-md-12'>
                                    <ModalHeader toggle={() => setEditModalview(false)}>
                                        <span>{formValues.patientName}</span>&nbsp;&nbsp;&nbsp;
                                        {/* <span>{moment(selectedDate).format('DD MMM YYYY') + " "}</span> */}
                                    </ModalHeader>
                                    <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                                        <table className="table table-bordered" style={{ fontSize: '13px' }}>
                                            <thead style={{ backgroundColor: grey }}>
                                                <tr>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Assigned Technician</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Date & Time</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Date & Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                                    shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                                        <>
                                                            <tr
                                                            >
                                                                <td className="text text-center">{currentPage * 5 + recordIndex + 1}</td>
                                                                <td style={{ cursor: "pointer", textAlign: "center" }} className="text" >
                                                                    {record.staffName}
                                                                </td>
                                                                <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                    {record.startTime}
                                                                </td>
                                                                <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{record.endTime}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={4} className="text-center">There are no records...</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                </div>
                            </div>
                        </div >
                    </Modal >
                <AddEyesight
                        IsOpen={modal}
                        Close={toggle}
                        errors={errors}
                        setErrors={setErrors}
                        setPatientDate={setPatientDate}
                        selectedDate={selectedDate}
                        selectedTab={selectedTab}
                        shifts={shifts}
                        patientDropdown={patientDropdown}
                    />
                    <EditOneToOneRoutineModal1 IsOpen={editModal1} closeModal={setEditModal1} formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} shifts={shifts} setShifts={setShifts} forDate={moment(selectedDate).format('YYYYMMDD')} editChange={editChange} selectedTab={selectedTab} />
                    <EditOneToOneRoutineModal1 IsOpen={editModal1} closeModal={setEditModal1} formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} shifts={shifts} setShifts={setShifts} forDate={moment(selectedDate).format('YYYYMMDD')} editChange={editChange} selectedTab={selectedTab} />
        </div>
        </div>
        </div >
    )
}

export default Eyesight
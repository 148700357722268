import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import TeamListTable from "./teamListTable";
import { Button } from "reactstrap";
import { primarybg } from "../../common/primary";
import { DatePicker } from "@mui/x-date-pickers";
import { getStaffDropdown } from "../../slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { authorize } from "../../helpers/common";
import axios from "axios";
import { baseURL, successCode } from "../../configuration/url";
import { toast } from "react-toastify";

const TeamAllocation = () => {
  const [value, setValue] = React.useState("Team-A");
  const [openAddStaff, setOpenAddStaff] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [allTeamData, setAllTeamData] = useState<any>({});
  const { header1 } = authorize();

  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const [staffDropdown,setStaffDropdown] = useState<any>([]);
  const org = useSelector((state: any) => state.Login.organization);

  console.log(staffDropdown, "staffDropdown");
  const handleNewClose = () => {
    setOpenAddStaff(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [selectTeam, setSelectTeam] = useState("");
  const [staffError, setStaffError] = useState(false);
  const [teamError, setTeamError] = useState(false);

  console.log(selectedValues, "selectedValues");
  const isAllSelected =
    staffDropdown?.length > 0 &&
    selectedValues.length === staffDropdown?.length;
  const handleselectChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let allData = staffDropdown?.map((data: any) => data);
      setSelectedValues(
        selectedValues.length === staffDropdown?.length ? [] : allData
      );
      return;
    }
    if (value.length === 0) {
      setStaffError(true);
    } else {
      setStaffError(false);
    }
    setSelectedValues(value);
  };

  const handleSelectTeam = (event: any) => {
    setSelectTeam(event.target.value);
    if (event.target.value === 0) {
      setTeamError(true);
    } else {
      setTeamError(false);
    }
  };

  const getAllStaffByTeam = async () => {
    try {
   
      const response = await axios.get(
        `${baseURL}/team/getTeamStaffDropdown?organization=${org}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        console.log(response.data.data, "0000");
        
        setStaffDropdown(response.data.data);
    
      } else {
   
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllTeam = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/team/allByOrganization?organization=${org}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        console.log(response.data.data, "107");
        const teams = ["Team-A", "Team-B", "Team-C", "Team-D"];
        const filteredData: any = {};

        teams.forEach((teamName) => {
          const team = response.data.data?.filter(
            (data: any) => data?.teamName === teamName
          );
          filteredData[teamName] = team?.length > 0 ? team : [];
        });
        setAllTeamData(filteredData);
        setValue(value);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getAllStaffByTeam();
    getAllTeam();
    // let teamA = getAll?.filter((data) => data?.teamName === "Team-A");
    // console.log(teamA, "teamA");
  }, []);

 

  const handleCreateTeam = async () => {
    setLoading(true);
    let valid = true;

    if (selectTeam === "") {
      setTeamError(true);
      valid = false;
    }

    if (selectedValues.length === 0) {
      setStaffError(true);
      valid = false;
    }

    if (valid) {
      try {
        setLoading(true);

        const staffIds = selectedValues.map((staff: any) => ({
          staffId: staff.id, // Renaming 'id' to 'staffId'
        }));

        console.log(staffIds);
        let formData = {
          organization: organization,
          staff: staffIds,
          teamName: selectTeam,
        };
        console.log(formData, "formData");

        const response = await axios.post(
          `${baseURL}/team/register`,
          formData,
          { headers: header1 }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          handleNewClose();
          setLoading(false);
          setTeamError(false);
          setStaffError(false);
          setSelectedValues([]);
          setSelectTeam('');
          getAllTeam();
          getAllStaffByTeam();
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("API Request Error:", error);
      }
    }

    setLoading(false);
  };
  return (
    <>
      {Loading && <Loader />}
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <h5 style={{ marginLeft: "28px" }}>Team Allocation</h5>
          <Button onClick={() => setOpenAddStaff(true)}>Add Team</Button>
        </div>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >
                <Tab label="Team-A" value="Team-A" />
                <Tab label="Team-B" value="Team-B" />
                <Tab label="Team-C" value="Team-C" />
                <Tab label="Team-D" value="Team-D" />
              </TabList>
            </Box>
            <TabPanel value="Team-A">
              <TeamListTable
                value={value}
                allTeamData={allTeamData["Team-A"]}
              />
            </TabPanel>
            <TabPanel value="Team-B">
              <TeamListTable
                value={value}
                allTeamData={allTeamData["Team-B"]}
              />
            </TabPanel>
            <TabPanel value="Team-C">
              <TeamListTable
                value={value}
                allTeamData={allTeamData["Team-C"]}
              />
            </TabPanel>
            <TabPanel value="Team-D">
              <TeamListTable
                value={value}
                allTeamData={allTeamData["Team-D"]}
              />
            </TabPanel>
          </TabContext>
        </Box>

        <Dialog
          maxWidth={"lg"}
          PaperProps={{
            sx: {
              width: "595px",
              maxHeight: "calc(100% - 40px)",
              top: 25,
            },
          }}
          className="p-5"
          open={openAddStaff}
          onClose={handleNewClose}
        >
          <DialogTitle>Add Team</DialogTitle>
          <DialogContent style={{ overflowY: "auto" }} className="p-4">
            <DialogContentText style={{ paddingLeft: "15px" }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel color="primary">Staff Name</InputLabel>
                    <Select
                      error={staffError}
                      placeholder="Select staff"
                      multiple
                      value={selectedValues} // This should be an array of selected values
                      onChange={handleselectChange}
                      color="primary"
                      size="medium"
                      label="Staff Name"
                      name="staff_name"
                      renderValue={(selectedValues) =>
                        selectedValues
                          .map((value: any) => value?.name)
                          .join(", ")
                      }
                    >
                      {/* Select All / Unselect All MenuItem */}
                      <MenuItem value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllSelected}
                            indeterminate={
                              selectedValues.length > 0 &&
                              selectedValues.length < staffDropdown?.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                      </MenuItem>

                      {/* Render other staff options */}
                      {staffDropdown?.map((newData: any, i: number) => (
                        <MenuItem key={i} value={newData}>
                          <Checkbox
                            checked={selectedValues?.indexOf(newData) > -1} // Check if this item is selectedValues
                          />
                          <ListItemText primary={newData?.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  {staffError && (
                    <span className="form-error">Please select staff</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel color="primary">Team Name</InputLabel>
                    <Select
                      error={teamError}
                      value={selectTeam} // This should be an array of selected values
                      onChange={handleSelectTeam}
                      placeholder="Select staff"
                      color="primary"
                      size="medium"
                      label="Team Name"
                      name="team_name"
                    >
                      <MenuItem value="Team-A">Team-A</MenuItem>
                      <MenuItem value="Team-B">Team-B</MenuItem>
                      <MenuItem value="Team-C">Team-C</MenuItem>
                      <MenuItem value="Team-D">Team-D</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  {teamError && (
                    <span className="form-error">Please select team</span>
                  )}
                </Grid>
                {/* <Grid item md={6}>
                <DatePicker label="Start Date" />
              </Grid>
              <Grid item md={6}>
                <DatePicker label="End date" />
              </Grid> */}
              </Grid>
              {/* <Grid item md={12} sx={{ marginY: "15px" }}>
              {selectedValues?.length > 0 && (
                <>
                  {selectedValues?.map((data: any) => {
                    const firstLetter = data.charAt(0).toUpperCase();
                    return (
                      <>
                        <Chip
                          style={{ marginLeft: "5px" }}
                          variant="outlined"
                          label={`${data}`}
                          color="primary"
                          size="small"
                          avatar={<Avatar>{`${firstLetter}`}</Avatar>}
                        />
                      </>
                    );
                  })}
                </>
              )}
            </Grid> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "10px",
                  paddingBottom: "15px",
                  paddingRight: "15px",
                }}
                className="mt-4"
              >
                <Button style={{ cursor: "pointer" }} onClick={handleNewClose}>
                  Cancel
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                    backgroundColor: primarybg,
                    borderColor: "transparent",
                  }}
                  onClick={() => handleCreateTeam()}
                >
                  Save
                </Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default TeamAllocation;

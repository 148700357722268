import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { baseURL, successCode } from "../../configuration/url";
import { DatePicker } from "@mui/x-date-pickers";
import { authorize, formatDateToYYYYMMDD } from "../../helpers/common";
import { CloseTwoTone } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { primarybg, primarytext } from "../../common/primary";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { HttpLogin } from "../../utils/Http";
import { FaInbox } from "react-icons/fa";
import "./Timeline.css";
const ReportView = () => {
  const [data, setData] = useState<any>([]);
  const [date, setDate] = useState("");
  const [Loading, setLoading] = useState<boolean>(false);
  const { header1 } = authorize();
  const [value, setValue] = React.useState("1");
  const [formValid, setFormValid] = useState({
    location: false,
    activity: false,
  });
  let [displayLocationData, setDisplayLocationData] = useState(
    new Array<any>()
  );
  let [displayActivityData, setDisplayActivityData] = useState(
    new Array<any>()
  );

  const { shiftStartTime } = useSelector((state: any) => state.Org);

  const { loading } = useSelector(
    (state: any) => state.PSConfig
  );

  const [openModal, setOpenModal] = useState(false);
  const org = useSelector((state: any) => state.Login.organization);
  const handleSubmit = async () => {
    if (!date) {
      toast.warning("Please select a date.", {});
      return;
    }
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/config/getByDateShow/${date}/${org}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        setData(response.data.data);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  let Q15RegistryData = {
    location: "",
    activity: "",
    remarks: "",
    reason: "",
    q15Date: "",
    pid: "",
    organization: org,
  };
  const [selectedSlotData, setSelectedSlotData] = useState<any>(null);
  console.log(selectedSlotData, "selectedSlotData");
  let [inputQ15Data, setInputQ15Data] = useState<any>(Q15RegistryData);
    const [patient, setPatient] = useState("");
  const handleIconClick = (
    iconId: any,
    patient: any,
    item: any,
    location: any
  ) => {
    console.log(item , '111')
    setInputQ15Data(() => ({
      q15Date : item?.q15Date,
      pid : item.patientId
  }))
    // inputQ15Data.q15Date = item?.q15Date;
    // inputQ15Data.pid = item.patientId;

    const iconData: any = data.find((items: any) => items.patient === patient);
    setPatient(patient);
    setSelectedSlotData(
      iconData.slotData.find((k: any) => k.q15Slot === iconId)
    );
    setInputQ15Data(iconData.slotData.find((k: any) => k.q15Slot === iconId));
    setOpenModal(true);

    let afterSelected = iconData.slotData.find(
      (k: any) => k.q15Slot === iconId
    );
    const savedUsername = localStorage.getItem("userDetailUsername");
    let filterLocation = displayLocationData?.find(
      (data) => data?.value === afterSelected?.location
    );
    let filterActivity = displayActivityData?.find(
      (data) => data?.value === afterSelected?.activity
    );
    setInputQ15Data(() => ({
      remarks : afterSelected?.remarks ?afterSelected?.remarks :'',
    enteredBy : savedUsername,
    reason : afterSelected?.reason ? afterSelected.reason : "",
    activity : filterActivity?.value ? filterActivity.value : "",
    location : filterLocation?.value ? filterLocation.value : "",
  }))
    // inputQ15Data.remarks = afterSelected?.remarks ? afterSelected.remarks : "";;
    // inputQ15Data.enteredBy = savedUsername;
    // inputQ15Data.reason = afterSelected?.reason ? afterSelected.reason : "";
    // inputQ15Data.activity = filterActivity?.value ? filterActivity.value : "";
    // inputQ15Data.location = filterLocation?.value ? filterLocation.value : "";
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setFormValid(() => ({
      location: false,
      activity: false,
    }));
  };

  useEffect(() => {
    HttpLogin.axios()
      .get(
        `/api/dropdowns/getByOrgIdAndDropdown?dropdown=Location%20Legend&organizationId=${org}&page=-1`
      )
      .then((newInData) => {
        if (newInData.data.message.code === successCode) {
          let newOutData = newInData.data.data[0].list;
          // let entries = Object.entries(newOutData).map(([key, value]) => ({
          //   label: `${value}`,
          //   value: `${key}`,
          // }));
          // console.log(entries, 'entries')
          setDisplayLocationData(newOutData);
          setLoading(false);
        } else {
          alert(newInData.data.message.description);
          setLoading(false);
        }
      });

    HttpLogin.axios()
      .get(
        `/api/dropdowns/getByOrgIdAndDropdown?dropdown=Activity%20Legend&organizationId=${org}&page=-1`
      )
      .then((newInData) => {
        if (newInData.data.message.code === successCode) {
          let newOutData = newInData.data.data[0].list;
          // let entries = Object.entries(newOutData).map(([key, value]) => ({
          //   label: `${value}`,
          //   value: `${key}`,
          // }));
          setDisplayActivityData(newOutData);
          setLoading(false);
        } else {
          alert(newInData.data.message.description);
          setLoading(false);
        }
      });
  }, [org]);
  const handleTimeSlotChange = (event: React.SyntheticEvent, newValue: any) => {
    // timeSlotChange = event;
    setValue(newValue);
  };
  const handleChangeInputs = (e: any, identifyer: any) => {
    let value = e.target.value;
    if (identifyer === "location") {
      if (value === "") {
        inputQ15Data.location = value;

        setFormValid((prev) => ({
          ...prev,
          location: true,
        }));
      } else {
        inputQ15Data.location = value;

        setFormValid((prev) => ({
          ...prev,
          location: false,
        }));
      }
      setInputQ15Data({ ...inputQ15Data });
    }

    if (identifyer === "activity") {
      inputQ15Data.activity = value;
      if (value === "") {
        inputQ15Data.activity = value;
        setFormValid((prev) => ({
          ...prev,
          activity: true,
        }));
      } else {
        inputQ15Data.activity = value;
        setFormValid((prev) => ({
          ...prev,
          activity: false,
        }));
      }
      setInputQ15Data({ ...inputQ15Data });
    }
  };
  const handleUpdateConfig = async () => {
    let valid = true;
    let formData = {
      ...inputQ15Data,
      q15Time: selectedSlotData?.q15Time,
      shiftIncharge: selectedSlotData?.shiftIncharge,
    };
    if (inputQ15Data.location === "") {
      valid = false;
      setFormValid((prev) => ({
        ...prev,
        location: true,
      }));
    }

    if (inputQ15Data.activity === "") {
      valid = false;
      setFormValid((prev) => ({
        ...prev,
        activity: true,
      }));
    }

    if (valid) {
      try {
        setLoading(true);

        const { header1 } = authorize();
        const response = await axios.post(
          `${baseURL}/config/register`,
          formData,
          { headers: header1 }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          handleCloseModal();
          handleSubmit();
          setLoading(false);

          setFormValid(() => ({
            location: false,
            activity: false,
          }));
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("API Request Error:", error);
      }
    } else {
      toast.error("Please enter the data !");
    }
  };

  return (
    <>
      {Loading && <Loader />}
      {loading && <Loader />}

      <div className="p-2">
        <div className="row summary-page_row d-flex mt-2 justify-content-center align-items-center mb-4">
          <div className="col-md-3">
            <DatePicker
              label={"Enter Date"}
              format="MM-DD-YYYY"
              onChange={(date: any) => {
                setDate(formatDateToYYYYMMDD(date));
              }}
            />
          </div>
          <div className="col-md-2">
            <Button className="calender_submit" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          {/* <div className="col-md-1"></div> */}
          <div className="col-md-2 d-flex align-items-center">
            <h5 className="mb-0">Q15 Summary</h5>
          </div>
          <div className="col-md-5 d-flex justify-content-end gap-3 align-items-center">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#355c7d52", marginRight: "5px" }}
              />
              <span>Done</span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#426cc3", marginRight: "5px" }}
              />
              <span>No Proximity</span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#ff7171", marginRight: "5px" }}
              />
              <span>Missing</span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#edb405", marginRight: "5px" }}
              />
              <span>Late</span>
            </div>
          </div>
        </div>

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              scrollButtons
              onChange={handleTimeSlotChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label="Shift-A" value="1" />
              <Tab label="Shift-B" value="2" />
              <Tab label="Shift-C" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {data?.length > 0 ? (
              <TableContainer component={Paper} sx={{ width: "99%" }}>
                <Table size="small" sx={{ borderSpacing: 0 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "#f6f5f599",
                      borderTop: "1px groove #c0c1c2",
                      borderBottom: "1px groove #c0c1c2",
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f5f599",
                        borderTop: "1px groove #c0c1c2",
                        borderBottom: "1px groove #c0c1c2",
                      }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                          color: primarytext,
                          borderRight: "1px groove",
                        }}
                      >
                        Patient
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          color: primarytext,
                          borderRight: "1px groove",
                        }}
                      >
                        Bed Number
                      </TableCell>
                      {Array.from({ length: 8 }, (_, index) => {
                        const startHourInt = parseInt(
                          shiftStartTime.split(":")[0],
                          10
                        );

                        const currentHour = startHourInt + index;
                        const startHour =
                          (currentHour % 24).toString().padStart(2, "0") +
                          ":00";

                        // Calculate the end hour (next hour), again ensuring it doesn't exceed 24
                        const endHour =
                          ((currentHour + 1) % 24).toString().padStart(2, "0") +
                          ":00";

                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              padding: 0,
                              color: primarytext,
                              whiteSpace: "nowrap",
                              borderRight: "1px groove",
                            }}
                            key={index}
                          >
                            {startHour}-{endHour}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: any) => (
                      <TableRow sx={{ fontSize: "9px" }} key={item.id}>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "start",
                            lineHeight: "1.2",
                            borderRight: "1px groove",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <p style={{ paddingTop: "12px" }}>{item.patient}</p>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "start",
                            lineHeight: "1.2",
                            borderRight: "1px groove",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <p style={{ color: "#000", paddingTop: "12px" }}>
                              {item.bed}
                            </p>
                          </div>
                        </TableCell>
                        {[...Array(8)].map((_, index) => {
                          const startHourInt = parseInt(
                            shiftStartTime.split(":")[0],
                            10
                          );

                          const slotId = `${(index + startHourInt)
                            .toString()
                            .padStart(2, "0")}`;

                          return (
                            <TableCell
                              sx={{
                                fontSize: "9px",
                                padding: 0,
                                borderRight: "1px groove",
                              }}
                              key={index}
                            >
                              <div
                                className="d-flex justify-content-center "
                                style={{ gap: "4px" }}
                              >
                                {["A", "B", "C", "D"].map((iconPrefix) => {
                                  const iconId = `${iconPrefix}${slotId}`;
                                  const iconData: any = data.find(
                                    (items: any) =>
                                      items.patient === item.patient
                                  );

                                  const slotData = iconData.slotData.find(
                                    (k: any) => k.q15Slot === iconId
                                  );
                                  const color = slotData?.proximityStatus
                                    ? "#426cc3"
                                    : slotData?.slotLateEntry
                                    ? "#edb405"
                                    : slotData?.slotMissed
                                    ? "#ff7171"
                                    : !slotData
                                    ? "#ff7171"
                                    : "#355c7d52";
                                  return (
                                    <FontAwesomeIcon
                                      key={iconId}
                                      icon={faCircle}
                                      style={{
                                        marginRight: 1,
                                        color: color,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleIconClick(
                                          iconId,
                                          iconData.patient,
                                          item,
                                          iconData.location || ""
                                        );
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" className="table_empty">
                <FaInbox className="empty_svg" />
                No Data found for the selected Date
              </Typography>
            )}
          </TabPanel>
          <TabPanel value="2">
            {data?.length > 0 ? (
              <TableContainer component={Paper} sx={{ width: "99%" }}>
                <Table size="small" sx={{ borderSpacing: 0 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "#f6f5f599",
                      borderTop: "1px groove #c0c1c2",
                      borderBottom: "1px groove #c0c1c2",
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f5f599",
                        borderTop: "1px groove #c0c1c2",
                        borderBottom: "1px groove #c0c1c2",
                      }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                          color: primarytext,
                          borderRight: "1px groove",
                        }}
                      >
                        Patient
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          color: primarytext,
                          borderRight: "1px groove",
                        }}
                      >
                        Bed Number
                      </TableCell>
                      {Array.from({ length: 8 }, (_, index) => {
                        const startHourInt = parseInt(
                          shiftStartTime.split(":")[0],
                          10
                        );

                        const currentHour = startHourInt + index;
                        const startHour =
                          ((currentHour + 8) % 24).toString().padStart(2, "0") +
                          ":00";

                        // Calculate the end hour (next hour), again ensuring it doesn't exceed 24
                        const endHour =
                          ((currentHour + 9) % 24).toString().padStart(2, "0") +
                          ":00";

                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              padding: 0,
                              color: primarytext,
                              whiteSpace: "nowrap",
                              borderRight: "1px groove",
                            }}
                            key={index + 8}
                          >
                            {startHour}-{endHour}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: any) => (
                      <TableRow sx={{ fontSize: "9px" }} key={item.id}>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "start",
                            lineHeight: "1.2",
                            borderRight: "1px groove",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <p style={{ paddingTop: "12px" }}>{item.patient}</p>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "start",
                            lineHeight: "1.2",
                            borderRight: "1px groove",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <p style={{ color: "#000", paddingTop: "12px" }}>
                              {item.bed}
                            </p>
                          </div>
                        </TableCell>
                        {[...Array(8)].map((_, index) => {
                          const startHourInt = parseInt(
                            shiftStartTime.split(":")[0],
                            10
                          );

                          const slotId = `${((index + startHourInt + 8) % 24)
                            .toString()
                            .padStart(2, "0")}`;

                          return (
                            <TableCell
                              sx={{
                                fontSize: "9px",
                                padding: 0,
                                borderRight: "1px groove",
                              }}
                              key={index}
                            >
                              <div
                                className="d-flex"
                                style={{ justifyContent: "center", gap: "4px" }}
                              >
                                {["A", "B", "C", "D"].map((iconPrefix) => {
                                  const iconId = `${iconPrefix}${slotId}`;
                                  const iconData: any = data.find(
                                    (items: any) =>
                                      items.patient === item.patient
                                  );
                                  const slotData = iconData.slotData.find(
                                    (k: any) => k.q15Slot === iconId
                                  );
                                  const color = slotData?.proximityStatus
                                    ? "#426cc3"
                                    : slotData?.slotLateEntry
                                    ? "#edb405"
                                    : slotData?.slotMissed
                                    ? "#ff7171"
                                    : !slotData
                                    ? "#ff7171"
                                    : "#355c7d52";
                                  return (
                                    <FontAwesomeIcon
                                      key={iconId}
                                      icon={faCircle}
                                      style={{
                                        marginRight: 1,
                                        color: color,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleIconClick(
                                          iconId,
                                          iconData.patient,
                                          item,
                                          iconData.location || ""
                                        );
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" className="table_empty">
                <FaInbox className="empty_svg" />
                No Data found for the selected Date
              </Typography>
            )}
          </TabPanel>
          <TabPanel value="3">
            {data?.length > 0 ? (
              <TableContainer component={Paper} sx={{ width: "99%" }}>
                <Table size="small" sx={{ borderSpacing: 0 }}>
                  <TableHead
                    sx={{
                      backgroundColor: "#f6f5f599",
                      borderTop: "1px groove #c0c1c2",
                      borderBottom: "1px groove #c0c1c2",
                    }}
                  >
                    <TableRow
                      sx={{
                        backgroundColor: "#f6f5f599",
                        borderTop: "1px groove #c0c1c2",
                        borderBottom: "1px groove #c0c1c2",
                      }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                          color: primarytext,
                          borderRight: "1px groove",
                        }}
                      >
                        Patient
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          color: primarytext,
                          borderRight: "1px groove",
                        }}
                      >
                        Bed Number
                      </TableCell>
                      {Array.from({ length: 8 }, (_, index) => {
                        const startHourInt = parseInt(
                          shiftStartTime.split(":")[0],
                          10
                        );

                        const currentHour = startHourInt + index;
                        const startHour =
                          ((currentHour + 16) % 24)
                            .toString()
                            .padStart(2, "0") + ":00";

                        // Calculate the end hour (next hour), again ensuring it doesn't exceed 24
                        const endHour =
                          ((currentHour + 17) % 24)
                            .toString()
                            .padStart(2, "0") + ":00";

                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              padding: 0,
                              color: primarytext,
                              whiteSpace: "nowrap",
                              borderRight: "1px groove",
                            }}
                            key={index + 16}
                          >
                            {startHour}-{endHour}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: any) => (
                      <TableRow sx={{ fontSize: "9px" }} key={item.id}>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "start",
                            lineHeight: "1.2",
                            borderRight: "1px groove",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <p style={{ paddingTop: "12px" }}>{item.patient}</p>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            textAlign: "start",
                            lineHeight: "1.2",
                            borderRight: "1px groove",
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <p style={{ color: "#000", paddingTop: "12px" }}>
                              {item.bed}
                            </p>
                          </div>
                        </TableCell>
                        {[...Array(8)].map((_, index) => {
                          const startHourInt = parseInt(
                            shiftStartTime.split(":")[0],
                            10
                          );

                          const slotId = `${((index + startHourInt + 16) % 24)
                            .toString()
                            .padStart(2, "0")}`;
                          return (
                            <TableCell
                              sx={{
                                fontSize: "9px",
                                padding: 0,
                                borderRight: "1px groove",
                              }}
                              key={index}
                            >
                              <div
                                className="d-flex"
                                style={{ justifyContent: "center", gap: "4px" }}
                              >
                                {["A", "B", "C", "D"].map((iconPrefix) => {
                                  const iconId = `${iconPrefix}${slotId}`;
                                  const iconData: any = data.find(
                                    (items: any) =>
                                      items.patient === item.patient
                                  );
                                  const slotData = iconData.slotData.find(
                                    (k: any) => k.q15Slot === iconId
                                  );
                                  const color = slotData?.proximityStatus
                                    ? "#426cc3"
                                    : slotData?.slotLateEntry
                                    ? "#edb405"
                                    : slotData?.slotMissed
                                    ? "#ff7171"
                                    : !slotData
                                    ? "#ff7171"
                                    : "#355c7d52";
                                  return (
                                    <FontAwesomeIcon
                                      key={iconId}
                                      icon={faCircle}
                                      style={{
                                        marginRight: 1,
                                        color: color,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleIconClick(
                                          iconId,
                                          iconData.patient,
                                          item,
                                          iconData.location || ""
                                        );
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" className="table_empty">
                <FaInbox className="empty_svg" />
                No Data found for the selected Date
              </Typography>
            )}
          </TabPanel>
        </TabContext>

        <Dialog
          maxWidth={"lg"}
          PaperProps={{
            sx: {
              width: "595px",
              paddingBottom: "20px",
            },
          }}
          className="p-5"
          open={openModal}
          onClose={handleCloseModal}
        >
          <div>
            <DialogTitle sx={{ height: "45px" }}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p style={{ fontWeight: "bold", color: "#717171" }}>
                    {patient}
                  </p>
                </div>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseTwoTone />
                </IconButton>
              </div>
            </DialogTitle>
            <hr />
            <DialogContent>
              <DialogContentText>
                <div>
                  {selectedSlotData ? (
                    <>
                      <div className="row ">
                        <div className="col-md-4">
                          <div>
                            <TextField
                              value={selectedSlotData.q15Time}
                              disabled={true}
                              style={{ width: "96.5%" }}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Q15 Time"
                              size="medium"
                              margin="none"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <TextField
                              value={selectedSlotData.incharge}
                              disabled={true}
                              style={{ width: "96.5%" }}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Incharge"
                              size="medium"
                              margin="none"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <TextField
                              value={selectedSlotData.enteredBy}
                              disabled={true}
                              style={{ width: "90.5%" }}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Entered By"
                              size="medium"
                              margin="none"
                            />
                          </div>
                        </div>
                        <div className="col-md-5 pt-3">
                          <div>
                            <FormControl
                              fullWidth
                              style={{ width: "95%" }}
                              variant="outlined"
                            >
                              <InputLabel color="primary">
                                Location Legend
                              </InputLabel>
                              <Select
                                error={formValid.location}
                                color="primary"
                                size="medium"
                                label="Location Legend"
                                name="q15Location"
                                value={inputQ15Data?.location}
                                onChange={(e) => {
                                  handleChangeInputs(e, "location");
                                }}
                              >
                                {displayLocationData.map(
                                  (newData: any, i: number) => {
                                    return (
                                      <MenuItem key={i} value={newData.value}>
                                        {newData.value}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              <FormHelperText />
                              {formValid.location && (
                                <span className="form-error">
                                  Please enter location
                                </span>
                              )}
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-7 pt-3">
                          <FormControl
                            style={{ width: "94.5%" }}
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel color="primary">
                              Activity Legend
                            </InputLabel>
                            <Select
                              color="primary"
                              error={formValid.activity}
                              size="medium"
                              label="Activity Legend"
                              name="q15Activity"
                              value={inputQ15Data.activity}
                              onChange={(e) => {
                                handleChangeInputs(e, "activity");
                              }}
                            >
                              {displayActivityData.map(
                                (newData: any, i: number) => {
                                  return (
                                    <MenuItem key={i} value={newData.value}>
                                      {newData.value}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                            <FormHelperText />
                            {formValid.activity && (
                              <span className="form-error">
                                Please enter activity
                              </span>
                            )}
                          </FormControl>
                        </div>
                        <div className="col-md-12  pt-3">
                          <TextField
                            value={inputQ15Data.remarks}
                            onChange={(e) => {
                              inputQ15Data.remarks = e.target.value;
                              setInputQ15Data({ ...inputQ15Data });
                            }}
                            style={{ width: "96.5%" }}
                            multiline
                            rows={2}
                            color="primary"
                            variant="outlined"
                            type="text"
                            label="Notes/remarks"
                            size="medium"
                            margin="none"
                            required
                          />
                        </div>
                        {selectedSlotData?.reason ? (
                          <div className="col-md-12  pt-3">
                            <TextField
                              value={inputQ15Data.reason}
                              onChange={(e) => {
                                inputQ15Data.reason = e.target.value;
                                setInputQ15Data({ ...inputQ15Data });
                              }}
                              style={{ width: "96.5%" }}
                              multiline
                              rows={2}
                              color="primary"
                              variant="outlined"
                              type="text"
                              label="Beacon Scanning Override"
                              size="medium"
                              margin="none"
                              required
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <p>No Data found for the slot</p>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div
                className="d-flex gap-3 justify-content-center align-items-center"
                style={{ height: "5px" }}
              >
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                    backgroundColor: primarybg,
                    borderColor: "transparent",
                  }}
                  onClick={handleUpdateConfig}
                >
                  Save
                </Button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
};
export default ReportView;

import React, { useEffect, useState, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBedAssign
} from "../../slices/bedAssign/thunk";
import { getAllBed } from "../../slices/patientAssign/thunk";
import { HttpLogin } from "../../utils/Http";
import { patientDischarge, updatePatientDetails } from "../../slices/thunk";
import { Autocomplete, TextField, Chip, DialogTitle, Dialog, DialogContent, DialogContentText, FormControlLabel, Checkbox, Tooltip, Select, MenuItem, ListItem, IconButton } from '@mui/material';
import { Button } from "primereact/button";
import axios from "axios";
import { baseURL, successCode } from "../../configuration/url";
import { faDoorOpen, faBed, faPencil, faBan, faQrcode } from "@fortawesome/free-solid-svg-icons";
import dischargeIcon from '../../assets/images/admit2.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, CardBody, CardTitle, CardSubtitle, CardFooter, Card, Badge, List,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter } from "reactstrap";
import patientImage from './../../assets/images/patientImage.png'
import { toast } from "react-toastify";
import { authorize } from "../../helpers/common";
import roomimg from "../../assets/images/room.svg";
import bedimg from "../../assets/images/bed.svg";
import LogoImg from "../../assets/images/profile.svg";
import Loader from "../../components/loader/Loader";
import { FaQrcode, FaSearch } from "react-icons/fa";
import { getPatientSuccess, getTotalElementsSuccess } from "../../slices/patient/reducer";
import { BrowserMultiFormatReader } from "@zxing/library";
import { Bed } from "@mui/icons-material";
import { grey } from "../../common/primary";

const AdmitPatient: React.FC = () => {

  const { loading } = useSelector((state: any) => state.Patient);
  let [genderDropDown, setGenderDropDown] = useState(new Array<any>());
  const dispatch = useDispatch<any>();
  const [selectPatientId, setSelectPatientId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const { organization, userData } = useSelector((state: any) => state.Login);
  const userId = userData?.userDetail?.id;
  const [bedAssignDialog, setBedAssignDialog] = useState(false);
  const [bedClick, setBedClick] = useState(false)
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [bedId, setBedId] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState('')
  const [scanning, setScanning] = useState(false);
  const codeReader = new BrowserMultiFormatReader();
  const videoRef = useRef(null);
  const [options, setOptions] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  let [bedSelected, setBedSelected] = useState<string | null>(null);
  let [admitPatientData, setAdmitPatientData] = useState(new Array<any>());

  useEffect(() => {
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/Q15Bed/getAdmittedPatient`, { headers: header1 });
        setAdmitPatientData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchPatientsandBedAssign = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(
          `${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 }
        );
        if (response?.data?.data && Array.isArray(response?.data?.data)) {
          setPatientAndBedAssign(response?.data?.data);
        } else {
          console.error("Invalid data format for patients:", response.data);
        }
      } catch (error) {
        console.warn(error);
      }
    };
    const fetchDropDownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getPatientsBeacon/${organization}`, { headers: header1 });
        setOptions(response?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchDropDownData();
    fetchData();
    fetchPatientsandBedAssign();
    return () => {
      setAdmitPatientData([])
    }
  }, []);

  const bedsByNumber: { [key: string]: any[] } = {};
  patientAndBedAssign?.forEach((bedassign) => {
    let { bedNo } = bedassign;
    if (!bedsByNumber[bedNo]) {
      bedsByNumber[bedNo] = [];
    }
    bedsByNumber[bedNo]?.push(bedassign);
  });

  const handleSearch = async (value: any) => {
    const { header1 } = authorize();
    if (value !== null) {
      const response = await axios.get(`${baseURL}/patient/sortBy?search=${value}&page=0`, { headers: header1 })
      if (response?.data?.message?.code === successCode) {
        dispatch(getPatientSuccess(response?.data?.data?.content))
        dispatch(getTotalElementsSuccess(response?.data?.data?.totalElements))
      } else {
        dispatch(getPatientSuccess([]))
        dispatch(getTotalElementsSuccess(0))
      }
    }
  }

  const handleBedClick = (selectedBed: any) => {
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
    setBedClick(!bedClick)
  };

  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };

  const handleDioCancel = () => {
    setBedSelected(null);
    setBedAssignDialog(false);
    setBedClick(false);
  }

  const getPatientName = (patientId: string) => {
    const selectedPatient = admitPatientData?.find((patient: any) => patient?.id === patientId);
    
    if (selectedPatient) {
      if (selectedPatient?.name && selectedPatient?.name?.length > 0) {
        const { family, given } = selectedPatient?.name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      } else if (selectedPatient?.basicDetails && selectedPatient?.basicDetails?.length > 0) {
        const { family, given } = selectedPatient?.basicDetails[0]?.name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      }
    }
    console.warn(`Patient data issue for ID: ${patientId}`, selectedPatient);
    return "Unknown";
  };

  const handleSave = async () => {
    const { header1 } = authorize();
    const selectedPatient = admitPatientData?.find((patient: any) => patient.id === selectPatientId);
    if(selectedPatient){
      const basicDetails = selectedPatient?.basicDetails[0];
      const address = selectedPatient?.contact[0]?.address[0];
      const updatedPatientFields = {
        id: selectPatientId,
        basicDetails: [
          {
            name: [
              {
                use: basicDetails?.name[0]?.use || "",
                given: basicDetails?.name[0]?.given || "",
                family: basicDetails?.name[0]?.family || "",
              },
            ],
            ssn: basicDetails?.ssn || "",
            mrNumber: basicDetails?.mrNumber || "",
            gender: basicDetails?.gender || "",
            birthDate: basicDetails?.birthDate || "",
          },
        ],
        email: selectedPatient?.email || "",
        organization,
        contact: [
          {
            address: [
              {
                addressLine1: address?.addressLine1 || "",
                addressLine2: address?.addressLine2 || "",
                city: address?.city || "",
                state: address?.state || "",
                postalCode: address?.postalCode || "",
                country: address?.country || "",
              },
            ],
          },
        ],
        beaconDevice:deviceId
      };
      dispatch(
        updatePatientDetails(
          selectPatientId !== null ? selectPatientId:"",
          updatedPatientFields,
          organization,
          currentPage
        )
      );   
       if(selectedDate !== ""){
       try {
      const requestBody = {
        bedId: bedId,
        pid: selectPatientId,
        assignedBy: userId,
        admitDate: selectedDate.replace(/-/g, "")
      };
      const response = await axios.post(
        `${baseURL}/Q15Bed/transfer`,
        requestBody, { headers: header1 }
      );
      if (
        response?.data?.message &&
        response?.data?.message?.code === successCode
      ) {
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization);
        toast.success(response?.data?.message?.description)
        setBedAssignDialog(false)
        setBedClick(false)
      } else {
        console.error("Error:", response?.data?.message);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
    }      
    }
   
  }

  const handleNewBedClick  = (selectedBed: any) => {
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
  };

  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
        setScanning(true);
        const videoInputDevices = await codeReader.listVideoInputDevices();
        const selectedDeviceId = videoInputDevices[0].deviceId;
        const constraints = {
            video: { deviceId: selectedDeviceId },
        };
        codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            videoRef.current,
            (result: any, err: any) => {
              if (result) {
                const result1 = result.getText();
                if (result1 !== "" && result1 !== undefined && result1 !== null) {
                  if(result1.length < 18){
                    const resultId = result1.match(/.{2}/g).join(':'); 
                    setDeviceId(resultId);
                  }else{
                    const resultId = result1.match(/.{2}/g).join(':');                
                    setDeviceId(resultId.slice(6,23));                             
                  }
                  setShow(false);
                  setScanning(false);                             
                }        
                const modal = document.getElementById("exampleModal");
                if (modal) {
                  modal.classList.add("show");
                  modal.style.display = "block";
                }                  
            }
                if (err && err.name === "NotFoundError") {
                    console.error("No QR code found in the video feed.");
                }
                if (err) {
                    console.error("Error during scanning:", err);
                }
            },
        );
    } catch (error) {
        console.error("Error starting the scanner:", error);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };


  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleQrClick = () => {
    if (options?.length < 1) return;
    setShow(true);
    setScanning(!scanning);
  }

 

  return (
    <div className="container m5 p3" style={{ width: '90%' }}>
      {loading && <Loader />}
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <h5>Active Patient List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <FaSearch className="search-icon" />
          </div>

        </div>
        <div className="row">
          <div className="col-md-3">

          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th scope="col" className="text-center table-data">S.No</th>
              <th scope="col" className="text-center table-data">Patient Name</th>
              <th scope="col" className="text-center table-data">SSN</th>
              <th scope="col" className="text-center table-data">Date Of Birth</th>
              <th scope="col" className="text-center table-data">Beacon Device</th>
              <th scope="col" className="text-center table-data">Room No - Bed No</th>
              <th scope="col" className="text-center table-data">Action</th>
            </tr>
          </thead>
          <tbody>
            {admitPatientData?.map((patient: any, index: number) => (
              <tr key={index}>
                <td className="text">{index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                  className="text"
                >
                  {patient?.basicDetails[0]?.name[0]?.given}{" "}
                  {patient?.basicDetails[0]?.name[0]?.family}
                </td>
                <td className="text">{'***' + patient.basicDetails[0]?.ssn?.slice(-4)}</td>
                <td className="text">{patient?.basicDetails[0]?.birthDate?.slice(4, 6) + '-' + patient?.basicDetails[0]?.birthDate?.slice(6, 8) + '-' + patient?.basicDetails[0]?.birthDate?.slice(0, 4)}</td>
                <td className="text">{patient?.beaconDevice}</td>
                <td className="text">{patient?.assignedBed}</td>
                <td className="text d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setBedAssignDialog(true);
                        setSelectPatientId(patient?.id);
                      }}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Dialog maxWidth={'xl'} PaperProps={{ sx: { width: '90%', maxWidth: '90%', position: 'absolute', height: '95vh', top: '1px' } }}
          open={bedAssignDialog}
          onClose={() => setBedAssignDialog(false)}
        >
          <DialogTitle>    <div style={{ fontWeight: 600 }}>All Beds</div></DialogTitle>
          <DialogContentText >
            <DialogContent style={{ padding: '20px', background: '#F8FAFB' }}>
              <div style={{ display: "flex", justifyContent: "flex-start", padding: "10px 10px", alignItems: "center", gap: "10px" }}>

                <div>
                  <label htmlFor="Date"> Date</label>
                  <input style={{ height: '40px', borderColor: "#bfc0c1", backgroundColor: grey }} type="date" id="Date" value={selectedDate} onChange={handleDateChange} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor="DeviceId"> Device Id</label>

                  <Select
                    // multiple
                    value={deviceId}
                    id="DeviceId"
                    onChange={(e) => setDeviceId(e.target.value)}
                    style={{ height: '40px', width: '300px' }}
                    IconComponent={() => (
                      <FaQrcode
                        className="position-absolute bottom-0 m-3"
                        role='button' onClick={handleQrClick}
                        style={{ fontSize: '1.2rem', color: '#000', right: 0, cursor: 'pointer', margin: 0, padding: 0 }}
                      />
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '300px',
                        },
                      },
                    }}
                  >
                    {options?.length > 0 ? options?.map((option: any) => (
                      <MenuItem key={option?.id} value={option?.deviceId}>
                        {option?.deviceId}
                      </MenuItem>
                    )) : 
                      <MenuItem>
                        No Device Data
                      </MenuItem>
                    }
                  </Select>

                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor="Room"> Room-Bed</label>
                  <TextField
                    id="Room"
                    type="text"
                    placeholder=''
                    margin="none"
                    size="small"
                  value={bedSelected ? bedSelected : ""}
                    disabled={true}
                    InputProps={{
                      endAdornment: (
                        <IconButton edge="end">
                          <Bed />
                        </IconButton>
                      )
                    }}
                  />
                </div>
              </div>
              <div>
                {Object?.entries(bedsByNumber)?.map(([bedNo, assignments]) => (
                  <Row key={bedNo} style={{ display: "flex", flexWrap: "wrap", justifyContent: bedNo === '1' ? 'space-evenly' : 'flex-start', marginBottom: "40px" }}>
                    {Array.isArray(assignments) && assignments?.length > 0 ? (
                      assignments?.map((bedassign, index) => (
                        bedassign?.pid !== null ? <>
                          <Col key={bedassign?.id} style={{ flex: 0, padding: 0 }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="danger"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign?.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className=" text-muted">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign?.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>
                                <CardFooter style={{
                                  padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                                  background: bedNo === '01' ? '#f8b19570' : (bedNo === '02' ? '#c06c8470' : (bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>
                                  <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{getPatientName(bedassign?.pid)}{patientAndBedAssign?.filter((item: any) => item?.roomNo === bedassign?.roomNo)?.length}</span>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </> : <>
                          <Col key={index} style={{ flex: 0, padding: 0 }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="primary"
                                outline
                                style={{
                                  width: "92px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  onClick={() => handleNewBedClick(bedassign)}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign?.roomNo}</span>
                                    </CardTitle>
                                  <CardSubtitle tag="h6" className="text-muted ">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign?.bedNo}</span>
                                    </CardSubtitle>
                                </CardBody>

                                <CardFooter style={{
                                  padding: '2px 0.6rem 5px 13px ', position: 'relative', top: '-13px', height: '33px',
                                  background: bedNo === '01' ? '#f8b19570' : (bedNo === '02' ? '#c06c8470' : (bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>

                                  <Badge
                                    style={{ fontSize: '10px' }}
                                    color={bedassign.pid ? "danger" : "success"}
                                    tag="h6"
                                  >
                                    {bedassign?.pid ? "Not Available" : "Available"}
                                  </Badge>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </>
                      ))) : (
                      <p>No bed assignments available.</p>
                    )}
                  </Row>
                ))}
              </div>
            </DialogContent>
          </DialogContentText>
          <div className="d-flex gap-3 mb-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
              onClick={handleDioCancel}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
          </div>
        </Dialog>
        <Dialog open={show} onClose={handleClose1}>
        <DialogTitle >Scanning</DialogTitle>
        <DialogContent>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </DialogContent>
      
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
            <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
          </div>    
      </Dialog>
      </div>
    </div>
  );

};

export default AdmitPatient;
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Loader from "../../components/loader/Loader";
import { FaInbox } from "react-icons/fa";
import { FaCircleMinus } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import { toast } from "react-toastify";
import "./q15slot.css";
interface ShiftProps {
  startTime: number; // Start time (e.g., 4, 6, 8, 12, 24)
  duration: number; // Shift duration (e.g., 4, 6, 8, 12, 24 hours)
}
const AddShiftStaff = ({
  selectedDate,
  selectedTab,
  allTeamData,
  getAllTeam,
  noOfShift,
}: any) => {
  const { shiftStartTime } = useSelector((state: any) => state.Org);
  const [AllTeamData, setAllTeamData] = useState<any>([]);
  const [openRemoveStaff, setOpenRemoveStaff] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [selectedRemove, setSelectedRemove] = useState<any>({});
  const startTime = new Date(`2000-01-01T${shiftStartTime}`);
  const [submitStaff, setSubmitStaff] = useState(false);
  const org = useSelector((state: any) => state.Login.organization);
  const endTime = new Date(startTime.getTime() + 8 * 60 * 60 * 1000);
  const { header1 } = authorize();
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [openAddStaff, setOpenAddStaff] = useState(false);
  const [inchargeErr, setInchargeErr] = useState(false);
  let [incharge, setIncharge] = useState<any>("");
  const { orgData } = useSelector((state: any) => state.Org);
  const [shifts, setShifts] = useState<any>([]);

  const handleStaffRemove = (data: any) => {
    setSelectedRemove(data);
    setOpenRemoveStaff(true);
    // setSelectedValues((prevData: any) =>
    //   prevData.filter((staff: any) => staff?.staffId !== data?.staffId)
    // );
  };
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      default:
        return "No Content Available";
    }
  };

  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    // Convert the start time string to a Date object
    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    for (let i = 0; i < totalShifts; i++) {
      // Calculate the end time by adding the duration to the start time
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      ); // duration in hours

      // Format start and end times into 'HH:MM' format
      const startTimeFormatted = startTime.toTimeString().slice(0, 5);
      const endTimeFormatted = endTime.toTimeString().slice(0, 5);

      // Assign shift name (A, B, C, etc.)
      const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

      // Push shift details into the array
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
      });

      // Update the start time for the next shift
      startTime = endTime;
    }

    // Set the shifts state
    setShifts(shifts);
  };

  useEffect(() => {
    setLoading(true);
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    setLoading(false);
    const initialSelected = allTeamData?.flatMap((shift: any) =>
      shift.staff.filter((staff: any) => staff.checkIn === true)
    );
    const allStaff = allTeamData?.flatMap((shift: any) => shift.staff);
    setAllTeamData(allStaff);

    setSelectedValues(initialSelected);

    // Check if all items are selected based on their checked: true status
    const allChecked = AllTeamData?.every(
      (staff: any) => staff.checked === true
    );
  }, [selectedTab, allTeamData]);

  const handleSubmitRemove = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${baseURL}/shiftStaff/removeStaff?date=${moment(selectedDate).format(
          "YYYYMMDD"
        )}&organization=${org}&shiftName=${renderTabContent()}&staffId=${
          selectedRemove?.staffId
        }`,
        {
          headers: header1,
        }
      );
      if (response.data.message && response.data.message.code === successCode) {
        toast.success(response.data.message.description);
        setLoading(false);
        getAllTeam();
        setOpenRemoveStaff(false);
      }
    } catch (error) {
      console.log("Error on remove staff", error);
      setLoading(false);
    }
  };

  const handleSubmitAdd = async () => {
    const missingObjects = AllTeamData?.filter(
      (obj1: any) =>
        !selectedValues?.some((obj2: any) => obj2.staffId === obj1.staffId)
    );

    const updatedMissingObjects = missingObjects?.map((obj: any) => ({
      ...obj,
      checkIn: false,
    }));

    const updatedSelectedObjects = selectedValues?.map((selectedObj: any) => ({
      ...selectedObj,
      checkIn: true,
    }));

    const finalUpdatedData = [
      ...updatedMissingObjects,
      ...updatedSelectedObjects,
    ];

    try {
      setLoading(true);
      let formData = {
        date: moment(selectedDate).format("YYYYMMDD"),
        organization: org,
        shift: [
          {
            endTime:
              selectedTab === 6
                ? shifts[0]?.end_time
                : shifts[selectedTab]?.end_time,
            shiftIncharge: incharge,
            shiftName: renderTabContent(),
            staff: finalUpdatedData,
            startTime:
              selectedTab === 6
                ? shifts[0]?.start_time
                : shifts[selectedTab]?.start_time,
          },
        ],
      };
      console.log(formData, "formData");
      const response = await axios.post(
        `${baseURL}/shiftStaff/register`,
        formData,
        {
          headers: header1,
        }
      );
      if (response.data.message && response.data.message.code === successCode) {
        toast.success(response.data.message.description);
        setLoading(false);
        getAllTeam();
        setOpenAddStaff(false);
        setSelectedValues([]);
      }
    } catch (error) {
      console.log("error on register staff ", error);
      setOpenAddStaff(false);
      setLoading(false);
    }
  };

  const handleNewClose = () => {
    setOpenRemoveStaff(false);
  };
  const handleCloseAdd = () => {
    setOpenAddStaff(false);
  };

  const isAllSelected =
    AllTeamData?.length > 0 && selectedValues.length === AllTeamData?.length;
  const handleselectChange = (event: any) => {
    console.log(AllTeamData, "2323");
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let allData = AllTeamData?.map((data: any) => data);
      setSelectedValues(
        selectedValues.length === AllTeamData?.length ? [] : allData
      );
      return;
    }
    if (value.length === 0) {
      setSubmitStaff(true);
    } else {
      setSubmitStaff(false);
    }

    setSelectedValues(value);
  };

  const shiftAEndTime = `${endTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${endTime.getMinutes().toString().padStart(2, "0")}`;
  const BEndTime = new Date(endTime.getTime() + 8 * 60 * 60 * 1000);
  const shiftBEndTime = `${BEndTime.getHours()
    .toString()
    .padStart(2, "0")}:${BEndTime.getMinutes().toString().padStart(2, "0")}`;

  const { rnInchargeList, socialWorkerList } = useSelector(
    (state: any) => state.PSConfig
  );

  const handleAddShifStaff = () => {
    let valid = false;
    if (incharge === "") {
      setInchargeErr(true);
      valid = false;
    } else {
      setInchargeErr(false);

      valid = true;
    }

    if (valid) {
      setOpenAddStaff(true);
    } else {
      setOpenAddStaff(false);
    }
  };
  const setInchargeChange = (value: any) => {
    setIncharge(value || "");
    if (value === "") {
      setInchargeErr(true);
    } else {
      setInchargeErr(false);
    }
  };
  return (
    <div>
      {Loading && <Loader />}

      <div className="row" style={{ alignItems: "center", width: "100%" }}>
        <div className="col-md-4">
          <h5>
            {moment(selectedDate)?.format("ddd DD")} {renderTabContent()} Q15
            Shift
          </h5>
          <div style={{ fontSize: "14px" }}>
            <span>
              Start Time :{" "}
              {selectedTab === 6
                ? shifts[0]?.start_time
                : shifts[selectedTab]?.start_time}
            </span>

            <span style={{ marginLeft: "5px" }}>
              End Time :{" "}
              {selectedTab === 6
                ? shifts[0]?.end_time
                : shifts[selectedTab]?.end_time}
            </span>
          </div>
        </div>

        <div className="col-md-3">
          <Autocomplete
            sx={{ width: "200px" }}
            onChange={(e, value) => setInchargeChange(value?.id || "")}
            value={
              rnInchargeList?.find((item: any) => item?.id === incharge) || null
            }
            disablePortal
            options={rnInchargeList}
            getOptionLabel={(option) =>
              option.name[0]?.given + " " + option.name[0]?.family
            }
            renderInput={(params) => (
              <TextField
                error={inchargeErr}
                {...params}
                label="Select RN Incharge"
              />
            )}
          />
          {inchargeErr && (
            <span className="form-error">Please Select Incharge</span>
          )}
        </div>
        <div className="col-md-3">
          <FormControl variant="outlined" fullWidth>
            <InputLabel color="primary">Staff Name</InputLabel>
            <Select
              // error={staffError}
              placeholder="Select staff"
              multiple
              sx={{ width: "200px" }}
              value={selectedValues} // This should be an array of selected values
              onChange={handleselectChange}
              color="primary"
              size="medium"
              label="Staff Name"
              name="staff_name"
              renderValue={(selectedValues) =>
                selectedValues.map((value: any) => value?.staffName).join(", ")
              }
            >
              {/* Select All / Unselect All MenuItem */}
              {/* {allTeamData?.length > 0 ?(<> */}
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selectedValues.length > 0 &&
                      selectedValues.length < AllTeamData?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>

              {/* Render other staff options */}
              {AllTeamData?.map((newData: any, i: number) => (
                <MenuItem key={i} value={newData}>
                  <Checkbox
                    checked={selectedValues?.indexOf(newData) > -1} // Check if this item is selectedValues
                  />
                  <ListItemText primary={newData?.staffName} />
                </MenuItem>
              ))}
              {/* </>):(<>
                        <MenuItem >No Options</MenuItem>
                      </>) } */}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="col-md-1">
          <Button
            disabled={selectedValues.length === 0}
            onClick={() => handleAddShifStaff()}
          >
            Submit
          </Button>
        </div>
      </div>
      <hr />
      <div style={{ overflowY: "auto", height: "260px", overflowX: "hidden" }}>
        {selectedValues?.length === 0 ? (
          <>
            <Typography variant="body1" className="table_empty">
              <FaInbox className="empty_svg" />
              No Staff found for this Shift
            </Typography>
          </>
        ) : (
          <div
            className="row"
            style={{ alignItems: "center", marginTop: "20px" }}
          >
            {selectedValues?.map((data: any, index: number) => {
              return (
                <div
                  className="col-md-2 staff_card"
                  key={index}
                  title={data?.staffName}
                >
                  <h5 className="selected_staff_h5">{data?.staffName}</h5>
                  <Divider />
                  <h6 style={{ fontSize: "12px" }}>bc:12:34:56:89</h6>
                  <h6 style={{ fontSize: "12px" }}>Location : N/A</h6>
                  <IconButton
                    sx={{ position: "absolute", top: "0", right: "0" }}
                    aria-label="Add"
                    onClick={() => handleStaffRemove(data)}
                  >
                    <MdCancel style={{ color: "#ff0000a1" }} />
                  </IconButton>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "7px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "5px",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <TimelineDot
                          color="success"
                          sx={{ margin: "0" }}
                        />
                        <span >In</span>
                      </div>
                      <h6 style={{ fontSize: "11px" }}>
                        {moment(data?.inTime, "YYYYMMDDHHmmss").format("hh:mm")}
                      </h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "5px",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <TimelineDot color="error"   sx={{ margin: "0" }} />
                        <span >Out</span>
                      </div>

                      <h6 style={{ fontSize: "11px" }}>09:00</h6>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-md-3">
                    <TextField
                      fullWidth
                      label="Staff Name"
                      disabled
                      value={data?.staffName}
                      variant="standard"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Device ID"
                      disabled
                      value="N/A"
                      variant="standard"
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Location"
                      disabled
                      value="N/A"
                      variant="standard"
                    />
                  </div>
                  <div className="col-md-3">
                    <IconButton
                      aria-label="Add"
                      onClick={() => handleStaffRemove(data)}
                    >
                      <FaCircleMinus style={{ color: "#0f3995" }} />
                    </IconButton>
                  </div> */}
          </div>
        )}
      </div>
      {/* <Button
        style={{
          float: "right",
          marginTop: "20px",
          backgroundColor: "#0f3995",
        }}
      >
        Save Changes
      </Button> */}

      <Modal
        isOpen={openRemoveStaff}
        toggle={handleNewClose}
        centered
        style={{
          fontFamily: "calibri",
          fontSize: "20px",
          maxWidth: "500px",
          width: "80%",
          height: "300px",
        }}
      >
        <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
          Confirmation
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            gap: "4px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              position: "relative",
              top: "-5px",
            }}
          >
            Are you sure you want to delete - `{selectedRemove?.staffName}`
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <Button color="primary" onClick={() => handleSubmitRemove()}>
              Yes
            </Button>
            <Button onClick={handleNewClose}>No</Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={openAddStaff}
        toggle={handleCloseAdd}
        centered
        style={{
          fontFamily: "calibri",
          fontSize: "20px",
          maxWidth: "500px",
          width: "80%",
          height: "300px",
        }}
      >
        <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
          Add Staff for {renderTabContent()}
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            gap: "4px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              position: "relative",
              top: "-5px",
            }}
          >
            Are you sure you want to add this Staff's
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <Button color="primary" onClick={() => handleSubmitAdd()}>
              Yes
            </Button>
            <Button onClick={handleCloseAdd}>No</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddShiftStaff;

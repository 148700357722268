import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { baseURL } from '../../configuration/url';
import { grey, primarybg, primarytext } from '../../common/primary';
import { FaSearch } from 'react-icons/fa';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SuperAdminAdd from './SuperAdminAdd';

const SuperAdmin = () => {
    const navigate = useNavigate()
  const [superAdminData, setData] = useState<any>([]);
  const [showDialog, setShowDialog] = useState(true);
  const [secretKey, setSecretKey] = useState('');
  const validKey = '123456';
  const [modal, setModal] = useState(false)

  const dataApi = async () => {
    const response = await axios.get(`${baseURL}/superAdmin/get`);
    setData(response?.data?.data);
  };

  useEffect(() => {
    if (!showDialog) {
      dataApi();
    }
  }, [showDialog]);

  const handleCloseDialog = () => {
    navigate('/login')
  };

  const handleSuccess = () => {
    setShowDialog(false);
  };

  const handleKeySubmit = () => {
    if (secretKey === validKey) {
      handleSuccess();
    } else {
        navigate('/login')
    }
  };

  const handleAdd = () => {
    setModal(true)
  }
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div>
      {/* Dialog for Secret Key */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showDialog}
        onClose={() => {}}
        PaperProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            width: '35%',
          },
        }}
      >
        <DialogTitle>
          Enter Secret Key
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="px-1 py-4"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <TextField
              id="secretKey"
              label="Enter Your Secret Key"
              variant="outlined"
              fullWidth
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
            />
            <div className="d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#0f3995',
                  fontSize: '12px',
                  height: '30px',
                  width: '70%',
                  fontWeight: 'bold',
                }}
                onClick={handleKeySubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Main Component */}
      {!showDialog && (
        <div className="p-3">
          <div className="row p-2">
            <div className="col-md-8"></div>
            <div className="col-md-4 d-flex justify-content-end gap-2">
              <div className="mx-0 search-container d-flex align-items-center">
                <input
                  style={{ height: '32px', fontSize: '12px' }}
                  type="text"
                  placeholder="Search..."
                  className="search form-control"
                />
                <FaSearch className="search-icon " />
              </div>
              <div
                style={{
                  backgroundColor: primarybg,
                  top: 0,
                  borderRadius: '4px',
                  cursor: 'pointer',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '30px',
                  height: '30px',
                }}
              >
                <i
                  style={{ fontSize: '33px', fontWeight: 'lighter' }}
                  className="material-icons"
                  onClick={handleAdd}
                >
                  add
                </i>
              </div>
            </div>
          </div>
          <table className="table table-bordered" style={{ fontSize: '13px' }}>
            <thead style={{ backgroundColor: grey }}>
              <tr>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  S.No{' '}
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  First Name
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Username
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Timezone
                </th>
              </tr>
            </thead>
            <tbody>
              {superAdminData?.map((data: any, index: any) => (
                <tr key={index}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td>{data?.firstName}</td>
                  <td>{data?.lastName}</td>
                  <td>{data?.email}</td>
                  <td>{data?.username}</td>
                  <td>{data?.timeZone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {modal && (
        <SuperAdminAdd modal={modal} toggle={toggle} />
      )

      }
    </div>
  );
};

export default SuperAdmin;

import { useNavigate } from "react-router-dom";
import {
  getAllOrganizationDetails,
  deleteOrganizationDetails,
  activeOrg,
} from "../../slices/organizationDetails/thunk";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { FaBroadcastTower, FaSearch } from "react-icons/fa";
import React, { useEffect, useRef, useState } from "react";
import "./form.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrganizationForm from "./Form";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Close } from "@mui/icons-material";
import { getOrgDataByIdSuccess } from "../../slices/organization/reducer";
import Loader from "../../components/loader/Loader";
import { grey, primarybg, primarytext, secondarybg } from "../../common/primary";
import LogoutConfirmationModal from "../../components/LogoutModel";
import { getOrganizationDetailsSuccess } from "../../slices/organizationDetails/reducer";
import { setOrgTimeZone } from "../../slices/LargeScreen/reducer";

interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

const Organization: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [activeModal, setActiveModal] = useState<boolean>(false)
  const [activeDetail, setActiveDetail] = useState<any>()
  const [activeMessage, setActiveMessage] = useState<any>('')

  const toggle = () => {
    setModal(!modal);
  };
  const { organizationDetails, totalElements, loading } = useSelector(
    (state: any) => state.Organization

  );
  const userEmail = localStorage.getItem('userDetailEmail');
  const [dropdownData, setDropdownData] = useState<Dropdown[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
    setCurrentPage(0); 
  };
  
  const [organizationId, setOrganizationId] = useState("");
  const [secretKey, setSecretKey] = useState({
    pinNumber: ['', '', '', '', '', '']
  });
  const secretcode = secretKey.pinNumber.join('')
  const inputRefs = useRef<any>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
    return () => {
      dispatch(getOrganizationDetailsSuccess([]))
    }
  }, []);

  const handleInputChangep = (e: any, index: any) => {
    const newValue = e.target.value;
    setSecretKey(prevState => {
      const newData = [...prevState.pinNumber];
      newData[index] = newValue;
      return {
        pinNumber: newData
      };
    });
    if (index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const maskPassword = (index: any) => {
    const inputElement = document.getElementById(`pinNumber${index}`) as HTMLInputElement;
    inputElement.type = 'text';
    setTimeout(() => {
      inputElement.type = 'password';
    }, 400);
  };
  const handleBackspaceAndEnter = (e: any, index: any) => {
    if (e.key === 'Backspace') {
      if (index > 0 && secretKey.pinNumber[index] === '') {
        const prevInput = document.getElementById(`pinNumber${index - 1}`) as HTMLInputElement;
        prevInput.focus();
        setSecretKey(prevState => {
          const newData = [...prevState.pinNumber];
          newData[index - 1] = '';
          return {
            pinNumber: newData
          };
        });
      } else {
        setSecretKey(prevState => {
          const newData = [...prevState.pinNumber];
          newData[index] = '';
          return {
            pinNumber: newData
          };
        });
      }
    }
    if (e.key === 'Enter') {
    }
  };

  useEffect(() => {
    if (currentPage === 0) {
      getAllOrganizationDetails(dispatch, 0, search ? search : '');
    }
  }, [dispatch, search]);
  const handleOrgChange = (organizationId: string, orgName: string) => {
    navigate(`/beacon-table/${organizationId}`);
    window.localStorage.setItem("OrgName", orgName);
  }

  const handleActiveModalOpen = (organization: any) => {
    setActiveModal(!activeModal)
    setActiveDetail(organization)
    if (organization?.active) {
      setActiveMessage(`Are you sure to inactive this "${organization?.organizationdetails?.[0]?.name}"?`)
    } else {
      setActiveMessage(`Are you sure to activate "${organization?.organizationdetails?.[0]?.name}"?`)
    }
  }

  const handleAvtiveModalClo = () => {
    setActiveModal(!activeModal)
    setActiveDetail('')
  }

  const handleActivatedModal = async (organization: any) => {
    if (organization?.active) {
      handleopen(organization.id, organization.organizationdetails?.[0]?.name)
      handleAvtiveModalClo()
    } else {
      await dispatch(activeOrg(organization.id, currentPage));
      handleAvtiveModalClo()
    }
  }

  const handleClick = (organization: any) => {
    dispatch(getOrgDataByIdSuccess([]))
    navigate(`/org-update/${organization.id}`)
  };

  const [show, setShow] = useState(false);
  const handleClose1 = () => {
    secretKey.pinNumber = ["", "", "", "", "", ""]
    setShow(false)
  };
  const [orgName1, setOrgName1] = useState("")
  const handleopen = (orgID: any, orgName: any) => {
    setShow(true);
    window.localStorage.setItem("OrgName", orgName);
    setOrgName1(orgName)
    setOrganizationId(orgID);
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdown/ehr/getByDropdown?dropdown=OrganizationType`, { headers: header1 });
        if (response && response.data.message && response.data.message.code === successCode) {
          setDropdownData(response.data.data[0].list);
        } else {
          console.error(
            "Error fetching dropdown data:",
            response.data.message.description
          );
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };
    fetchDropdownData();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      secretKey: secretKey.pinNumber,
      email: userEmail
    };
    axios.post(`${baseURL}/org/checkSecretKey?email=${userEmail}&secretKey=${secretcode}`)
      .then(response => {
        if (response.data.message && response.data.message.description) {
          if (response.data.message.code === successCode) {
            try {
              dispatch(deleteOrganizationDetails(organizationId, currentPage));
              console.warn("Organization Inactivated successfully");
              handleClose1()
            } catch (error) {
              console.warn("Failed to inActive organization");
            }
          } else {
            toast.error(response.data.message.description)
          }
        } else {
          toast.warn("Unexpected response structure");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleorgtimeset = (org:any) => {
    dispatch(setOrgTimeZone(org.timeZone))
    localStorage.setItem('orgName',org.organizationdetails[0].name)
  }
  return (
    <div className="container" style={{ paddingTop: "10px" }}>
      {loading && <Loader />}
      <div className="row">
        <div className="col-md-12">
          <div className="row mb-2">
            <div className="col-md-5 d-flex align-items-center">
              <h5>Client/Organization Details</h5>
            </div>
            <div className="col-md-3">
            </div>
            <div className="col-md-4 d-flex justify-content-end gap-2">
              <div style={{ backgroundColor: primarybg, top: 0, borderRadius: "4px", cursor: "pointer", color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "30px", height: "30px" }}>
                <i style={{ fontSize: "33px", fontWeight: "lighter" }} onClick={toggle} className="material-icons">add</i>
              </div>
              <div className="mx-0 search-container d-flex align-items-center" >
                <input
                  value={search}
                  style={{ height: '32px', fontSize: '12px' }}
                  type="text"
                  placeholder="Search..."
                  className="search form-control"
                  onChange={handleSearch}
                />
                <FaSearch className="search-icon mt-1" />
              </div>
            </div>
          </div>
          <table className="table table-bordered" style={{ fontSize: "13px" }}>
            <thead style={{ backgroundColor: grey }}>
              <tr>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  S.No{" "}
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Organization Name
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Organization Type
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Q15 Access
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  SOS
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  One To One
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Eye Sight
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Proximity
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Geo Fencing
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  One Hour
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ color: primarytext, backgroundColor: grey }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {organizationDetails.length > 0 ? organizationDetails.map((organization: any, index: number) => (
                <tr key={index}>
                  <td>{currentPage * 10 + index + 1}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() =>{
                       handleClick(organization)
                       handleorgtimeset(organization)
                      
                      }}

                  >
                    {organization.organizationdetails?.[0]?.name || ""}
                  </td>
                  <td>{organization.organizationdetails?.[0]?.type || ""}</td>
                  <td className="text-center">
                    {organization.q15Access === "Yes" ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center">
                    {organization.sos === true ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center">
                    {organization.q15OneToOne === true ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center">
                    {organization.eyeSight === true ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center">
                    {organization.proximityVerification === "Yes" ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center">
                    {organization?.geofencing === "Yes" ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center">
                    {organization.q15OneHour === true ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                  </td>
                  <td className="text-center d-flex justify-content-around align-items-center">
                    <Tooltip title="Edit" arrow>
                      <div className="text-primary">
                        <i
                          style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                          onClick={() =>{
                            handleClick(organization)
                            handleorgtimeset(organization)
                           
                           }}
                          className="material-icons">create</i>
                      </div>
                    </Tooltip>
                    <Tooltip title="Device List" arrow>
                    <div
                      className="text-primary"
                      style={{ position: 'relative', top: '-4px', cursor: "pointer" }}
                    >
                      <FaBroadcastTower
                        onClick={() => {
                          handleClick(organization);
                          handleorgtimeset(organization);
                          handleOrgChange(
                            organization.id,
                            organization.organizationdetails?.[0]?.name
                          );
                        }}
                      />
                    </div>

                    </Tooltip>
                    <Tooltip title={organization.active ? "Active" : "inActive"} arrow>
                      <div className="text-primary">
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: organization.active ? '#5FB477' : '#EF6868',
                            display: "inline-block",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handleActiveModalOpen(organization)
                          }}
                        />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              )) :
                <tr>
                  <td colSpan={7} className="text-center">
                    <h5>No Client/Organization found</h5>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <LogoutConfirmationModal open={activeModal} handleConfirm={() => handleActivatedModal(activeDetail)} handleClose={() => handleAvtiveModalClo()} message={activeMessage} />
      <Dialog
          maxWidth={'sm'}
          fullWidth
          PaperProps={{
            sx: {
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              width: '35%',
            }
          }}
          open={show}
          onClose={handleClose1}
        >
          <DialogTitle>
            Organization: {orgName1}
            <IconButton
              aria-label="close"
              onClick={() => handleClose1()}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div
            className="px-1 py-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <TextField
                disabled
                id="email"
                label="Email"
                value={userEmail}
                variant="outlined"
                fullWidth
              />
              <label
                htmlFor="code"
                style={{
                  fontSize: '14px',
                  marginBottom: '4px',
                  textAlign: 'center'
                }}
              >
                Enter Your Passcode
              </label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                {secretKey.pinNumber.map((value: any, index: any) => (
                  <input
                    style={{
                      width: '40px',
                      height: '40px',
                      textAlign: 'center',
                      fontSize: '15px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                    }}
                    itemID="code"
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    id={`pinNumber${index}`}
                    // className={`passwordText${index + 1}`}
                    name="pinNumber"
                    value={value}
                    onKeyDown={(e) => handleBackspaceAndEnter(e, index)}
                    required
                    onChange={(e) => {
                      handleInputChangep(e, index);
                      maskPassword(index);
                    }}
                    maxLength={1}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-center">
              <Button label="Inactive"
                style={{ backgroundColor: '#0f3995', fontSize: '12px', height: '30px', width:'70%', fontWeight: 'bold' }}
                onClick={handleSubmit}></Button>
            </div>
            </div>
          </DialogContent>
        </Dialog>

      <OrganizationForm modal={modal} toggle={toggle} currentPage={currentPage} />
      <div className="pagination-container" >
        <div className="d-flex justify-content-center">
          <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {
              setCurrentPage(e.page)
              getAllOrganizationDetails(dispatch, e.page, search ? search : '');
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Organization;
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import * as Constants from "../Constants/Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL, successCode } from '../../configuration/url';
import { FaQrcode } from 'react-icons/fa';
import { DatePicker } from '@mui/x-date-pickers';
import { authorize, formatDateToYYYYMMDD, formatSSN, formatEmail } from '../../helpers/common';
import { DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardFooter,
  Badge,
} from "reactstrap";
import { getAllOrgPatient, getAllPatient, getDeviceDropdown } from '../../slices/thunk';
import patientImage from './../../assets/images/patientImage.png'
import {
  getAllBedAssign,
} from "../../slices/bedAssign/thunk";
import { getAllBed } from "../../slices/patientAssign/thunk";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { Bed } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { BrowserMultiFormatReader } from '@zxing/library';
import { CircularProgress, InputLabel } from '@material-ui/core';
import uploadImage from "../../assets/images/rectangle-59991.svg";
import Loader from '../../components/loader/Loader';
import PatientBed from '../AllPatient/patientBed';
import './patient.css'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useZipcode from '../../hooks/usefetch';

interface PatientCreationFormProps {
  modal: boolean;
  toggle: () => void;
  currentPage: number;
}
const PatientCreation: React.FC<PatientCreationFormProps> = ({ modal, toggle, currentPage }) => {
  const [zipCode, setZipCode] = useState("");
  const { data, zipError, zipLoading } = useZipcode(zipCode);
  const [loading1, setLoading1] = useState(false);
  useEffect(() => {
    const locationData = data.data?.[0];

    if (data)
      if (locationData) {
        formValues.state = locationData.state || "";
        formValues.country = locationData.country || "";
        formValues.city = locationData.city || "";
        setCityDropDown([locationData.city]);
        setLoading1(false);
      } else {
        formValues.state = "";
        formValues.country = "";
        formValues.city = "";
        setCityDropDown([]);
        setLoading1(false);
      }
  }, [data]);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [admitD, setAdmitD] = useState<boolean>(false)
  const [admitDbed, setAdmitDbed] = useState<boolean>(false)
  let [genderDropDown, setGenderDropDown] = useState(new Array<any>());
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const { organization } = useSelector((state: any) => state.Login);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [bedAssignDialog, setBedAssignDialog] = useState(false);
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const dispatch = useDispatch<any>()
  const [bedId, setBedId] = useState<string | null>(null);
  const [reason, setReason] = useState('')
  const { roleList } = useSelector((state: any) => state.PSConfig)
  const [admitDate, setAdmitDate] = useState<any>('')
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [options, setOptions] = useState<any>([]);
  const [deviceIds, setDeviceIds] = useState<string[]>([]);
  const videoRef = useRef(null);
  const codeReader = new BrowserMultiFormatReader();
  const [scanning, setScanning] = useState(false);
  const [device, setDevice] = useState<any>('');
  const [newPatientData, setNewPatientData] = useState<any[]>([]);
  let [highlightdate, sethighlightdate] = useState(false);
  let [highlightdate1, sethighlightdate1] = useState(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  useEffect(() => {
    fetchNewPatientData();
    fetchPatientsandBedAssign();
  }, [selectedDevices]);

  let [newAssignedBy, setAssignedBy] = useState<string | null>(null);
  let [bedSelected, setBedSelected] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false)

  const org = useSelector((state: any) => state.Login.organization);
  useEffect(() => {
    getDeviceDropdown(dispatch, organization)
  }, [organization]);
  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const handleBedClick = (selectedBed: any) => {
    setAdmitError({ ...admitError, bedSelected: false })
    setAdmitError({ ...admitError, bedSelected: false })
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
  };

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      const constraints = {
        video: { deviceId: selectedDeviceId },
      };
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            const result1 = result.getText();
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 18) {
                const resultId = result1.match(/.{2}/g).join(':');
                setDevice(resultId);
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                setDevice(resultId.slice(6, 23));
              }
              setShow(false);
              setScanning(false);
            }
            const modal = document.getElementById("exampleModal");
            if (modal) {
              modal.classList.add("show");
              modal.style.display = "block";
            }
          } else {
            toast.error("Scanned device ID not found in the options.");
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };
  const fetchNewPatientData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/patient/get/activePatientMob/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setNewPatientData(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  }
  const fetchPatientsandBedAssign = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const [show, setShow] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };


  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleQrClick = () => {
    if (options?.length < 1) return;
    setShow(true);
    setScanning(!scanning);
  }
  const initFormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    ssn: '',
    gender: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    mrNumber: '',
    email: '',
    deviceId: '',
    profile: ''
  }

  let [formValues, setFormValues] = useState(initFormData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
  const [admitError, setAdmitError] = useState<{ [key: string]: boolean }>({})

  const handleCancel = () => {
    setFormValues(initFormData);
    setBedSelected(null)
    setDisabled(false)
    setSelectedOption([])
    setDevice('')
    setSelectedDevices([])
    toggle();
    setNameError({})
    setAdmitDbed(false)
    setExpanded(false)
    setAdmitDate('')
    setBedSelected('')
    setAdmitError({ ...admitError, admitDate: false, reason: false, device: false, bedSelected: false })
    setReason('')
    setReason('')
    setSelectedStaff('')
  }

  const bedsByNumber = patientAndBedAssign.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});

  const getPatientName = (patientId: string) => {
    const selectedPatient = newPatientData?.find((patient: any) => patient.id === patientId);

    if (selectedPatient) {
      if (selectedPatient.name && selectedPatient.name.length > 0) {
        const { family, given } = selectedPatient.name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      } else if (selectedPatient.basicDetails && selectedPatient.basicDetails.length > 0) {
        const { family, given } = selectedPatient.basicDetails[0].name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      }
    }
    return "Unknown";
  };
  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      setLoading(true)
      const { header1 } = authorize();
      const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Gender&organizationId=${org}&page=-1`, { headers: header1 })
      if (response.data.message.code === successCode) {
        setGenderDropDown(response.data.data[0].list);
        setLoading(false)
      } else {
        setGenderDropDown([]);
        setLoading(false)
      }
    }
    fetchDropdownData();
  }, []);

  const handleSaveClick = async (save: boolean) => {
    let newErrors = {
      firstName: !formValues.firstName,
      ssn: !formValues.ssn,
      email: !formValues.email,
      postalCode: !formValues.postalCode,
      middleName: !formValues.middleName,
      lastName: !formValues.lastName,
      birthDate: !formValues.birthDate,
      addressLine1: !formValues.addressLine1,
      addressLine2: !formValues.addressLine2,
      city: !formValues.city,
      state: !formValues.state,
      country: !formValues.country,
      mrNumber: !formValues.mrNumber,
      ...( !disabled && { gender: !formValues.gender } )
    }
    setNameError(newErrors)
    if (expanded) {
      if (admitDate || reason || device || bedSelected) {
        let admitError = {
          admitDate: !admitDate,
          reason: !reason,
          device: !device,
          selectedStaff: !selectedStaff,
          bedSelected: !bedSelected
        }
        setAdmitError(admitError)
        const hasErrors = Object.values(admitError).some(error => error);
        if (hasErrors) {
          toast.error("Please Fill Admit Required Field")
          return;
        }
        // else {
        //   handleChange(!expanded)
        // }
      } else {
        handleChange(!expanded)
        setAdmitError({ ...admitError, device: false, reason: false, admitDate: false, bedSelected: false })
      }
    }
    const hasErrors = Object.values(newErrors).some(error => error);
    if (hasErrors) {
      toast.error("Please Fill Required Field")
      return;
    }
    if (formValues.postalCode.length > 0 && formValues.city === "" && formValues.state === "") {
      formValues.city = "";
      formValues.state = "";
      formValues.country = "";
      toast.error("Please Enter Valid Zip Code");
      return;
    }
    const zipcode = {
      Postalcode: "",
      country: "",
      city: "",
      state: ""
    }
    const requestBody = {
      id: "",
      active: (admitDate === '') ? "0" : "1",
      resource: [
        {
          fullUrl: "",
          resourceType: ""
        }
      ],
      basicDetails: [
        {
          coding: [
            {
              system: "",
              code: "",
              display: ""
            }
          ],
          name: [
            {
              use: formValues.middleName,
              given: formValues.firstName,
              family: formValues.lastName,
            }
          ],
          gender: !disabled ? formValues.gender : '',
          birthDate: formValues.birthDate,
          mrNumber: formValues.mrNumber,
          ssn: (formValues.ssn).slice(0, 9),
          profile: "",
          licenseId: "",
          confirmEmail: "",
          get_birthDate: [
            {
              extension: [
                {
                  url: ""
                }
              ]
            }
          ],
          maritalStatus: "",
          sexualOrientation: ""
        }
      ],
      email: formValues.email,
      organization,
      deviceId1: deviceIds,
      contact: [
        {
          address: [
            {
              addressLine1: formValues.addressLine1,
              addressLine2: formValues.addressLine2,
              city: formValues.city,
              state: formValues.state || '',
              postalCode: formValues.postalCode,
              country: formValues.country || ''
            }
          ],
          motherName: "",
          firstName: "",
          lastName: "",
          homePhone: "",
          workPhone: "",
          mobilePhone: "",
          contactEmail: "",
          trustedEmail: "",
          emergency: [
            {
              relationShip: "",
              emergencyContact: "",
              emergencyPhone: ""
            }
          ],
          additionalAddress: [
            {
              addressUse: "",
              addressType: "",
              startDate: "",
              endDate: "",
              addressLine1: "",
              addressLine2: "",
              city: "",
              district: "",
              state: "",
              postalCodeNumber: "",
              Country: ""
            }
          ]
        }
      ],
      userType: "",
      employer: [
        {
          occupation: "",
          city: "",
          state: "",
          postalCode: "",
          Country: "",
          unassignedUSA: "",
          industry: "",
          addressLine1: "",
          addressLine2: ""
        }
      ],
      guardian: [
        {
          name: "",
          relationship: "",
          gender: "",
          address: [
            {
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              postalCode: "",
              Country: ""
            }
          ],
          workPhone: "",
          email: ""
        }
      ],
      misc: [
        {
          dateDeceased: "",
          reason: ""
        }
      ],
      stats: [
        {
          languageDeclined: true,
          ethnicityDeclined: true,
          raceDeclined: true,
          language: "",
          ethnicity: "",
          race: "",
          familySize: 0,
          financialReviewDate: "",
          monthlyIncome: "",
          homeless: "",
          interpreter: "",
          migrant: "",
          referralSource: "",
          religion: "",
          vfc: ""
        }
      ],
      insurance: [
        {
          primary: [
            {
              planName: "",
              subscriber: "",
              effectivedate: "",
              relationship: "",
              policyNumber: "",
              birthDate: "",
              groupNumber: "",
              ss: "",
              subscriberEmployee: "",
              subscriberPhone: "",
              city: "",
              state: "",
              Country: "",
              zipCode: "",
              gender: "",
              subscriberAddress: [
                {
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  state: "",
                  Country: "",
                  zipCode: ""
                }
              ],
              co_pay: "",
              acceptAssignment: "",
              title: "",
              seaddress: ""
            }
          ],
          secondary: [
            {
              insuranceDetails: {
                planName: "",
                subscriber: "",
                effectivedate: "",
                relationship: "",
                policyNumber: "",
                birthDate: "",
                groupNumber: "",
                ss: "",
                subscriberEmployee: "",
                subscriberPhone: "",
                city: "",
                state: "",
                Country: "",
                zipCode: "",
                gender: "",
                subscriberAddress: [
                  {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    Country: "",
                    zipCode: ""
                  }
                ],
                co_pay: "",
                acceptAssignment: "",
                title: "",
                seaddress: ""
              }
            }
          ]
        }
      ],
      familyHealth: [
        {
          id: "",
          name: "",
          deceadsed: "",
          diabetes: "",
          disease: "",
          stroke: "",
          mentalIllness: "",
          cancer: "",
          unknown: "",
          other: ""
        }
      ],
      socialHistory: [
        {
          smoker: "",
          smokePerDay: 0,
          everSmoked: "",
          smokeYears: 0,
          quitYear: 0,
          quitIntrest: "",
          drinkAlcohal: "",
          recreationalDrugs: "",
          pastAlcohal: "",
          tabaccoUse: "",
          usingTime: 0,
          partner: "",
          sexInfection: "",
          caffine: "",
          migrantOrSeasonal: "",
          usePerDay: 0,
          occupation: "",
          maritalStatus: "",
          child: "",
          noOfChild: 0,
          childAge: [
            ""
          ],
          sexActive: ""
        }
      ],
      primaryCarePhysician: [
        {
          id: "",
          primaryCarePhysician: "",
          phoneNo: "",
          medicalClinicName: "",
          fax: "",
          clinicAddress: "",
          notifyprimaryCarePhysician: true,
          patientSignature: "",
          psDateTime: "",
          guardianSignature: "",
          gsDateTime: "",
          releaseOfInformation: "",
          informationDateTime: "",
          faxed: ""
        }
      ],
      deviceId: device,
      devices: [
        {
          id: "",
          deviceId: "",
          companyName: "",
          brandName: "",
          gmdnPTName: "",
          snomedCTName: "",
          dateTime: "",
          batch: "",
          serialNumber: "",
          identificationCode: true,
          mriSaftyStatus: "",
          containsNRL: true,
          status: ""
        }
      ],
      password: "",
      username: ""
    };
    const { header1 } = authorize();
    try {
      setLoading(true)
      const response = await axios.post(`${baseURL}/patient/register`, requestBody, { headers: header1 });
      if (response.data.message && response.data.message.code === 'MHC - 0200') {
        const requestBody = {
          bedId: bedId,
          pid: response.data.data.id,
          assignedBy: newAssignedBy,
          admitReason: reason,
          admitDate: admitDate,
          doctorName: selectedStaff,
        };
        toast.success(response.data.message.description)
        if (!save) {
          handleCancel()
        }
        const { header1 } = authorize();
        try {
          const response = await axios.post(
            `${baseURL}/Q15Bed/assign`,
            requestBody, { headers: header1 }
          );
          if (
            response.data.message.code === 'MHC - 0200'
          ) {

            toast.success(response.data.message.description)
            toggle()
          } else {
            console.error("Error:", response.data.message);
          }
        } catch (error) {
          console.error("API Request Error:", error);
        }
        getAllOrgPatient(dispatch, organization, currentPage, '')
      } else {
        toast.error(`Error: ${response.data.message.description}`);
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
    }
  };

  const handleClick = (selectedBed: any) => {
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
    setBedAssignDialog(false);
  };

  const handlePostalCodeChange = async (e: any) => {
    const newZip = e.target.value;
    formValues.postalCode = newZip;
    setZipCode(e.target.value);
    if (newZip.length === 5) {
      setLoading1(true);
      try {
        const response = await axios.get(`${baseURL}/timezone/getTimeZoneByZipCode?zipCode=${newZip}`);
        const locationData = response.data.data?.[0];
        if (locationData) {
          formValues.state = locationData.state || "";
          formValues.country = locationData.country || "";
          formValues.city = locationData.city || "";
          setCityDropDown([locationData.city]);
          setNameError({
            ...nameError,
            state: false,
            country: false,
            city: false
          })
        } else {
          formValues.state = "";
          formValues.country = "";
          formValues.city = "";
          setCityDropDown([]);
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      } finally {
        setLoading1(false);
      }
    } else {
      formValues.state = "";
      formValues.country = "";
      formValues.city = "";
      setCityDropDown([]);
    }
    setFormValues({ ...formValues });
  };


  const handleDateChange = (date: any) => {
    setFormValues({ ...formValues, birthDate: formatDateToYYYYMMDD(date) });
    if (!date) {
      sethighlightdate(true);
    } else {
      sethighlightdate(false);
    }
  };
  const handleAdmitDate = (date: any) => {
    setAdmitDate(formatDateToYYYYMMDD(date));
    setAdmitError({ ...admitError, admitDate: false })
  };

  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstname') {
      formValues.firstName = event.target.value;
    } else if (event.target.id === 'middlename') {
      formValues.middleName = event.target.value;
    } else if (event.target.id === 'lastname') {
      formValues.lastName = event.target.value;
    } else if (event.target.id === 'MrNumber') {
      formValues.mrNumber = event.target.value;
    } else if (event.target.id === 'ssn') {
      formValues.ssn = event.target.value;
    } else if (event.target.id === 'email') {
      formValues.email = event.target.value;
    } else if (event.target.id === 'addressline1') {
      formValues.addressLine1 = event.target.value;
    } else if (event.target.id === 'addressline2') {
      formValues.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formValues.city = event.target.value;
    } else if (event.target.id === 'state') {
      formValues.state = event.target.value;
    } else if (event.target.id === 'country') {
      formValues.country = event.target.value;
    }
    setFormValues({ ...formValues });
  }


  useEffect(() => {
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getPatientsBeacon?organization=${organization}`, { headers: header1 });
        setOptions(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked);
  }
  const handleAdmitD = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdmitD(e.target.checked);
  }

  const hiddenFileInput = useRef<any>(null)
  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  }

  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result !== null ? reader.result.toString() : "";
          setFormValues(prevValues => ({ ...prevValues, profile: result }));
        }
        reader.readAsDataURL(file);
      }
    } else {
      console.error("No file selected");
    }
  }

  const handleChange = (panel: boolean) => {

    if (admitDate || reason || device || bedSelected) {
      // toast.error('Please Fill Admit Required Field')
      setExpanded(true);
    } else {
      setExpanded(panel);
    }
  };
  // const handleBlurChange = (panel: boolean) => {
  //   if (admitDate || reason || device || bedSelected) {
  //     // toast.error('Please Fill Admit Required Field')
  //     setExpanded(true);
  //   } else {
  //     setExpanded(panel)
  //   }
  // }

  // useEffect(() => {
  //   const handleOutsideClick = (event: MouseEvent) => {
  //     // Check if the click is outside both the modal and accordion
  //     if (
  //       accordionRef.current && !accordionRef.current.contains(event.target as Node) &&
  //       modalRef.current && !modalRef.current.contains(event.target as Node)
  //     ) {
  //       setExpanded(false); // Close accordion if the click is outside both the accordion and modal
  //     }
  //   };

  //   document.addEventListener('mousedown', handleOutsideClick);
  //   return () => document.removeEventListener('mousedown', handleOutsideClick);
  // }, []);

  return (
    <>
      {loading && <Loader />}
      <Modal isOpen={modal} centered size='xl' className='mt-5'>
        <div className="d-flex align-items-center justify-content-center vh-90">
          <div className='row'>
            <div className='container col-md-12'>
              <ModalHeader toggle={handleCancel}>
                <div className='d-flex gap-2'>
                  <div>
                    Patient Register
                  </div>
                  <div className=''>
                    <div onClick={handleProfileChange} hidden={formValues.profile !== null && formValues.profile !== "" && formValues.profile !== "string"} style={{ left: "unset", width: "156px" }} className="">
                      <div className="" style={{ fontSize: '12px', color: 'blue', cursor: 'pointer', height: '37px', width: '150px', border: '2px dashed grey', padding: '7px' }}>UPLOAD PROFILE PIC</div>
                    </div>
                    <div hidden={formValues.profile === null || formValues.profile === "" || formValues.profile === "string"} style={{ left: "unset", width: "182px" }} className="">
                      <div style={{ position: 'absolute', top: "5px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} alt='' src={formValues.profile} onClick={handleProfileChange} /><input className="" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                    </div>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                <div className="row w-100 " style={{ marginTop: '10px' }}>
                  <div className='col-md-4 mb-2' >
                    <TextField id="firstname"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      value={formValues.firstName}
                      onChange={(e) => {
                        handleinputchange(e)
                        setNameError({ ...nameError, firstName: false })
                      }}
                      error={!!nameError.firstName}
                      helperText={nameError.firstName ? 'First Name is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4 mb-2'>
                    <TextField id="middlename"
                      label="Middle Name"
                      variant="outlined"
                      fullWidth
                      value={formValues.middleName}
                      onChange={(e) => {
                        handleinputchange(e)
                        setNameError({ ...nameError, middleName: false })

                      }}
                      error={!!nameError.middleName}
                      helperText={nameError.middleName ? 'Middle Name is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="lastname"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      value={formValues.lastName}
                      onChange={(e) => {
                        handleinputchange(e)
                        setNameError({ ...nameError, lastName: false })
                      }}
                      error={!!nameError.lastName}
                      helperText={nameError.lastName ? 'Last Name is required' : ''}
                      required
                    />
                  </div>
                </div>
                <div className="row w-100 mb-4">
                  <div className='col-md-4 mb-1'>
                    <Autocomplete
                      id='gender'
                      options={genderDropDown?.map((item: any) => item.value)}
                      value={formValues.gender}
                      onChange={(e, v) => { formValues.gender = v; setFormValues({ ...formValues }); setNameError({ ...nameError, gender: false }); }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Gender'
                          variant="outlined"
                          error={!disabled && !!nameError.gender}
                          helperText={!disabled && nameError.gender ? nameError.gender ? "Gender is Required" : "" : ""}
                        />
                      )}
                      disabled={disabled}
                    />
                    <div style={{ display: 'flex', marginLeft: '15px', alignItems: 'center', marginTop: '-9px' }}>
                      <FormControlLabel
                        control={<Checkbox checked={disabled} onChange={handleCheck} />}
                        label="Declined to specify"
                      />
                    </div>
                  </div>
                  <div className='col-md-4 mb-2' >
                    <DatePicker
                      sx={{
                        border: highlightdate ? "1px solid red" : "",
                        borderRadius: "5px"
                      }}
                      label={'Date Of Birth'}
                      format='MM-DD-YYYY'
                      onChange={handleDateChange}
                    />
                  </div>
                  {/* <div className='col-md-4 mb-2'>
                    <TextField id="MrNumber"type='Number' label="MRN#" variant="outlined" value={formValues.mrNumber} fullWidth onChange={handleinputchange} />
                  </div> */}
                  
                  <div className='col-md-4 mb-2'>
                    <TextField id="MrNumber" type='text' label="MRN#" variant="outlined" value={formValues.mrNumber} fullWidth onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,7}$/.test(inputValue)) {
                        handleinputchange(e);
                      }
                    }}
                    inputProps={{
                      maxLength: 7, 
                    }}
                  />
                  </div>

                </div>
                <div className='row w-100'>
                  <div className='col-md-6 mb-2'>
                    <TextField
                      id="ssn"
                      label="SSN"
                      variant="outlined"
                      value={formatSSN(formValues.ssn)}
                      fullWidth
                      onChange={(e) => {
                        handleinputchange(e)
                        setNameError({ ...nameError, ssn: false })
                      }}
                      error={!!nameError.ssn}
                      helperText={nameError.ssn ? 'SSN is required' : ''}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      value={formValues.email}
                      fullWidth
                      onChange={(e) => {
                        handleinputchange(e);
                        setNameError({ ...nameError, email: false }); // Reset email error on input change
                      }}
                      onBlur={() => {
                        try {
                          const formattedEmail = formatEmail(formValues.email);
                          setFormValues({ ...formValues, email: formattedEmail });
                          setNameError({ ...nameError, email: false }); // No error on valid email
                        } catch (error) {
                          setNameError({ ...nameError, email: true }); // Set error if invalid email format
                        }
                      }}
                      error={!!nameError.email}
                      helperText={nameError.email ? 'Invalid email format' : ''}
                      required
                      InputProps={{
                        style: {
                          borderColor: nameError.email ? 'red' : '', // Apply red border if there's an error
                        },
                      }}
                    />
                  </div>

                </div>
                <div className="row w-100 ">
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="addressline1"
                      label="Address Line 1"
                      variant="outlined"
                      value={formValues.addressLine1}
                      fullWidth
                      onChange={(e) => { handleinputchange(e); setNameError({ ...nameError, addressLine1: false }) }}
                      error={!!nameError.addressLine1}
                      helperText={nameError.addressLine1 ? 'AddressLine1 is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="addressline2"
                      label="Address Line 2"
                      variant="outlined"
                      value={formValues.addressLine2}
                      fullWidth
                      onChange={(e) => { handleinputchange(e); setNameError({ ...nameError, addressLine2: false }) }}
                      error={!!nameError.addressLine2}
                      helperText={nameError.addressLine2 ? 'AddressLine2 is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      type='number'
                      value={formValues.postalCode}
                      onChange={(e) => {
                        handlePostalCodeChange(e)
                        setNameError({ ...nameError, postalCode: false })
                      }}
                      error={!!nameError.postalCode}
                      helperText={nameError.postalCode ? 'Zip/Postal is required' : ''}
                      required
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {(zipLoading && formValues.postalCode.length === 5) && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-4 mb-2'>
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formValues.city}
                        onChange={(e) => { handleinputchange(e); setNameError({ ...nameError, city: false }) }}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formValues.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formValues.city = v; setFormValues({ ...formValues }); setNameError({ ...nameError, city: false }) }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                            error={!!nameError.city}
                            helperText={nameError.city ? 'City is required' : ''}
                            required
                          />
                        }
                      />}
                  </div>
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formValues.state}
                      onChange={(e) => { handleinputchange(e); setNameError({ ...nameError, state: false }) }}
                      error={!!nameError.state}
                      helperText={nameError.state ? 'State is required' : ''}
                      required
                    />
                  </div>
                  <div className='col-md-4 mb-2'>
                    <TextField
                      id="country"
                      label="Country"
                      variant="outlined"
                      fullWidth
                      value={formValues.country}
                      onChange={(e) => { handleinputchange(e); setNameError({ ...nameError, country: false }) }}
                      error={!!nameError.country}
                      helperText={nameError.country ? 'Country is required' : ''}
                      required
                    />
                  </div>
                </div>
                <Accordion expanded={expanded ? true : false} onChange={() => handleChange(!expanded)}
                  onBlur={() => {
                    if (!document.getElementById('Reason')?.focus || !document.getElementsByClassName('datePicker')) {
                      handleChange(!expanded)
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Admit
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <>
                        <div className="d-flex row w-100">
                          <div className='col-md-6 pt-4 mt-2' >
                            <DatePicker
                              sx={{
                                border: admitError.admitDate ? "1px solid red" : "none",
                                borderRadius: admitError.admitDate ? "5px" : "none",
                                width: '100%'
                              }}
                              className='datePicker'
                              label={'Admit Date'}
                              format='MM-DD-YYYY'
                              onChange={handleAdmitDate}
                            />
                          </div>


                          <div className='col-md-6 mt-3 '>
                            {/* <span>Device Id:</span> */}
                            <List>
                              <ListItem disableGutters>
                                <FormControl fullWidth>

                                  <TextField
                                    label="Device Id"
                                    value={device}
                                    onChange={(event) => { setDevice(event.target.value); setAdmitError({ ...admitError, device: false }) }}
                                    error={!!admitError.device}
                                    select
                                    required
                                    InputProps={{
                                      endAdornment: (
                                        <FaQrcode
                                          onClick={handleQrClick}
                                          style={{
                                            fontSize: '1.2rem',
                                            color: '#000',
                                            cursor: 'pointer',
                                          }}
                                          role="button"
                                        />
                                      ),
                                    }}
                                    SelectProps={{
                                      IconComponent: () => null,

                                      MenuProps: {
                                        PaperProps: {
                                          style: { maxHeight: '300px' },
                                        },
                                      },
                                    }}
                                  >
                                    {options?.length > 0 ? (
                                      options?.map((option: any) => (
                                        <MenuItem key={option?.id} value={option?.deviceId}>
                                          {option?.deviceId}
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{
                                              fontSize: "12px",
                                              color: !option?.status ? '#5FB477' : '#EF6868',
                                              display: "inline-block",
                                              textAlign: "center",
                                              marginLeft: '10px',
                                              marginBottom: '1px'
                                            }}
                                          />
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem>No Devices</MenuItem>
                                    )}
                                  </TextField>
                                </FormControl>
                              </ListItem>
                            </List>
                          </div>
                        </div>

                        <div className="row w-100 ">

                          <div className="col-md-6">
                            <TextField
                              style={{ marginBottom: '17px' }}
                              label="Select Physician"
                              value={selectedStaff}
                              onChange={(e) => { setSelectedStaff(e.target.value) }}
                              fullWidth
                              select
                            >
                              {roleList?.length > 0 ? (
                                roleList?.map((staff: any) => (
                                  <MenuItem
                                    key={staff.id}
                                    value={`${staff?.name[0]?.given || ''} ${staff?.name[0]?.family || ''}`}
                                  >
                                    {`${staff?.name[0]?.given || ''} ${staff?.name[0]?.family || ''}`}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="">No Staff Available</MenuItem>
                              )}
                            </TextField>
                          </div>
                          <div className="col-md-6 mt-2" style={{ textAlign: 'end', display: 'flex', alignItems: 'center' }}>
                            <p style={{ paddingRight: '18px', marginTop: '10px', marginLeft: '10px', color: admitError.bedSelected ? "red" : "" }}>{bedSelected ? bedSelected : 'Select Room & Bed'}</p>
                            <IconButton style={{ color: admitError.bedSelected ? 'red' : "#0f3995", marginTop: '-10px', fontSize: '30px' }} onClick={() => {
                              if (admitDbed === false) {
                                setAdmitDbed(true);
                                setAdmitD(true)
                              } else {
                                setAdmitDbed(false);
                                setAdmitD(false)
                              }
                            }} edge="end">
                              <Bed style={{ fontSize: '30px' }} />
                            </IconButton>

                          </div>
                          <div className='col-md-6 mb-'>
                            <TextField
                              id="Reason"
                              label="Admit Reason"
                              variant="outlined"
                              value={reason}
                              fullWidth
                              onChange={(e) => { setReason(e.target.value); setAdmitError({ ...admitError, reason: false }) }}
                              error={!!admitError.reason}
                              helperText={admitError.reason ? "Reason Field is Required" : ""}
                              required
                            />
                          </div>

                        </div>
                        <div>
                          {admitDbed &&
                            <PatientBed
                              admitD={admitD}
                              bedId={bedId}
                              handleBedClick={handleBedClick}
                              bedsByNumber={bedsByNumber}
                            />
                          }
                        </div>
                      </>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </ModalBody>
            </div>
            <ModalFooter className="">
              <div className="d-flex gap-3 justify-content-center">
                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleCancel}></Button>
                {/* <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSaveClick(true)}></Button> */}
                <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSaveClick(false)}></Button>
              </div>
            </ModalFooter>
          </div>
        </div>
      </Modal >
      <Dialog maxWidth={'xl'} PaperProps={{ sx: { width: '90%', maxWidth: '90%', position: 'absolute', height: '95vh', top: '1px' } }}
        open={bedAssignDialog}
        onClose={() => setBedAssignDialog(false)}
      >
        <DialogTitle>Bed Assign</DialogTitle>
        <DialogContentText >
          <DialogContent style={{ padding: '20px', background: '#F8FAFB' }}>
            <div>
              <Row style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-evenly' }}>
                {Array.isArray(patientAndBedAssign) && patientAndBedAssign?.length > 0 ? (
                  patientAndBedAssign?.map((bedassign: any, index: number) => (
                    bedassign.pid !== null ? <>
                      <Col key={bedassign.id} style={{ flex: 0, padding: 0 }}>
                        <div className="bed-assignment-box">
                          <Card
                            className="mb-3"
                            color="danger"
                            outline
                            style={{
                              width: "92px",
                              height: "70px",
                              margin: "5px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <CardBody
                              key={index}
                              className="d-flex gap-1"
                              style={{ cursor: 'pointer', padding: '0.6rem' }}
                            >
                              <CardTitle tag="h6">
                                <FontAwesomeIcon icon={faDoorOpen} style={{ width: '15px', height: '20px' }} />
                                <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                              </CardTitle>
                              <CardSubtitle tag="h6" className="mb-2 text-muted">
                                <FontAwesomeIcon icon={faBed} style={{ width: '12px', height: '20px' }} />
                                <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                              </CardSubtitle>
                            </CardBody>
                            <CardFooter style={{ padding: '0.2rem', position: 'relative', display: 'flex', top: '-13px', height: '25px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal' }}>
                              <img src={patientImage} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px' }}>{getPatientName(bedassign.pid)}</span>
                            </CardFooter>
                          </Card>
                        </div>
                      </Col>
                    </> : <>
                      <Col key={index} style={{ flex: 0, padding: 0 }}>
                        <div className="bed-assignment-box">
                          <Card
                            className="mb-3"
                            color="primary"
                            outline
                            style={{
                              width: "92px",
                              height: "70px",
                              margin: "5px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <CardBody
                              key={index}
                              className="d-flex gap-1"
                              onClick={() => handleClick(bedassign)}
                              style={{ cursor: "pointer", padding: '0.6rem' }}
                            >
                              <CardTitle tag="h6">
                                <FontAwesomeIcon icon={faDoorOpen} style={{ width: '15px', height: '20px' }} />
                                <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                              </CardTitle>
                              <CardSubtitle tag="h6" className="mb-2 text-muted">
                                <FontAwesomeIcon icon={faBed} style={{ width: '12px', height: '20px' }} />
                                <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                              </CardSubtitle>
                            </CardBody>
                            <CardFooter style={{ padding: '0.6rem', position: 'relative', top: '-13px', height: '25px', paddingTop: '5px', paddingLeft: '13px' }}>
                              <Badge
                                style={{ fontSize: '10px' }}
                                color={bedassign.pid ? "danger" : "success"}
                                tag="h4"
                              >
                                {bedassign.pid ? "Not Available" : "Available"}
                              </Badge>
                            </CardFooter>
                          </Card>
                        </div>
                      </Col></>
                  ))
                ) : (
                  <p>No bed assignments available.</p>
                )}
              </Row></div>
          </DialogContent>
        </DialogContentText>
      </Dialog>
      <Modal isOpen={show} toggle={handleClose1}>
        <ModalHeader toggle={handleClose1}>Scanning</ModalHeader>
        <ModalBody>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
            <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PatientCreation;
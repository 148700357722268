import { useReducer ,useEffect} from "react";
import axios from "axios";
import { baseURL } from "../configuration/url";

const ACTIONS = {
  API_REQUEST: "api_request",
  FETCH_DATA: "fetch-data",
  ERROR: "zipError",
};


const initialState = {
  data: [],
  zipLoading: true,
  zipError: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.API_REQUEST:
      return { ...state, data: [], zipLoading: true };
    case ACTIONS.FETCH_DATA:
      return { ...state, data: payload, zipLoading: false };
    case ACTIONS.ERROR:
      return { ...state, data: [], zipLoading: false, zipError: payload };
    default:
      return state;
  }
};

const useZipcode = (zipCode) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (zipCode.length === 5) {
      dispatch({ type: ACTIONS.API_REQUEST });
      axios
        .get(`${baseURL}/timezone/getTimeZoneByZipCode?zipCode=${zipCode}`)
        .then((res) => {
          dispatch({ type: ACTIONS.FETCH_DATA, payload: res.data });
        })
        .catch((e) => {
          dispatch({ type: ACTIONS.ERROR, payload: e.message });
        });
    }
  }, [zipCode]); 

  return state; // Return the current state
};

export default useZipcode;

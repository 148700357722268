import React, { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Avatar,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button } from "reactstrap";

import { MdAddCircleOutline } from "react-icons/md";
import { DialogTitle } from "@material-ui/core";
import { primarybg } from "../../common/primary";
import { DatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaSearch } from "react-icons/fa";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Paginator } from "primereact/paginator";
import { useSelector } from "react-redux";
import { FaInbox } from "react-icons/fa6";

const TeamListTable = ({ allTeamData }:any) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { loading, totalElements } = useSelector((state: any) => state.Patient);

  const columns: GridColDef[] = [
    { field: "id", headerName: "S.No" },
    {
      field: "Staff Name",
      headerName: "Staff Name",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Staff ID",
      headerName: "Staff ID",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Date",
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
    },
  ];
  let rows = [
    {
      id: "1",
      StaffName: "sathish",
      StaffID: "1",
      Date: "Jan 03 ,2024",
    },
    {
      id: "2",
      StaffName: "Guru",
      StaffID: "2",
      Date: "Jan 03 ,2024",
    },
    {
      id: "3",
      StaffName: "Ajith",
      StaffID: "3",
      Date: "Jan 03 ,2024",
    },
    {
      id: "4",
      StaffName: "Mani",
      StaffID: "4",
      Date: "Jan 03 ,2024",
    },
  ];

  return (
    <div>
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              // onChange={(e) => handleSearch(e.target.value)}
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2"></div>
        <div className="row">
          <div className="col-md-3"></div>
        </div>
        {allTeamData?.length === 0 || allTeamData === undefined ? (<>
              <Typography variant="body1" className="table_empty">
                <FaInbox className="empty_svg" />
                No Data found for this team
              </Typography>
            </>):(
        <table
          className="table table-bordered"
          style={{ fontSize: "13px", marginTop: "10px" }}
        >
          <thead>
            <tr>
              <th scope="col" className="text-center table-data">
                Staff Name
              </th>
              <th scope="col" className="text-center table-data">
                Staff ID
              </th>
              <th scope="col" className="text-center table-data">
               Role
              </th>
              <th scope="col" className="text-center table-data">
              Job Title
              </th>
              <th scope="col" className="text-center table-data">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
           <>
              {allTeamData[0]?.staff?.map((staff: any, index: number) => (
              <tr key={index}>
                <td className="text">{staff?.staffame}</td>
                <td className="text">{staff?.staffId}</td>
                <td className="text">{staff?.staffRole}</td>
                <td className="text">{staff?.jobTitle}</td>
                <td className="text d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
            </>
          </tbody>
        </table>)}
      </div>
      {/* <div className="pagination-container" >
        <div className="d-flex justify-content-center">
          <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {
              setCurrentPage(e.page)
              getAllPatient(dispatch, organization, e.page, searchTerm ? searchTerm : '')
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
          />
        </div>
      </div> */}
    </div>
  );
};

export default TeamListTable;
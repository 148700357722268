import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import DateBox from "../../components/calendar/dateBox";
import { Button } from "reactstrap";
import { Box, Tabs, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import AddShiftStaff from "./AddShiftStaff";
import moment from "moment";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { authorize } from "../../helpers/common";
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0 24px 24px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Q15Shift = () => {
  const { header1 } = authorize();
  const [allTeamData, setAllTeamData] = useState<any>([]);
  const org = useSelector((state: any) => state.Login.organization);
  const { orgData } = useSelector((state: any) => state.Org);

  const [Loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [date, setDate] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [noOfShift, setNoOfShift] = useState<any>(0);
  console.log(noOfShift, "noOfShift");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue, "newValue");
    setValue(newValue);
  };
  const handleDateChange = (e: any) => {
    const newDate = e.target.value;
    setSelectedDate(e.target.value);

    if (!newDate) {
      if (!newDate) {
        return;
      }

      try {
        getAllTeam();
      } catch (error) {
        console.error("API Error : ", error);
        console.error("API Error : ", error);
      }
    }
  };
  const getNumberOfShifts = (duration: number): number => {
    if (duration <= 0) {
      throw new Error("Duration must be a positive number.");
    }

    return 24 / duration; // Return the number of shifts
  };
  useEffect(() => {
    const handlestartTime = () => {
      const crtime: any = localStorage.getItem("LTime");
      if (crtime) {
        const formattedDate = moment(crtime).format("YYYY-MM-DD");
        setSelectedDate(formattedDate);
      }
    };
    handlestartTime();

    let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
    console.log(numShift, "numShift");
    setNoOfShift(numShift);
  }, []);
  const renderTabContent = () => {
    switch (value) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      default:
        return "No Content Available";
    }
  };
  const getAllTeam = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseURL}/shift/ByOrganizationAndDate?date=${moment(
          selectedDate
        ).format("YYYYMMDD")}&organization=${org}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        if (response.data.data.content?.length > 0) {
          const filteredShifts = response?.data.data?.content?.filter(
            (shift: any) => shift?.shiftName === renderTabContent()
          );
          setLoading(false);
          setAllTeamData(filteredShifts);
        } else {
          setAllTeamData([]);
          setLoading(false);
        }

        setLoading(false);
      } else {
        setLoading(false);
        setAllTeamData([]);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getAllTeam();
  }, [selectedDate, value]);
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };
  
  return (
    <div>
      {Loading && <Loader />}

      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            width: "90%",
            position: "relative",
            left: "66px",
          }}
        >
          <DateBox
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleDateChange={handleDateChange}
            countCheck={null}
            checkShift={null}
          />
        </div>
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            height: "auto",
            marginTop: "20px",
          }}
        >
        
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
          
              
                  {Array.from({ length: noOfShift }).map((_, index) => (
                    <Tab
                      key={index}
                      label={`Shift-${String.fromCharCode(65 + index)}`} // Shift-A, Shift-B, etc.
                      value={index}
                      {...a11yProps(index)}
                    />
                  ))}
               
              {/* )} */}

            {/* Render General Tab */}
            <Tab label="General" value={6} {...a11yProps(6)} />
          </Tabs>
          {Array.from({ length: noOfShift }).map((_, index) => (
            <TabPanel value={value} index={index}>
              <AddShiftStaff
                getAllTeam={getAllTeam}
                selectedDate={selectedDate}
                selectedTab={value}
                allTeamData={allTeamData}
                noOfShift={noOfShift}
              />
            </TabPanel>
          ))}
          <TabPanel value={value} index={6}>
            <AddShiftStaff
              getAllTeam={getAllTeam}
              selectedDate={selectedDate}
              selectedTab={value}
              allTeamData={allTeamData}
              noOfShift={noOfShift}

            />
          </TabPanel>
          {/*      
      <TabPanel value={value} index={1}>
      <AddShiftStaff getAllTeam={getAllTeam} selectedDate={selectedDate} selectedTab={value} allTeamData={allTeamData}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <AddShiftStaff getAllTeam={getAllTeam} selectedDate={selectedDate} selectedTab={value} allTeamData={allTeamData}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <AddShiftStaff getAllTeam={getAllTeam} selectedDate={selectedDate} selectedTab={value} allTeamData={allTeamData}/>
      </TabPanel>
      */}
        </Box>
        {/* <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList  orientation='vertical'
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >
                <Tab label="Shift-A" value="Shift-A" />
                <Tab label="Shift-B" value="Shift-B" />
                <Tab label="Shift-C" value="Shift-C" />
                <Tab label="General" value="General" />
              </TabList>
            <TabPanel value="Shift-A">
             <AddShiftStaff selectedDate={selectedDate} selectedTab={value}/>
            </TabPanel>
            <TabPanel value="Shift-B">
            <AddShiftStaff selectedDate={selectedDate} selectedTab={value}/>
             
            </TabPanel>
            <TabPanel value="Shift-C">
            <AddShiftStaff selectedDate={selectedDate} selectedTab={value}/>
             
            </TabPanel>
            <TabPanel value="General">
             <AddShiftStaff selectedDate={selectedDate} selectedTab={value}/>
            </TabPanel>
            </Box>

          </TabContext>
        </Box> */}
      </div>
    </div>
  );
};

export default Q15Shift;

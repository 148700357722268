import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Typography } from "@mui/material";
import { Button } from "reactstrap";
import { FaInbox, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import "./shiftAllocation.css";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { authorize } from "../../helpers/common";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";

const ShiftListTable = ({
  allTeamData,
  noOfShift,
  value,
  staffDropdown,
  getAllTeam,
  getAllStaffByTeam,
  selectedDate,
}: any) => {
  const [searchQuery, setSearchQuery] = useState("");
    const { shiftStartTime } = useSelector((state: any) => state.Org);
  
  const [filteredData, setFilteredData] = useState<any>([]);
  const { header1 } = authorize();
  const [Loading, setLoading] = useState<boolean>(false);
  const [Data, setData] = useState<any>([]);
  const { organization } = useSelector((state: any) => state.Login);
  const [shifts, setShifts] = useState<any>([]);
  const startTime = new Date(`2000-01-01T${shiftStartTime}`);
  const { orgData } = useSelector((state: any) => state.Org);
  const generateShifts = (startTimeStr:any, duration:any, totalShifts:any) => {
    const shifts = [];
    
    // Convert the start time string to a Date object
    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);
    
    for (let i = 0; i < totalShifts; i++) {
      // Calculate the end time by adding the duration to the start time
      let endTime = new Date(startTime.getTime() + Number(duration) * 60 * 60 * 1000); // duration in hours
      
      // Format start and end times into 'HH:MM' format
      const startTimeFormatted = startTime.toTimeString().slice(0, 5);
      const endTimeFormatted = endTime.toTimeString().slice(0, 5);
      
      // Assign shift name (A, B, C, etc.)
      const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'
      
      // Push shift details into the array
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
      });
      
      // Update the start time for the next shift
      startTime = endTime;
    }
    
    // Set the shifts state
    setShifts(shifts);
  };

  const renderTabContent = () => {
    switch (value) {
      case "Shift-A":
        return 0;
      case "Shift-B":
        return 1;
      case "Shift-C":
        return 2;
      case "Shift-D":
        return 3;
      case "Shift-E":
        return 4;
      case "Shift-F":
        return 5;
      case "General":
        return 6;
      default:
        return "No Content Available";
    }
  };
  useEffect(() => {
    setLoading(true);
  generateShifts(shiftStartTime,orgData?.shift?.duration,noOfShift);

    // for (let i = 0; i < staffDropdown.length; i++) {
    //   (staffDropdown[i] as { checked?: boolean }).checked = false; // Type assertion
    // }
    if (allTeamData?.length === 0 || allTeamData === undefined) {
      setData(staffDropdown);
      setLoading(false);
    } else {

      // let filteredSecondArray = allTeamData[0].staff.filter(
      //   (item: any) => item?.checked
      // ); // Filter only checked true objects

      // let newArray = filteredSecondArray?.map((staff: any) => ({
      //   id: staff?.staffId,
      //   name: staff?.staffName,
      //   role: staff?.staffRole,
      //   checked: staff.checked,
      // }));
      // let mergedArray = [...staffDropdown, ...newArray]; // Merge the arrays

      // const uniqueArray = Array.from(
      //   new Map(mergedArray.map((item) => [item.id, item])).values()
      // );
      // const sortedData = [...allTeamData].sort(
      //   (a, b) => b?.checkIn - a?.checkIn
      // );
      setData(allTeamData);
      setLoading(false);
    }
  }, [ allTeamData,value, staffDropdown]);




  const handleCheckboxChange = (id: string) => {
    setData((prevData: any[]) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, checkIn: !item?.checkIn } : item
      )
    );

    setFilteredData((prevData: any[]) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, checkIn: !item?.checkIn } : item
      )
    );
  };

  const handleSaveStaff = async () => {
    let filteredData = Data?.filter((data: any) => data.checkIn === true);
    const staffIds = Data?.map((staff: any) => ({
      staffId: staff.id,
      staffName: staff.name,
      checkIn: staff.checkIn,
    }));
    let formData = {
      organization: organization,
      shiftName: value,
      staff: staffIds,
      startDate: moment(selectedDate)?.format("YYYYMMDD"),
    };
    try {
      setLoading(true);

      const response = await axios.post(`${baseURL}/shift/register`, formData, {
        headers: header1,
      });
      if (response.data.message && response.data.message.code === successCode) {
        toast.success(response.data.message.description);
        setLoading(false);
        getAllStaffByTeam();
        getAllTeam();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = (e: any) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filtered = Data?.filter((item: any) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(Data);
    }
  };
  return (
    <div>
      {Loading && <Loader />}

      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-3 d-flex align-items-center">
          <h5 style={{ marginLeft: "28px" }}>Shift Allocation</h5>
        </div>
        <div  className="col-md-5 d-flex align-items-center" style={{ fontSize: "14px" }}>
            <span>
              Start Time :{" "}
              {renderTabContent() === 6  ?shifts[0]?.start_time:shifts[renderTabContent()]?.start_time}
            </span>

            <span style={{ marginLeft: "5px" }}>
              End Time :{" "}
              {renderTabContent() === 6  ?shifts[0]?.end_time:shifts[renderTabContent()]?.end_time}

            </span>
          </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              className="search form-control"
              onChange={(e) => handleSearch(e)}
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
        </div>

        <div>
          <>
            <div className="row" style={{ overflow: "auto", height: "220px" }}>
              <div>
                <div
                  className="row"
                  style={{ width: "100%", flexWrap: "wrap" }}
                >
                  {filteredData?.length > 0
                    ? filteredData.map((item: any) => (
                        <div key={item.id} className="col-md-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              padding: "5px",
                            }}
                          >
                            <Checkbox
                              checked={item?.checkIn}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                            <div>{item.name}</div>
                          </div>
                        </div>
                      ))
                    :Data.length > 0? Data?.map((item: any, index: number) => (
                        <div key={item.id} className="col-md-3">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              padding: "5px",
                            }}
                          >
                            <Checkbox
                              checked={item?.checkIn}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                            <div>{item.name}</div>
                          </div>
                        </div>
                      )): <>
                      <Typography variant="body1" className="table_empty">
                        <FaInbox className="empty_svg" />
                        No Staff found for this Shift
                      </Typography>
                    </>}
                </div>
              </div>
            </div>
            <Divider />
            <Button
              style={{
                float: "right",
                marginTop: "10px",
                backgroundColor: "#0f3995",
              }}
              onClick={() => handleSaveStaff()}
            >
              Save Changes
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default ShiftListTable;

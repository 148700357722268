import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap';
import { baseURL } from "../../configuration/url";
import { useSelector } from 'react-redux';
import siren from "../../assets/images/siren-on1.png";
import Loader from "../../components/loader/Loader";
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

interface AlarmData {
    id: number;
    sirenName: string;
    siren: string;
    online: boolean;
}
const DeviceStatus: React.FC = () => {
    const [alarmData, setAlarmData] = useState<AlarmData[]>([]);
    const [totalDevices, setTotalDevices] = useState<number>(0);
    const { organization } = useSelector((state: any) => state.Login);
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const getDevices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${baseURL}/siren/getAllDevices?organization=${organization}`
                );

                if (response?.data?.devices) {
                    let deviceCounter = 0;
                    const allDevices = response.data.devices.flatMap((deviceGroup: any) =>
                        deviceGroup.data.result.devices.map((device: any) => {
                            deviceCounter += 1;
                            return {
                                id: deviceCounter,
                                sirenName: device.name || 'N/A',
                                siren: device.online ? 'Online' : 'Offline',
                                online: device.online,
                            };
                        })
                    );

                    setAlarmData(allDevices);
                    const total = response.data.devices.reduce(
                        (sum: number, deviceGroup: any) => sum + deviceGroup.data.result.total,
                        0
                    );
                    setTotalDevices(total);
                    setLoading(false);

                } else {
                    setAlarmData([]);
                    setTotalDevices(0);
                    setLoading(false);

                }
            } catch (error) {
                console.error('Error fetching devices:', error);
                setAlarmData([]);
                setTotalDevices(0);
                setLoading(false);
            }
        };

        getDevices();
    }, [organization]);
    return (
        <div>
            {loading && <Loader />}
            <h2 style={{ textAlign: 'start', fontWeight: 'bold', color: 'black', fontSize: '18px' }}>Device Status</h2>
            <Table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead>
                    <tr>
                        <th className="text-center table-data" style={{ width: '5%' }}>S.No</th>
                        <th className="text-center table-data">Name</th>
                        <th className="text-center table-data">Device Status</th>
                        {/* <th className="text-center table-data">Status</th> */}
                    </tr>
                </thead>
                <tbody>
                    {alarmData.length > 0 ? (
                        alarmData.map((record) => (
                            <tr key={record.id}>
                                <td className="text-center">{record.id}</td>
                                <td className="text-center">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        {record?.sirenName && (
                                            <img
                                                src={siren}
                                                className="logo-img"
                                                style={{ width: "15px", height: "15px", marginTop: '5px', marginRight: '5px' }}
                                                alt="Siren Icon"
                                            />
                                        )}
                                        <p style={{ color: "#000", margin: 0, fontSize: '12px', paddingTop: '3px', fontWeight: 'bold' }}>
                                            {record.sirenName}
                                        </p>
                                    </div>

                                </td>
                                <td className="text-center">
                                    <div className='d-flex flex-row justify-content-center'>
                                        {/* <p
                                            style={{
                                                color: record.siren === 'Online' ? '#05B31C' : 'red',
                                                margin: 0,
                                                fontSize: '12px',
                                                paddingTop: '3px',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            {record.siren}
                                        </p> */}
<Tooltip title={record?.online ? "Online" : "Offline"} arrow>
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                style={{
                                                    fontSize: "13px",
                                                    cursor: "pointer",
                                                    color: record?.online ? '#05B31C' : 'red',
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                }}
                                            />
                                        </Tooltip>
                                    </div>

                                </td>
                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {record.online !== undefined && (
                                        <Tooltip title={record?.online ? "Occupied" : "Not Available"} arrow>
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                style={{
                                                    fontSize: "13px",
                                                    cursor: "pointer",
                                                    color: record?.online ? '#05B31C' : 'red',
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </div> */}


                            </tr>

                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className="text-center">
                                No records found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};
export default DeviceStatus;

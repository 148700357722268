export const TimeSlotData = {
  slotFirst: [
    {
      q15Slot: "A00",
      timeFormat: "00:00-00:14",
      displayTime: "00.00",
    },
    {
      q15Slot: "B00",
      timeFormat: "00:15-00:29",
      displayTime: "00.15",
    },
    {
      q15Slot: "C00",
      timeFormat: "00:30-00:44",
      displayTime: "00.30",
    },
    {
      q15Slot: "D00",
      timeFormat: "00:45-00:59",
      displayTime: "00.45",
    },
    {
      q15Slot: "A01",
      timeFormat: "01:00-01:14",
      displayTime: "01.00",
    },
    {
      q15Slot: "B01",
      timeFormat: "01:15-01:29",
      displayTime: "01.15",
    },
    {
      q15Slot: "C01",
      timeFormat: "01:30-01:44",
      displayTime: "01.30",
    },
    {
      q15Slot: "D01",
      timeFormat: "01:45-01:59",
      displayTime: "01.45",
    },
    {
      q15Slot: "A02",
      timeFormat: "02:00-02:14",
      displayTime: "02.00",
    },
    {
      q15Slot: "B02",
      timeFormat: "02:15-02:29",
      displayTime: "02.15",
    },
    {
      q15Slot: "C02",
      timeFormat: "02:30-02:44",
      displayTime: "02.30",
    },
    {
      q15Slot: "D02",
      timeFormat: "02:45-02:59",
      displayTime: "02.45",
    },
    {
      q15Slot: "A03",
      timeFormat: "03:00-03:14",
      displayTime: "03.00",
    },
    {
      q15Slot: "B03",
      timeFormat: "03:15-03:29",
      displayTime: "03.15",
    },
    {
      q15Slot: "C03",
      timeFormat: "03:30-03:44",
      displayTime: "03.30",
    },
    {
      q15Slot: "D03",
      timeFormat: "03:45-03:59",
      displayTime: "03.45",
    },
    {
      q15Slot: "A04",
      timeFormat: "04:00-04:14",
      displayTime: "04.00",
    },
    {
      q15Slot: "B04",
      timeFormat: "04:15-04:29",
      displayTime: "04.15",
    },
    {
      q15Slot: "C04",
      timeFormat: "04:30-04:44",
      displayTime: "04.30",
    },
    {
      q15Slot: "D04",
      timeFormat: "04:45-04:59",
      displayTime: "04.45",
    },
    {
      q15Slot: "A05",
      timeFormat: "05:00-05:14",
      displayTime: "05.00",
    },
    {
      q15Slot: "B05",
      timeFormat: "05:15-05:29",
      displayTime: "05.15",
    },
    {
      q15Slot: "C05",
      timeFormat: "05:30-05:44",
      displayTime: "05.30",
    },
    {
      q15Slot: "D05",
      timeFormat: "05:45-05:59",
      displayTime: "05.45",
    },
  ],
  slotTwo: [
    {
      q15Slot: "A06",
      timeFormat: "06:00-06:14",
      displayTime: "06.00",
    },
    {
      q15Slot: "B06",
      timeFormat: "06:15-06:29",
      displayTime: "06.15",
    },
    {
      q15Slot: "C06",
      timeFormat: "06:30-06:44",
      displayTime: "06.30",
    },
    {
      q15Slot: "D06",
      timeFormat: "06:45-06:59",
      displayTime: "06.45",
    },
    {
      q15Slot: "A07",
      timeFormat: "07:00-07:14",
      displayTime: "07.00",
    },
    {
      q15Slot: "B07",
      timeFormat: "07:15-07:29",
      displayTime: "07.15",
    },
    {
      q15Slot: "C07",
      timeFormat: "07:30-07:44",
      displayTime: "07.30",
    },
    {
      q15Slot: "D07",
      timeFormat: "07:45-07:59",
      displayTime: "07.45",
    },
    {
      q15Slot: "A08",
      timeFormat: "08:00-08:14",
      displayTime: "08.00",
    },
    {
      q15Slot: "B08",
      timeFormat: "08:15-08:29",
      displayTime: "08.15",
    },
    {
      q15Slot: "C08",
      timeFormat: "08:30-08:44",
      displayTime: "08.30",
    },
    {
      q15Slot: "D08",
      timeFormat: "08:45-08:59",
      displayTime: "08.45",
    },
    {
      q15Slot: "A09",
      timeFormat: "09:00-09:14",
      displayTime: "09.00",
    },
    {
      q15Slot: "B09",
      timeFormat: "09:15-09:29",
      displayTime: "09.15",
    },
    {
      q15Slot: "C09",
      timeFormat: "09:30-09:44",
      displayTime: "09.30",
    },
    {
      q15Slot: "D09",
      timeFormat: "09:45-09:59",
      displayTime: "09.45",
    },
    {
      q15Slot: "A10",
      timeFormat: "10:00-10:14",
      displayTime: "10.00",
    },
    {
      q15Slot: "B10",
      timeFormat: "10:15-10:29",
      displayTime: "10.15",
    },
    {
      q15Slot: "C10",
      timeFormat: "10:30-10:44",
      displayTime: "10.30",
    },
    {
      q15Slot: "D10",
      timeFormat: "10:45-10:59",
      displayTime: "10.45",
    },
    {
      q15Slot: "A11",
      timeFormat: "11:00-11:14",
      displayTime: "11.00",
    },
    {
      q15Slot: "B11",
      timeFormat: "11:15-11:29",
      displayTime: "11.15",
    },
    {
      q15Slot: "C11",
      timeFormat: "11:30-11:44",
      displayTime: "11.30",
    },
    {
      q15Slot: "D11",
      timeFormat: "11:45-11:59",
      displayTime: "11.45",
    },
  ],
  slotThree: [
    {
      q15Slot: "A12",
      timeFormat: "12:00-12:14",
      displayTime: "12.00",
    },
    {
      q15Slot: "B12",
      timeFormat: "12:15-12:29",
      displayTime: "12.15",
    },
    {
      q15Slot: "C12",
      timeFormat: "12:30-12:44",
      displayTime: "12.30",
    },
    {
      q15Slot: "D12",
      timeFormat: "12:45-12:59",
      displayTime: "12.45",
    },
    {
      q15Slot: "A13",
      timeFormat: "13:00-13:14",
      displayTime: "13.00",
    },
    {
      q15Slot: "B13",
      timeFormat: "13:15-13:29",
      displayTime: "13.15",
    },
    {
      q15Slot: "C13",
      timeFormat: "13:30-13:44",
      displayTime: "13.30",
    },
    {
      q15Slot: "D13",
      timeFormat: "13:45-13:59",
      displayTime: "13.45",
    },
    {
      q15Slot: "A14",
      timeFormat: "14:00-14:14",
      displayTime: "14.00",
    },
    {
      q15Slot: "B14",
      timeFormat: "14:15-14:29",
      displayTime: "14.15",
    },
    {
      q15Slot: "C14",
      timeFormat: "14:30-14:44",
      displayTime: "14.30",
    },
    {
      q15Slot: "D14",
      timeFormat: "14:45-14:59",
      displayTime: "14.45",
    },
    {
      q15Slot: "A15",
      timeFormat: "15:00-15:14",
      displayTime: "15.00",
    },
    {
      q15Slot: "B15",
      timeFormat: "15:15-15:29",
      displayTime: "15.15",
    },
    {
      q15Slot: "C15",
      timeFormat: "15:30-15:44",
      displayTime: "15.30",
    },
    {
      q15Slot: "D15",
      timeFormat: "15:45-15:59",
      displayTime: "15.45",
    },
    {
      q15Slot: "A16",
      timeFormat: "16:00-16:14",
      displayTime: "16.00",
    },
    {
      q15Slot: "B16",
      timeFormat: "16:15-16:29",
      displayTime: "16.15",
    },
    {
      q15Slot: "C16",
      timeFormat: "16:30-16:44",
      displayTime: "16.30",
    },
    {
      q15Slot: "D16",
      timeFormat: "16:45-16:59",
      displayTime: "16.45",
    },
    {
      q15Slot: "A17",
      timeFormat: "17:00-17:14",
      displayTime: "17.00",
    },
    {
      q15Slot: "B17",
      timeFormat: "17:15-17:29",
      displayTime: "17.15",
    },
    {
      q15Slot: "C17",
      timeFormat: "17:30-17:44",
      displayTime: "17.30",
    },
    {
      q15Slot: "D17",
      timeFormat: "17:45-17:59",
      displayTime: "17.45",
    },
  ],
  slotFour: [
    {
      q15Slot: "A18",
      timeFormat: "18:00-18:14",
      displayTime: "18.00",
    },
    {
      q15Slot: "B18",
      timeFormat: "18:15-18:29",
      displayTime: "18.15",
    },
    {
      q15Slot: "C18",
      timeFormat: "18:30-18:44",
      displayTime: "18.30",
    },
    {
      q15Slot: "D18",
      timeFormat: "18:45-18:59",
      displayTime: "18.45",
    },
    {
      q15Slot: "A19",
      timeFormat: "19:00-19:14",
      displayTime: "19.00",
    },
    {
      q15Slot: "B19",
      timeFormat: "19:15-19:29",
      displayTime: "19.15",
    },
    {
      q15Slot: "C19",
      timeFormat: "19:30-19:44",
      displayTime: "19.30",
    },
    {
      q15Slot: "D19",
      timeFormat: "19:45-19:59",
      displayTime: "19.45",
    },
    {
      q15Slot: "A20",
      timeFormat: "20:00-20:14",
      displayTime: "20.00",
    },
    {
      q15Slot: "B20",
      timeFormat: "20:15-20:29",
      displayTime: "20.15",
    },
    {
      q15Slot: "C20",
      timeFormat: "20:30-20:44",
      displayTime: "20.30",
    },
    {
      q15Slot: "D20",
      timeFormat: "20:45-20:59",
      displayTime: "20.45",
    },
    {
      q15Slot: "A21",
      timeFormat: "21:00-21:14",
      displayTime: "21.00",
    },
    {
      q15Slot: "B21",
      timeFormat: "21:15-21:29",
      displayTime: "21.15",
    },
    {
      q15Slot: "C21",
      timeFormat: "21:30-21:44",
      displayTime: "21.30",
    },
    {
      q15Slot: "D21",
      timeFormat: "21:45-21:59",
      displayTime: "21.45",
    },
    {
      q15Slot: "A22",
      timeFormat: "22:00-22:14",
      displayTime: "22.00",
    },
    {
      q15Slot: "B22",
      timeFormat: "22:15-22:29",
      displayTime: "22.15",
    },
    {
      q15Slot: "C22",
      timeFormat: "22:30-22:44",
      displayTime: "22.30",
    },
    {
      q15Slot: "D22",
      timeFormat: "22:45-22:59",
      displayTime: "22.45",
    },
    {
      q15Slot: "A23",
      timeFormat: "23:00-23:14",
      displayTime: "23.00",
    },
    {
      q15Slot: "B23",
      timeFormat: "23:15-23:29",
      displayTime: "23.15",
    },
    {
      q15Slot: "C23",
      timeFormat: "23:30-23:44",
      displayTime: "23.30",
    },
    {
      q15Slot: "D23",
      timeFormat: "23:45-23:59",
      displayTime: "23.45",
    },
  ],
};

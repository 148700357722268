import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import pageError from './../../assets/images/404Page.png'

const ErrorPage = () => {
    const jwt = sessionStorage.getItem('jwt')
    const navigate = useNavigate()
    useEffect(()=>{
        if (!jwt) {
            navigate('/')
        }
    },[])
  return (
    <div  className={` d-flex justify-content-center  `} >

      <img className='w-75 h-75' src={pageError}/>
    </div>
  )
}

export default ErrorPage
import { Autocomplete, TextField } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import {
  Modal, ModalFooter, ModalBody, ModalHeader
} from "reactstrap";
import { HttpLogin } from '../../utils/Http';
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import Loader from "../../components/loader/Loader";
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Eyesight from '.';
interface AddOneOneProps {
  IsOpen: any;
  Close: any;
  errors: any;
  setErrors: any;
  setPatientDate: any;
  selectedDate: any;
  selectedTab: any;
  shifts: any;
  patientDropdown: any;
}

const AddEyesight: React.FC<AddOneOneProps> = ({
  IsOpen, Close, errors, setErrors, selectedDate,
  setPatientDate, selectedTab, shifts, patientDropdown
}) => {
  const { organization } = useSelector((state: any) => state.Login);
  const { staffDropdown, staffDropdown1 } = useSelector((state: any) => state.Staff);

  const initFormData = {
    date: "",
    endTime: "",
    id: "",
    oneToOneObserver: "",
    oneToOneObserverName: "",
    organization: "",
    patientId: "",
    patientName: "",
    patientIncharge: "",
    patientInchargeName: "",
    prescribedby: "",
    prescribedbyName: "",
    startTime: ""
  };

  const [Addpatient, setAddpatient] = useState<any>(initFormData);
  const dispatch = useDispatch<any>();
  const [Loading, setLoading] = useState<boolean>(false)

  const handleAutocompleteChange = (id: string, value: any, name: string) => {
    setAddpatient({
      ...Addpatient,
      [id]: value ? value.id : null,
      [name]: value ? `${value.name}` : ''
    });
    setErrors({ ...errors, [id]: !value });
  };

  const handleClose = () => {
    Close();
    setErrors({});
    setAddpatient(initFormData);
  };

  const fetchPatientData = async () => {
    setLoading(true);
    const { header1 } = authorize();

    HttpLogin.axios().get(`${baseURL}/eyesight/eyesightPatientList?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })

      .then((response) => {
        if (response.data.message.code === successCode) {
          setPatientDate(response.data.data);
          setLoading(false);
        } else {
          setPatientDate([""]);
        }
      });
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  const startTime = moment(Addpatient.startTime).format('HH:mm');
  const endTime = moment(Addpatient.endTime).format('HH:mm');
  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    try {
      let newErrors = {
        patientId: !Addpatient.patientId,
        startTime: !startTime,
        endTime: !endTime,
        prescribedby: !Addpatient.prescribedby,
        patientIncharge: !Addpatient.patientIncharge,
      };

      setErrors(newErrors);
      const hasErrors = Object.values(newErrors).some(error => error);
      if (hasErrors) {
        return;
      }

      const { header1 } = authorize();
      const bodyData = {
        date: moment(selectedDate).format('YYYYMMDD'),
        id: "",
        organization,
        pid: Addpatient.patientId,
        shift: shifts?.map((k: any) => ({
          record: k.shift[0].record?.map((l: any) => ({
            distance: "",
            endTime: endTime,
            note: [{ note: "", time: "" }],
            staffId: Addpatient.patientIncharge,
            startTime: startTime,
          })),
          shiftIncharge: Addpatient.prescribedby,
          shiftName: "Shift-A",
          shiftTime: "",
        }

        )),

      };

      switch (selectedTab) {
        case "Shift-A":
          bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-A");
          break;
        case "Shift-B":
          bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-B");
          break;
        case "Shift-C":
        default:
          bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-C");
          break;
      }

      const response = await axios.post(`${baseURL}/eyesight/register`, bodyData, { headers: header1 });
      if (response.data.message.code === successCode) {
        dispatch({ type: 'SET_FORM_VALUES', payload: response.data.data });
        toast.success(response.data.message.description);
        handleClose();
        fetchPatientData()
      } else {
        toast.error( response.data.message.description);

      }
    } catch (error) {
      toast.error("An error occurred during register.");
    }
  };

  return (
    <div>
      {Loading && <Loader />}
      <Modal isOpen={IsOpen} centered size="lg">
        <div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row w-100">
            <div className="container col-md-12">
              <ModalHeader toggle={handleClose}>Add EyeSight Routine</ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "65vh",
                  overflowY: "auto",
                }}
              >
                <div className="row ">
                  <div className="col-12">
                    {/* <label>Select Patient Name</label> */}
                    <Autocomplete
                      id="patientId"
                      options={patientDropdown}
                      getOptionLabel={(option) => `${option.name}`}
                      value={
                        patientDropdown?.find(
                          (patient: any) => patient.id === Addpatient.patientId
                        ) || null
                      }
                      onChange={(event, value) => {
                        setAddpatient({ ...Addpatient, patientId: value ? value.id : null });
                        setErrors({ ...errors, patientId: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Patient Name"
                          variant="outlined"
                          label='Select Patient Name'
                          error={!!errors.patientId}
                          helperText={errors.patientId ? "Patient name is required" : ""}
                        />
                      )}
                    />
                  </div>
                  <div className='row pt-3'>
                    <div className='col-md-3'>
                      <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <div style={{ width: '100%' }}>
                          <TimePicker format="HH:mm"
                            label={<span style={{ color: errors.startTime ? "#d32f2f" : "" }}>Start Time</span>}
                            sx={{ border: errors.startTime ? "0.30px solid #d32f2f" : "none", borderRadius: "4px" }}
                            value={Addpatient.startTime === "" ? null : Addpatient.startTime}
                            onChange={(newValue: any) => {
                              setAddpatient({ ...Addpatient, startTime: newValue });
                            }}
                            ampm={false}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3'>
                      <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <div style={{ width: '100%' }}>
                          <TimePicker format="HH:mm"
                            label={<span style={{ color: errors.endTime ? "#d32f2f" : "" }}>End Time</span>}
                            sx={{ border: errors.endTime ? "0.30px solid #d32f2f" : "none", borderRadius: "4px" }}
                            value={Addpatient.endTime === "" ? null : Addpatient.endTime}
                            onChange={(newValue: any) => {

                              setAddpatient({ ...Addpatient, endTime: newValue });
                            }}
                            ampm={false}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-6'>
  <Autocomplete
    id="prescribedby"
    options={staffDropdown1?.length > 0 ? staffDropdown1 : []}
    getOptionLabel={(option) => `${option.name} - ${option.role}`}
    value={staffDropdown1?.find(
      (staff: any) => staff.id === Addpatient.prescribedby
    ) || null}
    onChange={(event, value) => {
      // Handle the change, only update if value is not null
      if (value) {
        handleAutocompleteChange("prescribedby", value, "prescribedbyName");
      } else {
        // Handle case when no value is selected (optional logic)
      }
    }}
    style={{ width: '107%' }}
    renderInput={(params) => (
      <TextField
        {...params}
        placeholder={
          staffDropdown1?.length > 0 ? "Select Prescribed By" : "No Options"
        }
        variant="outlined"
        label="Prescribed By"
        error={!!errors.prescribedby}
        helperText={errors.prescribedby ? "Prescribed by is required" : ""}
      />
    )}
  />
</div>



                  </div>
                  <div className="col-md-6 mt-4 ">
                    <Autocomplete
                      id="Technician"
                      options={staffDropdown}
                      getOptionLabel={(option) => `${option.name} - ${option.role}`}
                      value={
                        staffDropdown?.find(
                          (staff: any) => staff.id === Addpatient.patientIncharge
                        ) || null
                      }
                      onChange={(event, value) =>
                        handleAutocompleteChange("patientIncharge", value, "patientInchargeName")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Technician"
                          variant="outlined"
                          placeholder="Select Staff Name"
                          InputLabelProps={{
                            shrink: !!params.inputProps.value,
                          }}
                          error={!!errors.patientIncharge}
                          helperText={
                            errors.patientIncharge ? "Technician is required" : ""
                          }
                        />
                      )}
                    />

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex gap-3">
                  <Button
                    label="Cancel"
                    severity="secondary"
                    style={{
                      color: "#000",
                      backgroundColor: "#94a0b7",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  ></Button>
                  <Button
                    label="Save Changes"
                    style={{
                      backgroundColor: "#0f3995",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={handleSaveClick}
                  ></Button>
                </div>
              </ModalFooter>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddEyesight;
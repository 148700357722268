import { Grid } from "@mui/material";
import MaskGroupImage from "../../assets/images/Mask group.svg";
import moment from "moment";
import React from "react";

const TimeSlot = ({
  handleDisplayDialogQ15Data,
  inputQ15Date,
  TimeSlotData,
  displayData,
  timeSlotSectionChange,
  inputQ15Data,
}) => {
  return (
    <div className="total_time_seaction">
    <Grid
      container
      direction="row"
      justifyContent="center"  // Centers the columns in the row
      alignItems="center"
      spacing={1.5}  // Adds spacing between grid items 
      sx={{width:'56%',flexWrap:'wrap'}}
    >
      {TimeSlotData?.map((slotData, index) => {
        return (
          <React.Fragment key={index}>
            {displayData
              .filter(
                (i) =>
                  i.q15Slot === slotData?.q15Slot &&
                  i.pid === inputQ15Data.pid &&
                  i.q15Date === moment(inputQ15Date).format("YYYYMMDD")
              )
              .map((k) => {
                return k;
              }).length > 0 ? (
              displayData
                .filter(
                  (i) =>
                    i.q15Slot === slotData?.q15Slot &&
                    i.pid === inputQ15Data.pid &&
                    i.q15Date === moment(inputQ15Date).format("YYYYMMDD")
                )
                .map((k) => (
                  <Grid
                    container
                    item
                    xs={6}  // This sets the columns to be 3 out of 12 (so 4 columns per row)
                    md={2} 
                    lg={3} sm={4}  // This is for larger screens, adjust as needed
                    key={k.id} // Use a unique key
                    
                    sx={{
                 
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    
                  >
                    <div className="patient-Q15-SlotSection"  onClick={() =>
                      handleDisplayDialogQ15Data(
                        slotData?.q15Slot,
                        slotData?.timeFormat,
                        k
                      )
                    } style={k.q15Slot === slotData?.q15Slot &&
                      k.pid === inputQ15Data.pid &&
                      k.q15Date === moment(inputQ15Date).format("YYYYMMDD")?{background:'#e7f0ff'}:""}>
                   
                    <span
                      className="patient-Q15-OccupiedSlot-Text"
                    >
                      {k.location + "-" + k.activity}
                    </span>
                    </div>
                  </Grid>
                ))
            ) : (
              <Grid
                container
                item
                xs={6}  // Adjust the column width for 4 per row
                md={2} lg={3} sm={4}
                key={slotData?.q15Slot}
                sx={{
                 
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              
               
              >
                <div className="patient-Q15-SlotSection" style={{
                  background:
                      timeSlotSectionChange === slotData?.q15Slot
                        ? "#4977D9"
                        : "", 
                }} onClick={() =>
                  handleDisplayDialogQ15Data(
                    slotData?.q15Slot,
                    slotData?.timeFormat,
                    ""
                  )
                }>
                <span
                  style={{
                    color:
                    timeSlotSectionChange === slotData?.q15Slot
                      ? "white"
                      : "",
                    textAlign: "center", // Center the text in the slot
                  }}
                  className="patient-Q15-SlotSection-Text"
                >
                  {slotData?.displayTime}
                </span>
                </div>
              </Grid>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
    </div>
  );
};

export default TimeSlot;

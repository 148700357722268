import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import Loader from "../../components/loader/Loader";
import Calendar from "../../components/calendar";
import { primarybg, primarytext } from "../../common/primary";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { baseURL } from "../../configuration/url";
import { useDispatch, useSelector } from "react-redux";
import DateBox from '../../components/calendar/dateBox';
import moment, { duration } from 'moment';
import { FaSearch } from "react-icons/fa";

interface AlarmRecord {
  id: string;
  triggeredStaffId: string;
  visitorName: string;
  triggeredTime: string;
  triggeredDate: string;
  location: string;
}

const AlarmRecordTable: React.FC = () => {
  const [alarmData, setAlarmData] = useState<AlarmRecord[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [date, setDate] = useState<string>('');
  const organizationId = useSelector((state: any) => state.Login.organization);
  const [searchTerm, setSearchTerm] = useState('');
  const { shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org);
  const recordsPerPage = 10;
  const formatDate = (date: any) => {
    const options = { day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };


  const fetchAlarmRecords = async (page: number, selectedDate: Date, searchTerm: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${baseURL}/alarm/getAlarmRecordByTriggeredDate/${moment(selectedDate).format("YYYYMMDD")}?organization=${organizationId}&triggeredDate=${moment(selectedDate).format("YYYYMMDD")}search=${searchTerm}&page=${page}&pageSize=${recordsPerPage}`
      );

      const data = response.data.data || {};
      const alarmRecords = data.content || [];

      setTotalRecords(data.totalElements || 0);
      setAlarmData(alarmRecords);
    } catch (err: any) {
      setError(err.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };
  const dispatch = useDispatch<any>()
  useEffect(() => {
    fetchAlarmRecords(currentPage, selectedDate, searchTerm);
  }, [currentPage, selectedDate, searchTerm]);
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }
  const handleDate = (date: Date) => {
    setSelectedDate(date);
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    setDate(formattedDate);
  };

  const getDate = (dateTime: string) => {
    if (!dateTime) return 'N/A';

    const date = new Date(
      `${dateTime.slice(0, 4)}-${dateTime.slice(4, 6)}-${dateTime.slice(6, 8)}`
    );

    if (isNaN(date.getTime())) return 'Invalid date';

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };
  const handleDateChange = (event: any) => {
    const newDate = new Date(event.target.value);
    if (isNaN(newDate.getTime())) {
      setSelectedDate(new Date());
      setDate('');
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = newDate.toLocaleDateString('en-US', options);
      setDate(formattedDate);
      setSelectedDate(newDate);
    }
  };
  const getTime = (dateTime: string) => {
    if (!dateTime) return 'N/A';
    const hours24 = dateTime.slice(8, 10);
    const minutes = dateTime.slice(10, 12);
    return `${hours24}:${minutes}`;
  };

  return (
    <div className="row" style={{ width: '99%', marginLeft: '5px' }}>
      <div className="col-md-8 d-flex align-items-center">
      {isLoading && ( <Loader /> )}
        <h5>SOS Call</h5>
      </div>
      <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
        <div className="mx-0 search-container d-flex align-items-center">
          <input
            type="text"
            placeholder="Search..."
            className="search form-control"
            onChange={handleSearch}
          />
          <FaSearch className="search-icon mt-1" />
        </div>

      </div>
      <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
        <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} checkShift={null} />
      </div>
          <Table className="table table-bordered" style={{ fontSize: '13px' }}>
            <thead>
              <tr>
                <th scope="col" className="text-center table-data" style={{ width: '13px', color: primarytext }}>S.No</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Date</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}> Trigered Time</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
                <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff / Visitor Name</th>
              </tr>
            </thead>
            <tbody>
              {alarmData.length > 0 ? (
                alarmData.map((record, index) => (
                  <tr key={record.id}>
                    <td className="text-center">{currentPage * recordsPerPage + index + 1}</td>
                    <td className="text-center">{getDate(record.triggeredDate)}</td>
                    <td className="text-center">{getTime(record.triggeredTime)}</td>
                    <td className="text-center">{record.location}</td>
                    <td className="text-center">  {record.triggeredStaffId ? record.triggeredStaffId : record.visitorName}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">No records found</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="pagination-container">
            <div className="d-flex justify-content-center">
              <Paginator
                first={currentPage * recordsPerPage}
                rows={recordsPerPage}
                totalRecords={totalRecords}
                onPageChange={(e: any) => {
                  setCurrentPage(e.page);
                }}
                currentPageReportTemplate={`Page ${currentPage + 1} of ${Math.ceil(totalRecords / recordsPerPage)}`}
              />
            </div>
          </div>
    </div>
  );
};

export default AlarmRecordTable;

import { TextField } from '@mui/material';
import { Button } from 'primereact/button';
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface FormProps {
    modal: boolean;
    toggle: () => void
  }
const SuperAdminAdd: React.FC<FormProps> = ({ modal, toggle }) => {
  return (
    <>
    <Modal style={{ overflowY: 'auto' }} centered size="md" isOpen={modal} toggle={toggle}>
        <ModalHeader>Super Admin Register</ModalHeader>
        <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
            <form>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <TextField
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            id="lastName"
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <TextField
                            id="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-6">
                        <TextField
                            id="timezone"
                            label="Time Zone"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            id="userName"
                            label="User Name"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            id="passcode"
                            label="Passcode"
                            variant="outlined"
                            fullWidth
                            // value={}
                            // onChange={(e: any) => {
                               
                            // }}
                            required
                            />
                    </div>
                </div>
            </form>
        </ModalBody>
        <ModalFooter>
             <div className="d-flex gap-3 justify-content-center">
                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={toggle}></Button>
                <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }}></Button>
            </div>
        </ModalFooter>
    </Modal>
    </>
  )
}

export default SuperAdminAdd
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PatientState {
    formValues: any[];
    oneData: any[];
    oneDataElement: number;
    oneData1: any[];
    oneDataElement1: number;
    loading: boolean;
    errorMsg: string;
    isOpen: boolean;
}

const initialState: PatientState = {
    formValues: [],
    oneData: [],
    oneDataElement: 0,
    oneData1: [],
    oneDataElement1: 0,
    loading: false,
    errorMsg: "",
    isOpen: false,
};

const oneToOneSlice = createSlice({
    name: "oneToOne",
    initialState,
    reducers: {
        isLoading(state) {
            state.loading = true;
        },
        setIsLoadingFalse(state) {
            state.loading = false;
        },
        setErrorMessage(state, action: PayloadAction<string>) {
            state.loading = false;
            state.isOpen = true;
            state.errorMsg = action.payload;
        },
        setFormValues(state, action: PayloadAction<any[]>) {
            state.formValues = action.payload;
        },
        setOneDataSuccess(state, action: PayloadAction<any[]>) {
            state.oneData = action.payload;
        },
        setOneDataElements(state, action: PayloadAction<any>) {
            state.oneDataElement = action.payload;
        },
        setOneData1Success(state, action: PayloadAction<any[]>) {
            state.oneData1 = action.payload;
        },
        setOneData1Elements(state, action: PayloadAction<any>) {
            state.oneDataElement1 = action.payload;
        }
        
       
    },
});

export const {
    isLoading,
    setIsLoadingFalse,
    setErrorMessage,
    setFormValues,
    setOneDataSuccess,
    setOneDataElements,
    setOneData1Success,
    setOneData1Elements,
} = oneToOneSlice.actions;

export default oneToOneSlice.reducer;

const baseURL = 'https://q15.mettlerhealth.com/mettlerapi/api';

const webSocketURL = 'https://q15.mettlerhealth.com/mettlerapi/ws';

const webSocketClient = '/topic/sosAlarm';

const successCode = 'MHC - 0200';



export { successCode, baseURL ,webSocketURL ,webSocketClient };
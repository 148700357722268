import { TextField } from '@mui/material'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { authorize } from '../../helpers/common'
import axios from 'axios'
import { baseURL, successCode } from '../../configuration/url'
import { toast } from 'react-toastify'
import LogoutConfirmationModal from '../../components/LogoutModel'

interface EditSettingModalProps {
  isOpen: boolean
  handleModalColse: () => void
  formValue: any
  setFormValue: any
  OrgId: any
}

const EditSettingModal: React.FC<EditSettingModalProps> = ({isOpen, handleModalColse, formValue, setFormValue, OrgId}) => {
    const [deleteRecord, setDeleteRecord] = useState<any>('');
    const [recordInx, setRecordInx] = useState<any>('');
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

      const recordDeleted = (deleteRecord: any, index: number) => {
        if (index !== -1) {
          const newData = formValue?.list?.filter((item: any, i: number) => i !== index);
          setFormValue((prevData: any) => ({
            ...prevData,
            list: newData
          }));
          recordDeletedCls();
        }
      };
    
      const recordDeletedCls = () => {
        setDeleteRecord('');
        setRecordInx('');
        setDeleteConfirm(false);
      };
    
      const  handleSubmit = async () => {
        try {
          const requestBody = {
              id: '',
              organizationId: OrgId,
              dropdown: formValue.type,
              list: [formValue],
              q15: true
            };
            const {header1} = authorize();
            const response = await axios.post(`${baseURL}/dropdowns/addByOrgId`, requestBody, { headers: header1 });
            if (response.data.message.code === successCode) {
              toast.success(response.data.message.description)
              handleModalColse()
            } else {
              toast.error(response.data.message.description)
            }

        } catch (error: any) {
          console.error('API ERROR: ', error)
        }

      }
      const handelClose = ( ) => {
        handleModalColse()
        setFormValue('')
      }
  return (
    <div>
        <Modal isOpen={isOpen} size="lg" centered style={{ width: '580px' }}>
            <ModalHeader toggle={() => handelClose()}>
              Edit
            </ModalHeader>
            <ModalBody>
          <div className="row w-100" style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <div className="col-md-6 mb-2 d-flex flex-row">
              <TextField
                label="Enter Your Type"
                fullWidth
                value={formValue?.type}
                disabled
              />
            </div>

            <div className="col-md-6 mb-2 d-flex flex-row">
              <div className="col-md-12">
                <TextField
                  label="Enter Data"
                  fullWidth
                  value={formValue.value}
                  onChange={(e: any) => {
                    setFormValue({...formValue, value : e.target.value})
                  }}
                />
              </div>
            </div>
          </div>

            </ModalBody>
        <ModalFooter className="gap-2">
          <Button
            label="Cancel"
            severity="secondary"
            style={{
              color: '#000',
              backgroundColor: '#94a0b7',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            onClick={() => handelClose()}
          ></Button>
          <Button
            label="Save Changes"
            style={{
              backgroundColor: '#0f3995',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            onClick={() => { handleSubmit() }}
          ></Button>
        </ModalFooter>
        </Modal>
        <LogoutConfirmationModal
        open={deleteConfirm}
        handleConfirm={() => recordDeleted(deleteRecord, recordInx)}
        handleClose={() => recordDeletedCls()}
        message={`Do you want to delete this Location ${deleteRecord?.value}? `}
      />
    </div>
  )
}

export default EditSettingModal
import React, { useEffect, useRef, useState } from 'react'
import Calendar from '../../components/calendar';
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { primarybg, primarytext } from '../../common/primary';
import { Button } from "primereact/button";
import { authorize, formatDate1 } from '../../helpers/common';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import { HttpLogin } from '../../utils/Http';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Autocomplete, List, ListItem, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { FaQrcode } from 'react-icons/fa';
import axios from 'axios';
import { getAllStaffLS } from '../../slices/thunk';
import moment from 'moment';
import LogoutConfirmationModal from '../../components/LogoutModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import DateBox from '../../components/calendar/dateBox';
import Loader from '../../components/loader/Loader';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const StaffSos = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [confirmDate, setConfirmDate] = useState(new Date());
    const videoRef = useRef(null);
    const dispatch = useDispatch()
    const { currentDateTime } = useSelector((state: any) => state.LargeScreen)
    const formattedDate = moment(currentDateTime, 'MM/DD/YYYY, hh:mm:ss A').format('YYYYMMDD');
    const [openModal, setOpenModal] = useState(false);
    const [location, setLocation] = useState('');
    const [deviceId, setDeviceId] = useState('')
    const [dropdownData, setDropdownData] = useState([])
    const { userData } = useSelector((state: any) => state.Login);
    const shiftDurationHour = [
        { 'id': '01:00', 'value': '01:00' },
        { 'id': '02:00', 'value': '02:00' },
        { 'id': '03:00', 'value': '03:00' },
        { 'id': '04:00', 'value': '04:00' },
        { 'id': '05:00', 'value': '05:00' },
        { 'id': '06:00', 'value': '06:00' },
        { 'id': '07:00', 'value': '07:00' },
        { 'id': '08:00', 'value': '08:00' }
    ]
    const [shiftDuration, setShiftDuration] = useState<any>('')
    const [activeModal, setActiveModal] = useState<boolean>(false)
    const [activeDetail, setActiveDetail] = useState<any>()
    const [activeMessage, setActiveMessage] = useState<any>('')

    const staff = userData?.userDetail;
    const fetchSoSData = async () => {
        const staffId = localStorage.getItem("userID")
        const { header1 } = authorize();
        setLoading(true)
        HttpLogin.axios().get(`${baseURL}/sosAlarm/sosAlarmRecordByDateAndOrgAndStaffId?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&staffId=${staffId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === 'MHC - 0200') {
                    setSlotRegister1(response?.data?.data?.sosAlarmList);
                    setDeviceId(response.data.data.staffDevice)
                    setLoading(false)
                } else {
                    setSlotRegister1([]);
                    console.error('No Record:');
                    setLoading(false)
                }
            })
    }
    useEffect(() => {
        fetchSoSData();
        return () => {
            setSlotRegister1([])
        }
    }, [selectedDate]);
    useEffect(() => {
        if (!slotRegister) return
        setDeviceId(slotRegister && slotRegister[slotRegister.length - 1]?.deviceId)
        return () => {
            setDeviceId('')
        }
    }, [])
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    const { organization } = useSelector((state: any) => state.Login)
    const [options, setOptions] = useState<any>([]);
    let [newRegister, setNewRegister] = useState<any>('');
    let [newIndex, setNewIndex] = useState<any>('');
    const [show, setShow] = useState(false);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [ltime, setLtime] = useState<any>('')
    const [ldate, setLdate] = useState<any>()
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const [loading, setLoading] = useState<boolean>(false)
    let addSlotRegister1 = [
        {
            staff: [
                {
                    deviceId: "",
                    registeredTime: "",
                    staffId: ""
                }
            ]
        }
    ]
    let [slotRegister, setSlotRegister1] = useState<any>(addSlotRegister1);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(slotRegister?.length / itemsPerPage);
    const org = useSelector((state: any) => state.Login.organization);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPage(pageNumber);
    };
    const startScanning = async () => {
        try {
            setScanning(true);
            const videoInputDevices = await codeReader.listVideoInputDevices();
            const selectedDeviceId = videoInputDevices[0].deviceId;
            const constraints = {
                video: { deviceId: selectedDeviceId },
            };
            codeReader.decodeFromVideoDevice(
                selectedDeviceId,
                videoRef.current,
                (result: any, err: any) => {
                    if (result) {
                        let newDeviceId = "";
                        const result1 = result.getText();
                        if (result1 !== "" && result1 !== undefined && result1 !== null) {
                            if (result1.length < 15) {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId;
                                setDeviceId(resultId)
                            } else {
                                const resultId = result1.match(/.{2}/g).join(':');
                                newDeviceId = resultId.slice(6, 23);
                                setDeviceId(newDeviceId)
                            }
                            setShow(false);
                            setScanning(false);
                        }
                        const scannedOption = options?.find((option: any) => option.deviceId === newDeviceId);

                        if (newRegister === "1") {
                            if (scannedOption) {
                                slotRegister[newIndex].deviceId = scannedOption.deviceId;
                                setSlotRegister1(slotRegister);
                            } else {
                                slotRegister[newIndex].deviceId = "";
                                setSlotRegister1(slotRegister);
                                toast.error("Scanned device ID not found in the options.");
                            }
                        }
                        const modal = document.getElementById("exampleModal");
                        if (modal) {
                            modal.classList.add("show");
                            modal.style.display = "block";
                        }

                    }
                    if (err && err.name === "NotFoundError") {
                        console.error("No QR code found in the video feed.");
                    }
                    if (err) {
                        console.error("Error during scanning:", err);
                    }

                },
            );
        } catch (error) {
            console.error("Error starting the scanner:", error);
        }
    };
    useEffect(() => {
        const handlestartTime = () => {
            const crtime: any = localStorage.getItem('LTime');
            if (crtime) {
                const formattedDate: any = moment(crtime).format('YYYY-MM-DD');
                setSelectedDate(formattedDate);
                setConfirmDate(formattedDate)
                setLdate(moment(formattedDate, 'YYYY-MM-DD').format('MM/DD/YYYY'))
                const formattedDate1 = moment(crtime, "MM/DD/YYYY HH:mm").toDate();
                setStartTime(formattedDate1);
            }
        };
        handlestartTime();
    }, []);
    const handleStartTime = () => {
        const crtime: any = localStorage.getItem('LTime')
        const formattedDate = moment(crtime, "MM/DD/YYYY HH:mm ").toDate();
        setStartTime(formattedDate);
    }
    useEffect(() => {
        handleStartTime()
    }, [])
    useEffect(() => {
        if (selectedDate) {
            setLdate(moment(selectedDate).format('MM/DD/YYYY'))
        }
    }, [selectedDate])
    useEffect(() => {
        if (scanning) {
            startScanning();
        } else {
            codeReader.reset();
            setNewIndex('');
            setNewRegister([]);
        }

        return () => {
            codeReader.reset();
        };
    }, [scanning]);

    const triggerEffectInOtherTabs = () => {
        localStorage.setItem('ltrigger', Date.now().toString());
    };

    const handleConfirmModal = () => {
        setConfirmModal(!confirmModal)
    }
    const handleConfirmModalClo = () => {
        setConfirmModal(!confirmModal)
        setConfirmMessage('')
    }
    const handleSubmit = async (alertValue: boolean) => {

        setLoading(true)
        const { header1 } = authorize();
        let newErrors = {
            deviceId: !deviceId,
            location: !location,
            startTime: !startTime,
            duration: !shiftDuration
        }
        setNameError(newErrors)
        const hasErrors = Object.values(newErrors).some(error => error);
        if (hasErrors) {
            toast.error("Please Fill Required Field")
            setLoading(false)
            return;
        }
        const bodyData = {
            staffId: staff.id,
            deviceId: deviceId,
            organization: organization,
            date: moment(selectedDate).format('YYYYMMDD'),
            location: location,
            assignedBy: staff.id,
            alert: alertValue,
            singleAlert: alertValue,
            visitorName: '',
            startTime: moment(startTime).format("HH:mm"),
            duration: shiftDuration
        }
        try {
            const response = await axios.post(`${baseURL}/sosAlarm/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description);
                closeModal()
                triggerEffectInOtherTabs()
                //   fetchSoSData();
                getAllStaffLS(dispatch, formattedDate, organization)
                fetchSoSData();
                setConfirmModal(false)
                setLoading(false)
            } else if (response.data.message.code === 'MHC - 0128') {
                setConfirmMessage('The staff already has another beacon device. Do you want to remove and assign this device?')
                setConfirmModal(true)
                setLoading(false)
                // const confirm = window.confirm('The staff already has another beacon device. Do you want to remove and assign this device?');
                //   if (confirm) {
                //       handleSubmit(true)
                //   }
                //   fetchSoSData();
            } else if (response.data.message.code === 'MHC - 0117') {
                setConfirmMessage('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                setConfirmModal(true)
                setLoading(false)
                // const confirm = window.confirm('The beacon device was already assigned to another staff member. Do you want to confirm this device to assign it to you?')
                // if (confirm) {
                //     handleSubmit(true)
                // }
                // fetchSoSData();
            } else {
                toast.error("Request failed: " + response.data.message.description);
                closeModal()
                setLoading(false)
            }
        } catch (error: any) {
            console.error("Error config:", error.config);
            setLoading(false)
        }
    };

    const handleQrClick = () => {
        setShow(true);
        setScanning(!scanning);
    }
    useEffect(() => {
        const fetchData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/sensor/getStaffsBeacon?organization=${organization}`, { headers: header1 });
                if (response.data.data.length > 0) {
                    const filterdata: any[] = response.data.data.filter((item: any) => !item?.status)
                    setOptions(filterdata);
                } else {
                    setOptions([])
                }
            } catch (error) {
                setOptions([]);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [slotRegister]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            const { header1 } = authorize();
            try {
                const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=Gender&organizationId=${org}&page=-1`, { headers: header1 });
                if (response && response.data.message && response.data.message.code === successCode) {
                    setDropdownData(response.data.data[0].list);
                } else {
                    setDropdownData([]);
                    console.error(
                        "Error fetching dropdown data:",
                        response.data.message.description
                    );
                }
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };
        fetchDropdownData();
    }, []);

    const closeModalAndRec = () => {
        setShow(false)
        setScanning(false)
        codeReader.reset()
    }

    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const handleClose = () => {
        setShow(false)
        setScanning(false)
        setNewIndex('');
        setNewRegister('');
        codeReader.reset()
    };

    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const closeModal = () => {
        setOpenModal(false)
        setLocation('')
        setShiftDuration('')
        setDeviceId(slotRegister && slotRegister[slotRegister.length - 1]?.deviceId)
    }
    const handleDateChange = (event: any) => {
        setSelectedDate(event.target.value);
    };
    const handleChange = (event: any, value: any) => {
        setLocation(value);
    };
    const handleActiveModalOpen = async (organization: any) => {
        if (!organization?.deActivateTime || organization?.deActivateTime === null || organization?.deActivateTime === undefined) {
            setActiveMessage(`Deactivate ${organization?.staffName !== null ? organization?.staffName : organization?.visitorName}-${organization?.deviceId}?`);
            setActiveModal(!activeModal);
            setActiveDetail(organization);
        }
    };
    const handleAvtiveModalClo = () => {
        setActiveModal(!activeModal)
        setActiveDetail('')
        setActiveMessage('')
    }
    const handleActivatedModal = async (staff: any) => {
        try {
            const idName = staff?.staffId ? staff?.staffId : staff?.visitorName;
            const response = await axios.post(`${baseURL}/sosAlarm/manualDeactivation?date=${moment(selectedDate).format('YYYYMMDD')}&duration=${staff?.duration}&organization=${staff?.organization}&staffId=${idName}&startTime=${staff?.startTime}`)
            if (response.data.message.code === "MHC - 0200") {
                toast.success(response.data.message.description)
                getAllStaffLS(dispatch, formattedDate, organization)
                handleAvtiveModalClo()
                fetchSoSData()
                triggerEffectInOtherTabs()
            } else {
                toast.error(response.data.message.description)
            }
        } catch (error: any) {
            console.error("API Error :", error)
        }
    }
    return (
        <div className='px-2' style={{ overflow: 'hidden' }}>
            {loading && <Loader />}
            <h5>Staff SOS Assignment</h5>
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} checkShift={null} />
            </div>
            <div className="row d-flex flex-row align-items-center py-2">
                <div className="col-md-5 d-flex justify-content-between" style={{ fontWeight: '500' }}>
                    <p style={{ color: primarybg, fontWeight: 'bold', margin: 0 }}>Staff Name: {staff && staff?.name[0]?.given + " " + staff?.name[0].family}</p>
                    Date: {moment(selectedDate).format('MMM DD, YYYY')}
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-1">
                    <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                        <i style={{ fontSize: "34px", fontWeight: "lighter" }} onClick={() => {
                            handleStartTime()
                            handleOpenModal()
                        }} className="material-icons">
                            add
                        </i>
                    </div>
                </div>
            </div>
            <Table className="table table-bordered" style={{ fontSize: '13px' }}>
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '13px', color: primarytext }} className="text-center table-data">S.No</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Staff Name</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Device ID</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Location</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Activated Time</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Valid Till</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>De-Activated Time</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Assigned By</th>
                        <th scope="col" className="text-center table-data" style={{ color: primarytext }}>Active Status</th>
                    </tr>
                </thead>
                <tbody >
                    {slotRegister?.length > 0 ? (
                        slotRegister?.slice()?.reverse().map((staff: any, index: any) => (
                            <tr key={index}>
                                <td style={{ textAlign: "left" }}>{index + 1}</td>
                                <td style={{ textAlign: "left" }}>{staff.staffName ? staff.staffName : staff.visitorName && staff.visitorName}</td>
                                <td style={{ textAlign: "left" }}>{staff.deviceId}</td>
                                <td style={{ textAlign: "left" }}>{staff.location}</td>
                                <td style={{ textAlign: "left" }}>{staff.activateTime && moment(`${staff.activateTime.slice(0, 4)}-${staff.activateTime.slice(4, 6)}-${staff.activateTime.slice(6, 8)} ${staff.activateTime.slice(8, 10)}:${staff.activateTime.slice(10, 12)}:${staff.activateTime.slice(12, 14)}`).format('MM-DD-YYYY HH:mm:ss')}</td>
                                <td style={{ textAlign: "left" }}>{staff.duration}</td>
                                <td style={{ textAlign: "left" }}>{staff.deActivateTime && moment(`${staff.deActivateTime.slice(0, 4)}-${staff.deActivateTime.slice(4, 6)}-${staff.deActivateTime.slice(6, 8)} ${staff.deActivateTime.slice(8, 10)}:${staff.deActivateTime.slice(10, 12)}:${staff.deActivateTime.slice(12, 14)}`).format('MM-DD-YYYY HH:mm:ss')}</td>
                                <td style={{ textAlign: "left" }}>{staff.assignedByName}</td>
                                <td style={{ textAlign: "center" }}>
                                    <Tooltip
                                        title={staff?.deActivateTime === null ? "Active" : ""}
                                        arrow>
                                        <div className="text-primary">
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                style={{
                                                    fontSize: "14px",
                                                    cursor: staff?.deActivateTime !== null ? 'default' : 'pointer',
                                                    color: staff?.deActivateTime !== null ? '#EF6868' : '#5FB477',
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                }}
                                                onClick={() => {
                                                    if (staff?.deActivateTime === null) {
                                                        handleActiveModalOpen(staff);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>

                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={9} className="text-center">There are no records...</td>
                        </tr>
                    )}

                </tbody>
            </Table>
            <Pagination>
                <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink
                        onClick={() => handlePageChange(currentPage - 1)}
                        previous
                    />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                    <PaginationItem active={i + 1 === currentPage} key={i}>
                        <PaginationLink onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink
                        onClick={() => handlePageChange(currentPage + 1)}
                        next
                    />
                </PaginationItem>
            </Pagination>

            <Modal show={openModal} className={`${confirmModal && 'z-2'}`} style={{ transition: `${confirmModal && 'width 2s'}`, top: '7%' }} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{staff && staff?.name[0]?.given + " " + staff?.name[0].family}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <span>Device ID</span>
                        <div className='col-md-12' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                                <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                                    <Select
                                        value={deviceId}
                                        onChange={(e) => {
                                            setDeviceId(e.target.value)
                                            setNameError({ ...nameError, deviceId: false})
                                        }}
                                        sx={{
                                            border: nameError.deviceId ? '1px solid red' : '',
                                            borderRadius: nameError.deviceId ? '5px' : 'none'
                                        }}
                                        style={{ minHeight: '50px', width: '96%', marginLeft: '12px', marginRight: '0px' }}
                                        IconComponent={() => (
                                            <FaQrcode
                                                className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                                role='button'
                                                style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                                            />
                                        )}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '300px',
                                                },
                                            },
                                        }}
                                    >
                                        {options?.length > 0 ? options?.map((option: any) => (
                                            <MenuItem key={option.id} value={option.deviceId}
                                            >
                                                {option.deviceId}
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        fontSize: "12px",
                                                        color: !option?.status ? '#5FB477' : '#EF6868',
                                                        display: "inline-block",
                                                        textAlign: "center",
                                                        marginLeft: '10px',
                                                        marginBottom: '1px'
                                                    }}
                                                />
                                            </MenuItem>
                                        )) :
                                            <MenuItem>
                                                No Device Data
                                            </MenuItem>
                                        }
                                    </Select>
                                </ListItem>
                            </List>
                        </div >
                        <div className='col-md-12 d-flex flex-row justify-content-between gap-1 py-3' style={{ marginRight: '0px' }}>
                            <div className="col-md-6 " style={{}} >
                                <DatePicker
                                    value={ldate === "" ? null : dayjs(ldate)}  // Use dayjs instead of moment
                                    label={'Date Of Birth'}
                                    format="MM-DD-YYYY"
                                    onChange={(e: any) => {
                                        setLdate(e.$d);
                                    }}
                                    className=""
                                    sx={{
                                        width: '100%',
                                    }}

                                />
                            </div>
                            <div className="col-md-6" >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <div style={{ width: '100%' }}>
                                        <TimePicker
                                            label={<span>Start Time</span>}
                                            sx={{ border: "none", borderRadius: "4px" }}
                                            value={startTime}
                                            onChange={(newValue: Date | null) => {
                                                setStartTime(newValue);
                                            }}
                                            ampm={false}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                        </div>
                    </div>
                    <div className="">
                        <div className=" row " style={{}} >
                            <div className="col-md-6">
                                <Autocomplete
                                    id={'organizationDetails'}
                                    options={dropdownData?.map((item: any) => item.value)}
                                    value={location}
                                    onChange={(e, v) => {
                                        handleChange(e, v)
                                        setNameError({ ...nameError, location: false })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={'Location'} variant="outlined"
                                        error={!!nameError.location}
                                        helperText={nameError.location ? 'Location is required' : ''}
                                    />}
                                />
                            </div>
                            <div className="col-md-6">
                                <Autocomplete
                                    id={'shiftDuration'}
                                    options={shiftDurationHour?.map((item: any) => item.value)}
                                    value={shiftDuration}
                                    onChange={(e: any, v) => {
                                        setShiftDuration(v)
                                        setNameError({ ...nameError, duration: false })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={'Duration'} variant="outlined"
                                        error={!!nameError.duration}
                                        helperText={nameError.duration ? 'Duration is required' : ''}
                                    />}
                                />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ gap: '4px' }}>
                    <Button label={'Close'} variant="secondary" style={{ backgroundColor: '#94a0b7', fontSize: '12px' }} onClick={closeModal} >
                    </Button>
                    <Button label={'Save Changes'} style={{ backgroundColor: '#0f3995', fontSize: '12px' }} onClick={() => handleSubmit(false)} />
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Scanning</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button label={'Close'} variant="secondary" onClick={handleClose} >
                    </Button>
                    <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995' }} onClick={closeModalAndRec} />
                </Modal.Footer>
            </Modal>
            <LogoutConfirmationModal open={activeModal} handleConfirm={() => handleActivatedModal(activeDetail)} handleClose={() => handleAvtiveModalClo()} message={activeMessage} />
            <LogoutConfirmationModal open={confirmModal} handleConfirm={() => handleSubmit(true)} handleClose={() => handleConfirmModalClo()} message={confirmMessage} />
        </div>
    )
}

export default StaffSos;
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { primarybg } from '../common/primary';

interface AuthRoutesProps {
  children?: React.ReactNode;
  onAccess?: () => void; // Callback to notify parent component about access
}

const ProtectedAuth = ({ children, onAccess }: AuthRoutesProps) => {
  const { jwt } = useSelector((state: any) => state.Login);
  const location = useLocation();
  useEffect(() => {
    if (onAccess) {
      onAccess(); // Notify parent component about access
    }
  }, [onAccess]);
  const patient = location.pathname === '/patient-view';
  const staffView = location.pathname === '/sos-view';
  const view = patient || staffView
  const navigate = useNavigate()

  
  // else if (!localStorage.getItem('authStaff')) {
  //   return <Navigate to={{ pathname: '/login' }} />;
  // }

  useEffect(() => {
    const handlecheck = setInterval(() => {
      const usetype = localStorage.getItem('userType')
        if ( !usetype ) {
        sessionStorage.clear()
      clearInterval(handlecheck)
      navigate('/')
    }
    }, 1000)
    
  }, [])

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
    const idleLimit = 5 * 60 * 1000; // 1 minute in milliseconds
  
    const resetIdleTimer = () => {
      sessionStorage.setItem('idleTime', '0'); // Reset to 0 on interaction
    };
  
    useEffect(() => {
      // if (view) return
      // alert('l')
      const storedIdleTime = parseInt(sessionStorage.getItem('idleTime') || '0', 10);
      if (storedIdleTime >= idleLimit) {
        setShowSessionExpiredModal(true);
      }
  
      const handleInteraction = () => {
        resetIdleTimer();
        sessionStorage.setItem('idleTime', '0'); // Reset idle time on interaction
      };
  
      window.addEventListener('mousemove', handleInteraction);
      window.addEventListener('keydown', handleInteraction);
      const handleInterval = () => {
        const currentIdleTime = parseInt(sessionStorage.getItem('idleTime') || '0', 10);
        const newIdleTime = !view ? currentIdleTime + 1000 : 0; // Increment idle time by 1 second
        sessionStorage.setItem('idleTime', newIdleTime.toString());
  
        if (newIdleTime >= idleLimit) {
          setShowSessionExpiredModal(true);
        }
      }
  
      const idleInterval = setInterval(() => {
        handleInterval()
      }, 1000);
        
      return () => {
        clearInterval(idleInterval);
        window.removeEventListener('mousemove', handleInteraction);
        window.removeEventListener('keydown', handleInteraction);
      };
    }, []);
  
    useEffect(() => {
      const disableReload = (e: any) => {
        if (showSessionExpiredModal) {
          if (e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      };
  
      const disableBeforeUnload = (e: any) => {
        if (showSessionExpiredModal) {
          e.preventDefault();
          e.returnValue = '';
        }
      };
  
      window.addEventListener('keydown', disableReload); // Disable F5
      window.addEventListener('beforeunload', disableBeforeUnload); // Disable reload button
  
      return () => {
        window.removeEventListener('keydown', disableReload); // Clean up F5 listener
        window.removeEventListener('beforeunload', disableBeforeUnload); // Clean up reload button listener
      };
    }, [showSessionExpiredModal]);
  
    const handleCloseModal = () => {
      setShowSessionExpiredModal(false);
      sessionStorage.setItem('idleTime', '0')
      // sessionStorage.clear()
    };
    const [count, setCount] = useState<number>(60)
    useEffect(() => {
      
      if (!showSessionExpiredModal) {
        setCount(60)
        return;
      }    
      const countFun = setInterval(() => {
        setCount(prevCount => {
          if (prevCount <= 1) {
            clearInterval(countFun)
            sessionStorage.clear()
            handleCloseModal()
            return 0
          }
          return prevCount - 1
        });
      }, 1000);    
      return () => clearInterval(countFun)
    }, [showSessionExpiredModal,])

    if (!sessionStorage.getItem('authStaff')) {
      if (view && localStorage.getItem('userType')) {
        sessionStorage.setItem('jwt', jwt)
        sessionStorage.setItem('authStaff', 'verified')
        return <Navigate to={{ pathname: location.pathname }} />;
      } else {
        return <Navigate to={{ pathname: '/login' }} />;
      }
    } 

  return <React.Fragment>
    {children}
  <Modal
        isOpen={showSessionExpiredModal}
        centered
        backdrop='static'
        style={{ fontFamily: "calibri", fontSize: "20px" }}
      >
        <ModalHeader>
          Session Expired
        </ModalHeader>
        <ModalBody>
        You've been inactive for a while. Your session will expire in {count} seconds. Would you like to continue or log out
        </ModalBody>
        <ModalFooter>
          <Button style={{backgroundColor: primarybg}} onClick={() => {handleCloseModal()}}>Continue with this session</Button>
          <Button style={{backgroundColor: primarybg}} onClick={() => {
            handleCloseModal()
            sessionStorage.clear()
            navigate('/')
            }}>Go to Login</Button>
        </ModalFooter>
      </Modal>
  </React.Fragment>;
  
};

export default ProtectedAuth;
